import React from 'react';

import SelectComponent from './custom_select';

import {glv} from "./App";
import HeaderComponent from "./header";
import StepperComponent from "./stepper";
import * as actions from "./redux/actions/env.actions";
import { useDispatch, useSelector } from "react-redux";
import ResultModal from './result_modal';
// let path = './images/'

function CreateOffer(props) {
    const dispatch = useDispatch();

    const language = useSelector((state) => state.envReducer.language);



    const set_language = React.useCallback((language) => dispatch(actions.setLanguage(language)), [
        dispatch,
    ]);
    const [open, set_open] = React.useState(false);
    // const [path, setPath] = React.useState('./images/ru.png');

    // React.useEffect(()=>{
    //     setPath(`./images/${currLang}.png`);
    // },[currLang])
    return (
        <div>
            <HeaderComponent  changeLang ={(lan)=> {
                // window.ln = lan;
                set_language(lan)
            }}
             def_lang={language}
            op_res={()=>set_open(true)}
            />
            <StepperComponent lang_name={language}/>
            {
                open ?
                    <ResultModal close={()=>set_open(false)}/> : null
            }

        </div>
    )
}

export default CreateOffer;