import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {glv} from "./App";

export function Docs({create_offer_form}) {
    const matches = useMediaQuery('(max-width:1025px)');
    return              <div className="step-row" style={{display: 'flex', width: '100%'}}>

        {
            matches ? <div style={{width: '100%', display: 'flex',}}>
                <div className="step-row-field" style={{width: '100%'}}>
                    <label htmlFor="sf3" style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                    }}>{glv(`mainStepper.first_step.inputs.id_.label`)} <sub>*</sub></label>
                    <input
                        style={{width: '100%', background: '#eeeeee'}}
                        type="text" id="sf3"
                        placeholder={glv(`mainStepper.first_step.inputs.id_.placeholder`)}

                        value={create_offer_form.doc_id}
                        required
                        disabled/>
                </div>

                <div className="step-row-field" style={{width: '100%', marginLeft: 5, minWidth: '50%'}}>
                    <label style={{whiteSpace: 'nowrap', overflow: 'hidden'}}
                           htmlFor="sf2">{glv(`mainStepper.first_step.inputs.data.label`)} <sub>*</sub></label>
                    <input style={{width: '100%', background: '#eeeeee'}} type="text" id="sf2" required
                           value={create_offer_form.date_crt}
                           placeholder={glv('mainStepper.first_step.inputs.data.placeholder')} disabled/>
                </div>

            </div> : null
        }

        <div className="step-row-field" style={{width: '100%', display: matches ? 'none' : 'flex'}}>
            <label htmlFor="sf3" style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden'
            }}>{glv(`mainStepper.first_step.inputs.id_.label`)} <sub>*</sub></label>
            <input
                style={{width: '100%', background: '#eeeeee'}}
                type="text" id="sf3"
                placeholder={glv(`mainStepper.first_step.inputs.id_.placeholder`)}
                value={create_offer_form.doc_id}
                required
                disabled/>
        </div>

        <div className="step-row-field"
             style={{width: '100%', marginLeft: matches ? 0 : 5, minWidth: '50%', }}>
            <label htmlFor="sf3" style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden'
            }}>{glv(`mainStepper.first_step.inputs.n_offer.label`)} <sub>*</sub></label>
            <input type="text" id="sf3"
                   style={{width: '100%', background: '#eeeeee'}}
                   placeholder={glv(`mainStepper.first_step.inputs.n_offer.placeholder`)}
                   value={create_offer_form.user_arr}
                   required
                   disabled/>
        </div>

        <div className="step-row-field" style={{width: '100%', marginLeft: 5, display: matches ? 'none' : 'flex'}}>
            <label style={{whiteSpace: 'nowrap', overflow: 'hidden'}}
                   htmlFor="sf2">{glv(`mainStepper.first_step.inputs.data.label`)} <sub>*</sub></label>
            <input style={{width: '100%', background: '#eeeeee'}} type="text" id="sf2" required
                   value={create_offer_form.date_crt}
                   placeholder={glv('mainStepper.first_step.inputs.data.placeholder')} disabled/>
        </div>

    </div>
}