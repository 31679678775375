import React, {useEffect} from 'react';
import RangeSliderComponent from './range_slider';
import SelectComponent from './custom_select';
import RadioBtnsComponent from './radio-btns';
import {
    Create_Offer_Context, create_template,
    global_form,
    glv,
    set_field_in_global_form,
    set_steps,
    steps,
    TPW_Context,
    Watter_Data_Context
} from "./App";
import imgs from "./jsonImgs";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import RadioTabComponent from "./RadioParaTabComponent";
import RadioNumList from "./RadioNumList";
import MainService from "./services/main_service";
import QueModal from "./que_modal";
import CustomImageLoader from "react-custom-image-loader.";
import Backdrop from "@material-ui/core/Backdrop";
import CustomSelect from "./cust_sel_manipul";
import {RowScales} from "./row_scales_component";
import {format_items_to_global_form} from "./helpers";
import {all_vals, tree_} from "./temp_tree";
import {four_arrs} from "./step_four";



export default function StepTwo(props) {
    const [updated_dinamic_form, set_updated_dinamic_form] = React.useState('');
    const [is_validated, set_is_validated] = React.useState(false);
    const [watter_data, set_watter_data] = React.useContext(Watter_Data_Context);
    const [create_offer_form, change_form_state] = React.useContext(Create_Offer_Context);

    const [tab, set_tab] = React.useState(true);

    const [data_types, set_data_types] = React.useState({
        tw: [
            {
                id: 'plumb',
                text: glv('mainStepper.second_step.inputs_.type_water.plumb')
            },
            {
                id: 'well',
                text: glv('mainStepper.second_step.inputs_.type_water.well')
            },
        ],
        tfw: [
            {
                id: 'human',
                text: glv('mainStepper.second_step.inputs_.type_perf_water.human')
            },
            {
                id: 'technic',
                text: glv('mainStepper.second_step.inputs_.type_perf_water.technic')
            },
            {
                id: 'mixed',
                text: glv('mainStepper.second_step.inputs_.type_perf_water.mixed')
            },
        ]
    });

    const matches = useMediaQuery('(max-width:1025px)');

    const [que_mod, set_que_mod] = React.useState({
        0: false,
        1: false,
        2: false
    });

    React.useEffect(() => {
        let a_v = watter_data?.all_values;
        let wfw = create_offer_form?.anal?.where_from_water;
        let tc = create_offer_form?.anal?.type_consumer;
        if (tree_ && wfw && tc && a_v) {
            let branch_keys_ = tree_[wfw][tc].keys;
            let rend_arr_temp = branch_keys_.map((branch_key) => ({
                ...a_v[branch_key],
                name: branch_key
            }));
            let temp_clone_cr_offer_form_anal = {where_from_water: wfw, type_consumer: tc};
            rend_arr_temp.map((item) => {
                temp_clone_cr_offer_form_anal[item.name] = format_items_to_global_form(item)
            })
            change_form_state('anal', '', {...temp_clone_cr_offer_form_anal})

            set_updated_dinamic_form(rend_arr_temp)
        }
    }, [watter_data, create_offer_form?.anal?.where_from_water, create_offer_form?.anal?.type_consumer])

    const _validated_ = ()=> {
        if (!create_offer_form?.anal?.where_from_water){
            set_is_validated(false)
            return
        }
        if (!create_offer_form?.anal?.type_consumer){
            set_is_validated(false)
            return
        }
        let one_err = false;
        let arr_by_type = [];
        four_arrs[create_offer_form?.anal?.where_from_water][create_offer_form?.anal?.type_consumer]
            .map((item)=> {
            arr_by_type =  arr_by_type.concat(item.data)
        })
        arr_by_type = arr_by_type.filter(item=>create_offer_form.anal.hasOwnProperty(item));

        let av =  watter_data.all_values;

        let cfan =  create_offer_form.anal;
        arr_by_type.map(item=>{
          if (av[item].type==='switch') {
              if (cfan[item].constructor!==Boolean) {
                  one_err = item;
              }
          }
            if (av[item].type==='muisliderX1') {

                if (cfan[item].value===null||cfan[item].value===undefined||cfan[item].value===av[item].scale.find(item_=>item_.name===cfan[item].type).size.min) {
                    one_err = item;
                }
            }
            if (av[item].type==='muisliderX2') {

                if (cfan[item].value===null||cfan[item].value===undefined||
                    (cfan[item].value[0]===av[item].scale.find(item_=>item_.name===cfan[item].type).size.min&&
                        cfan[item].value[1]===av[item].scale.find(item_=>item_.name===cfan[item].type).size.max
                    )) {
                    one_err = item;
                }
            }
        })

        if (one_err) {
            // console.log(one_err)
            set_is_validated(false)
        }else {
            set_is_validated(true)
        }
    }

    React.useEffect(() => {
        _validated_()
    }, [create_offer_form?.anal])

    const render_scales = () => {
        let rend_arr = []
        let rend_arr_temp = [...updated_dinamic_form];
        let has_color_iron = false;
        let ci = rend_arr_temp.find(item=>item.name==='color_iron');
        let ir = rend_arr_temp.find(item=>item.name==='iron');
        if (ci) {
            has_color_iron=true;
        }
        if (has_color_iron) {
            rend_arr_temp=rend_arr_temp.filter(item=>item.name!=='color_iron'&&item.name!=='iron')
            console.log(rend_arr_temp)
        }

        for (let i = 0; i < rend_arr_temp.length; i += 2) {
            let temp_arr = [];
            temp_arr.push({...rend_arr_temp[i]})
            if (rend_arr_temp[i + 1]) {
                temp_arr.push({...rend_arr_temp[i + 1]})
            } else {

            }
            rend_arr.push([...temp_arr])
        }

        if (has_color_iron) {
            rend_arr.push([ci, ir])
        }
        return rend_arr.map((blocks, index) => {
            return <RowScales
                data_scales_array={blocks}
                key_={index + 'blochok'}
                key={index + 'blochok_____' + '_'}
                type_page={'anal'}
            />
        })

    }

    const listener_0 = React.useCallback(() => {
        set_que_mod({...que_mod, 0: false});
    }, [])
    const listener_1 = React.useCallback(() => {
        set_que_mod({...que_mod, 1: false});
    }, [])
    const listener_2 = React.useCallback(() => {
        set_que_mod({...que_mod, 2: false});
    }, [])

    React.useEffect(() => {

        if (que_mod[0]) {
            document.addEventListener('click', listener_0)
        }
        if (que_mod[1]) {
            document.addEventListener('click', listener_1)
        }
        if (!que_mod[0]) {
            document.removeEventListener('click', listener_0)
        }
        if (!que_mod[1]) {
            document.removeEventListener('click', listener_1)
        }
        if (!que_mod[2]) {
            document.addEventListener('click', listener_2)
        }
        if (!que_mod[2]) {
            document.removeEventListener('click', listener_2)
        }

    }, [que_mod])

    React.useEffect(() => {

        MainService.get_watter_list_param().then((data) => {
            if (data.code < 300) {
                set_watter_data({
                    tree: {
                        plumb: {
                            ...data.info.plumb
                        },
                        well: {
                            ...data.info.well
                        },
                    },
                    all_values: {
                        ...data.info.all_values,
                            ...all_vals
                    }
                })
            }
        })


    }, [])

    useEffect(() => {
        if (!steps[1]) {
            return
        }
        switch (props.tab_val) {
            case true: {
                if (create_offer_form?.anal?.hasOwnProperty('id_anal')) {
                    return
                }
                set_updated_dinamic_form(null)
                change_form_state('g', '', {
                    ...create_offer_form,
                    'anal': {
                        ...create_template('anal')
                    }
                })
            }
            case false: {
                if (create_offer_form?.anal?.hasOwnProperty('where_from_water')) {
                    return
                }
                set_updated_dinamic_form(null)
                change_form_state('g', '', {
                    ...create_offer_form,
                    'anal': {
                        where_from_water: null
                    }
                })
            }
        }
    }, [props.tab_val, create_offer_form])

    let cond_prev_render = (props.tab_val && !create_offer_form?.anal?.hasOwnProperty('id_anal')) ||
        (!props.tab_val && !create_offer_form?.anal?.hasOwnProperty('where_from_water')) || !watter_data;


    return (
        <form className={"container step step2" + props.clas}>
            {
                cond_prev_render ? <Backdrop
                    open={true} style={{
                    zIndex: 100000,
                    color: "#fff",
                }}>
                    <CustomImageLoader
                        image={`${process.env.PUBLIC_URL}/images/icon.svg`}
                        isLoaded={true}
                        circle={false}
                        speed={2}
                        animationType={"pulse"}
                    />
                </Backdrop> : null
            }
            <div className="step-toptext" style={{paddingTop: 24}}>

                <div className="dect">
                    {glv(`mainStepper.second_step.description.p1`)}
                    <br/>
                    {/*<br/>*/}
                    {/*{glv(`mainStepper.first_step.description.p2`)}*/}
                </div>
                <div className="mob">
                    {glv(`mainStepper.second_step.description.p1`)}
                    <br/>
                    {/*<br/>*/}
                    {/*{glv(`mainStepper.first_step.description.p2`)}*/}
                </div>
            </div>

            <div className="step-row text">
                <div className="textR_line">
                    <span>{glv(`mainStepper.second_step.title`)}</span>
                </div>
                {/*<div className="text_under_textR_line">*/}
                {/*    <span>*</span> {glv(`mainStepper.second_step.second_tab.ps_title`)}*/}
                {/*</div>*/}
            </div>
            {/*<div style={{marginTop: matches ? 24 : 0}}>*/}
            {/*    <RadioTabComponent*/}
            {/*        tab_value={props.tab_val}*/}
            {/*        set_tab_value={(tab_) => props.set_tab_val(tab_)}*/}
            {/*        // btn_w={'192px'}*/}
            {/*        wr_style={{marginBottom: 0}}*/}
            {/*        sub_lab={"*"}*/}
            {/*        path_={`mainStepper.second_step.tab_selector.values.data.`}*/}
            {/*    />*/}
            {/*</div>*/}

            <div className="step-toptext" style={{display: !props.tab_val ? 'block' : 'none', paddingTop: 24}}>

                <div className="dect">
                    {glv(`mainStepper.second_step.second_tab.description.p1`)}
                    <br/>
                    {/*<br/>*/}
                    {/*{glv(`mainStepper.first_step.description.p2`)}*/}
                </div>
                <div className="mob">
                    {glv(`mainStepper.second_step.second_tab.description.p1`)}
                    <br/>
                    {/*<br/>*/}
                    {/*{glv(`mainStepper.first_step.description.p2`)}*/}
                </div>
            </div>


            <div style={{display: props.tab_val ? 'block' : 'none', marginTop: 32}}>
                <div className="step-toptext" style={{marginTop: matches ? 24 : 0}}>

                    <div className="dect">
                        {glv(`mainStepper.second_step.first_tab.description.p1`)}
                        <br/>
                        {/*<br/>*/}
                        {/*{glv(`mainStepper.first_step.description.p2`)}*/}
                    </div>
                    <div className="mob">
                        {glv(`mainStepper.second_step.first_tab.description.p1`)}
                        <br/>
                        {/*<br/>*/}
                        {/*{glv(`mainStepper.first_step.description.p2`)}*/}
                    </div>
                </div>

                <div style={{display: 'flex', width: '100%', alignItems: 'flex-end', marginBottom: 32}}>
                    <div className="step-row-field"
                         style={{width: '70%', position: 'relative'}}>

                        <label style={{display: 'flex'}}>

                           <span>
                              {glv(`mainStepper.second_step.first_tab.inputs.id.label`)}
                               <sub>*</sub>
                           </span>

                            <div
                                style={{position: 'relative'}}
                                onClick={() => set_que_mod({...que_mod, [1]: true})}
                            >
                                <img
                                    src={imgs.que} className="question "
                                    alt="question "
                                    style={{cursor: 'pointer', width: '16px', height: '16px'}}

                                />
                                {
                                    que_mod[1] ?
                                        <QueModal
                                            coord={{y: 0, x: 10}}
                                            content={glv(`mainStepper.second_step.first_tab.inputs.id.help`)}
                                        />
                                        : null
                                }

                            </div>
                        </label>
                        <input type={"text"} id="sf001"
                            // style={{border: ''}}
                               placeholder={glv(`mainStepper.second_step.first_tab.inputs.id.placeholder`)}
                               value={create_offer_form?.anal?.id_anal}
                               onChange={(e) => {
                                   let pattern = /^\d+$/;
                                   if (!e.target.value?.length) {
                                       change_form_state('anal', 'id_anal', e.target.value)
                                       return
                                   }
                                   if (pattern.test(e.target.value) && e.target.value.length < 11) {
                                       change_form_state('anal', 'id_anal', e.target.value)
                                   }

                               }
                               }
                               required/>

                        <div style={{
                            position: 'absolute',
                            top: matches ? 45 : 20,
                            fontSize: 13,
                            left: 10,
                            padding: 2,
                            background: 'white',
                            color: '#e00052',
                            display: 'none'
                        }}> {'fn_fiz_err'}
                        </div>
                    </div>

                    <div className="nextBtn" style={{width: '30%', minWidth: 126, height: 48, marginLeft: 10}}
                         onClick={() => {
                             // console.log('kakaya to knopka')
                         }}>{glv(`mainStepper.second_step.first_tab.buttons.id_button`)}</div>
                </div>

            </div>

            {
                !props.tab_val ?
                    <div style={{display: !props.tab_val ? 'block' : 'none', marginTop: matches ? 0 : 32}}>
                        <div className="step-row w50">
                            <div className="step-row-field">
                                <label style={{display: 'flex'}}>

                           <span>
                                {glv(`mainStepper.second_step.first_tab.selects.origin.label`)}
                               <sub>*</sub>
                           </span>

                                    <div
                                        style={{position: 'relative'}}
                                        onClick={() => set_que_mod({...que_mod, [0]: true})}
                                    >
                                        <img
                                            src={imgs.que} className="question "
                                            alt="question "
                                            style={{cursor: 'pointer', width: '16px', height: '16px'}}

                                        />
                                        {
                                            que_mod[0] ?
                                                <QueModal
                                                    coord={{y: 0, x: 10}}
                                                    content={glv(`mainStepper.second_step.first_tab.selects.origin.help`)}
                                                />
                                                : null
                                        }

                                    </div>
                                </label>
                                <CustomSelect
                                    options={data_types.tw}
                                    def_value={null}
                                    change_val={(val) => {
                                        if (!create_offer_form?.anal?.type_consumer) {
                                            change_form_state('anal', '', {
                                                ...create_offer_form?.anal,
                                                type_consumer: null,
                                                where_from_water: val
                                            })
                                            return
                                        }
                                        change_form_state('anal', 'where_from_water', val)
                                    }}
                                    // tooltips={data_types.origin.text_arr}
                                />
                            </div>
                            {
                                create_offer_form?.anal?.where_from_water ?
                                    <div className="step-row-field" style={{marginTop: matches ? '0px' : 0}}>
                                        <label style={{display: 'flex'}}>

                           <span>
                                {glv(`mainStepper.second_step.first_tab.selects.purpose.label`)}
                               <sub>*</sub>
                           </span>

                                            <div style={{position: 'relative'}}
                                                 onClick={() => set_que_mod({...que_mod, [1]: true})}
                                            >
                                                <img
                                                    src={imgs.que} className="question "
                                                    alt="question "
                                                    style={{cursor: 'pointer', width: '16px', height: '16px'}}

                                                />
                                                {
                                                    que_mod[1] ?
                                                        <QueModal
                                                            coord={{y: 0, x: 10}}
                                                            content={glv(`mainStepper.second_step.first_tab.selects.purpose.help`)}
                                                        />
                                                        : null
                                                }

                                            </div>
                                        </label>
                                        <CustomSelect
                                            options={data_types.tfw}
                                            def_value={null}
                                            change_val={(val) => {
                                                change_form_state('anal', 'type_consumer', val)
                                            }}
                                            // tooltips={data_types.purpose.text_arr}
                                        />
                                    </div> : null
                            }

                        </div>

                        {
                            updated_dinamic_form ?
                                render_scales()
                                : null
                        }
                    </div>
                    : null
            }


            <div className="step-row last" style={{marginTop: matches? 24 : 32}}>
                <div className="prevBtn" onClick={() => props.prevStep()}
                     style={{marginRight: is_validated ? '' : 0,
                         width: !is_validated&&matches ? '100%' : ''
                     }}>
                    {
                        glv(`mainStepper.prevStepTitle`)
                    }
                </div>
                {
                    is_validated? null : <div></div>
                }
                {
                    is_validated ?
                        <div className="nextBtnWr ">
                            <div className="nextBtn" onClick={() => {
                                if (!steps[2]) {
                                    change_form_state('l1', 'watt_par', {...create_template('watt_par')})

                                }
                                set_steps({...steps, 2: true})
                                props.nextStep()
                            }}>{glv(`mainStepper.nextStepTitle`)}</div>
                            {/* <input type="submit" className="nextBtn" value={props.lang.mainStepper.nextStepTitle} onClick = {()=>props.nextStep()}/> */}
                        </div> : null
                }

            </div>
        </form>
    )
}