import React from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import { GuardProvider, GuardedRoute } from 'react-router-guards';
import ViewOffer from "./View_Offer_Page";
import CreateOffer from "./Create_Offer_Page";
import RouterModal from "./router_modal";
import Account from "./Account_Page";
import UserPage from "./User_Page_Route";
import {Offer_User_Context} from "./App";
// import {isAuthGuard} from './guards/isAuthGuard';




const NotFound = ()=> <Redirect to={`/`} />;

const AppRouter = (props) => {
    const [offer_acc_data, set_offer_acc_data] = React.useContext(Offer_User_Context)

    return (
        <GuardProvider guards={[]} loading={<div></div>} error={<Redirect to={`/`} />}>
            <Switch>

                <GuardedRoute path="/" exact component={
                    !offer_acc_data?.id||offer_acc_data?.type==='first_load' ?
                        Account
                    :
                    RouterModal
                } />
                <GuardedRoute path="/view-offer" exact component={ViewOffer}  />
                <GuardedRoute path="/create-offer" exact component={CreateOffer}  />
                {/*<GuardedRoute path="/account" exact component={Account}  />*/}
                {/*<GuardedRoute path="/view-fiz-account" exact component={Account}  />*/}
                {/*<GuardedRoute path="/view-jur-account" exact component={Account}  />*/}
                <GuardedRoute path="/user-page" exact component={UserPage}  />
                <GuardedRoute path="*" component={NotFound} />
            </Switch>
        </GuardProvider>
    );}

export default AppRouter;



