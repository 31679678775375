import React from "react";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { VisibilityContext } from "react-horizontal-scrolling-menu";

function Arrow({
                   children,
                   disabled,
                   onClick,
                    direction,
    br
               }) {
    const [over, set_over] = React.useState(false)
    return (
        <button
            onMouseOver={()=> {
                set_over(true)
            }}
            onMouseLeave={()=> {
                set_over(false)
            }}
            disabled={disabled}
            onClick={onClick}
            style={{
                position: 'absolute',
                cursor: "pointer",
                [direction]: 0,
                display:disabled? 'none':"flex",
                flexDirection: "column",
                justifyContent: "center",
                borderRadius: br,
                opacity: disabled ? "0" : "1",
                border: '1px solid #3DB9E5',
                background:over ? '#3DB9E5' : 'white',
                height:'100%',
                color: over ? 'white' : '#3DB9E5',
                userSelect: "none",
                zIndex:5165161151
            }}
        >
            {children}
        </button>
    );
}

export function LeftArrow() {
    const {
        isFirstItemVisible,
        scrollPrev,
        visibleItemsWithoutSeparators
    } = React.useContext(VisibilityContext);
    // console.log(
    //     isFirstItemVisible,
    //     scrollPrev,
    //     visibleItemsWithoutSeparators
    // )

    const [disabled, setDisabled] = React.useState(
        !visibleItemsWithoutSeparators.length && isFirstItemVisible
    );
    React.useEffect(() => {
        // NOTE: detect if whole component visible
        if (visibleItemsWithoutSeparators.length) {
            setDisabled(isFirstItemVisible);
        }
    }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

    return (
        <Arrow disabled={disabled} onClick={(e) => {
            e.preventDefault()
            scrollPrev()
        }} direction={'left'} br={'5px 0px 0px 5px'}>
            <ArrowLeftIcon/>
        </Arrow>
    );
}

export function RightArrow() {
    const {
        isLastItemVisible,
        scrollNext,
        visibleItemsWithoutSeparators
    } = React.useContext(VisibilityContext);

    const [disabled, setDisabled] = React.useState(
        !visibleItemsWithoutSeparators.length && isLastItemVisible
    );
    React.useEffect(() => {
        if (visibleItemsWithoutSeparators.length) {
            setDisabled(isLastItemVisible);
        }
    }, [isLastItemVisible, visibleItemsWithoutSeparators]);

    return (
        <Arrow disabled={disabled} onClick={(e) => {
            e.preventDefault()

            scrollNext()
        }} direction={'right'} br={'0px 5px 5px 0px'}>
            <ArrowRightIcon/>
        </Arrow>
    );
}
