import React from 'react';

import SelectComponent from './custom_select';
import imgs from './jsonImgs';
import {glv, setlogos} from "./App";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {create_dn} from "./helpers";
import useDebounce from "./hooks/useDebounce";
import {useDispatch, useSelector} from "react-redux";
import {path, assocPath} from 'ramda';

// let path = './images/'


function get_vars_by_address(text, ln) {
    return fetch(`https://nominatim.openstreetmap.org/search?format=json&q=` + text + `&countrycodes=ua,pl&limit=50&accept-language=` + ln + `&addressdetails=1`)
        .then((res) => res.json()).then((results) => {

            let newArrRes = results ? results
                .map(element => {

                    let new_dn = create_dn(element)
                    return new_dn
                    // `${element.address.city}, ${element.address.country}`

                }) : [];
            return newArrRes
        })
        .catch((err) => {
            setlogos(err, 'nominatim zapros na inpute')
            console.log(err)
        });

}


function AddressInput(props) {
    const matches = useMediaQuery('(max-width:1025px)');

    // let sel_val = false;

    const [show_pub_loading_vars, set_show_pub_loading_vars] = React.useState(false);
    const [city, setCity] = React.useState('');
    const [sel_var, set_sel_var] = React.useState(false);
    const [error_, set_error_] = React.useState(false);


    const [variants, setVariants] = React.useState(null);
    const [hoverVariat, setHoverVariant] = React.useState(false);

    const curr_address = useSelector((state) => state.envReducer.curr_address);
    const debouncedSearchTerm = useDebounce(city, 500);

    // let num_value = format_tel(path(props.path.split('.'),props.form_))

    let _adress_ = path(props.path.split('.'), props.form_);

    React.useEffect(() => {
        setCity(props.custom_write ?_adress_ : _adress_.string_adr)
    }, [_adress_, props.custom_write]);


    React.useEffect(() => {
        set_error_(props.error)
    }, [props.error]);

    React.useEffect(() => {
        if (!props.bind_map) {
            return
        }
        if (!curr_address) {
            return
        }
        let new_address = create_dn(curr_address);

        setCity(new_address.dn)
    }, [curr_address]);

    React.useEffect(() => {
        if (debouncedSearchTerm) {


            if (sel_var) {
                return
            }
            if (!debouncedSearchTerm) {
                set_show_pub_loading_vars(false);
                return
            }
            get_vars_by_address(debouncedSearchTerm).then((res) => {
                set_show_pub_loading_vars(false);
                setVariants(res)
            })
        }
    }, [debouncedSearchTerm, sel_var])

    return (
        <div className="step-row-field" style={props.wrap_style ? {...props.wrap_style} : {}}>
            <label htmlFor="sf1">{
                props.label

            } {
                props.sub ? null : <sub>*</sub>
            }</label>
            {/* <ReactOsmGeocoding
            iconUrl={null}
             acceptLanguage={'en'}  callback={data => console.log(data)} city={null} countrycodes={['ua', 'pl']}/> */}

            <input type="text"
                   id="sf1"
                   placeholder={
                       props.placeholder

                   }

                   value={city}
                   autoComplete={'off'}
                   style={{width: '100%', border: error_ ? '1px solid #e00052' : ''}}
                   onChange={(e) => {
                       set_error_(false)
                       if (props.custom_write && props.handle_custom_write) {
                           if (e.target.value.length < 10  ) {
                               set_error_(glv('account.second_step.errors.short_adres'))
                           }
                           props.handle_custom_write(e.target.value)
                       }
                       if (!e.target.value) {
                           setCity(e.target.value)
                           return
                       }

                       // props.set_error(false)
                       // set_error_(false)
                       set_sel_var(false);
                       set_show_pub_loading_vars(true);
                       setCity(e.target.value)
                   }}
                // {...bindPlace}
                   required/>
            <div style={{
                position: 'absolute',
                top: matches ? 45 : 20,
                fontSize: 13,
                left: 10,
                padding: 2,
                background: 'white',
                color: '#e00052',
                display: error_ ? 'block' : 'none'
            }}> {error_}
            </div>

            {
                show_pub_loading_vars ? <div
                    className={'hide_scroll_general hide_scroll'}
                    style={{
                        overflowY: 'auto', maxHeight: 300,
                        display: 'flex', flexDirection: 'column',
                        border: '1px solid #EEEEEE', borderTop: 'none'
                    }}>
                    <div
                        style={
                            {
                                width: '100%',
                                background: "rgba(0,0,0,0.1)",
                                padding: '10px 12px',
                                cursor: 'pointer',
                            }}

                    >

                                    <span>
                                        {'Loading...'}
                                    </span>
                    </div>
                </div> : null
            }
            {variants && city ?
                <div
                className={'hide_scroll_general hide_scroll'}
                style={{
                    overflowY: 'auto', maxHeight: 300,
                    display: 'flex', flexDirection: 'column',
                    border: '1px solid #EEEEEE', borderTop: 'none'
                }}>

                {
                    props.custom_write ?
                        <div

                            onMouseOver={() => setHoverVariant('custom_var')}
                            onMouseLeave={() => setHoverVariant(false)}
                            style={
                                {
                                    width: '100%',
                                    background: hoverVariat === 'custom_var' ? "rgba(0,0,0,0.3)" : "rgba(0,0,0,0.1)",
                                    padding: '10px 12px',
                                    cursor: 'pointer',
                                }
                            }
                            onClick={() => {

                                setVariants(null)
                            }

                            }

                        >

<span>
    {glv('account.second_step.inputs.address.custom_variant')}
                </span>
                        </div> : null
                }

                {variants.map((element, index) =>

                        <div

                            onMouseOver={() => setHoverVariant(index + element.dn)}
                            onMouseLeave={() => setHoverVariant(false)}
                            style={
                                hoverVariat === index + element.dn ? {
                                        width: '100%',
                                        background: "rgba(0,0,0,0.3)",
                                        padding: '10px 12px',
                                        cursor: 'pointer',
                                    } :
                                    {

                                        width: '100%',
                                        background: "rgba(0,0,0,0.1)",
                                        padding: '10px 12px',
                                        cursor: 'pointer',
                                    }}
                            onClick={() => {
                                // props.select_variant(element)
                                if (props.custom_write&&props.handle_custom_write) {
                                    set_error_(false)
                                    props.handle_custom_write(element.dn);
                                    set_sel_var(true)
                                    setCity(element.dn);
                                    setVariants(null)
                                    return
                                }

                                set_error_(false)
                                let new_form = assocPath(
                                    [...props.path.split('.')],
                                    {
                                        "coordinate": {
                                            "lat": element.lat,
                                            "lon": element.lon
                                        },
                                        "coordinate_arr": {
                                            "country": element.address.country ?? " ",
                                            "country_code": element.address.country_code ?? " ",
                                            "region": element.address.county ?? " ",
                                            "state_district": element.address.state ?? " ",
                                            "street": element.address.road ?? " ",
                                            "city": element.address.city ?? " ",
                                            "town": element.address.town ?? " ",
                                            "village": element.address.village ?? " ",
                                            "house_number": element.address.house_number ?? " ",
                                            "house_name": element.address.house_number ?? " "
                                        },
                                        "string_adr": element.dn ?? " "
                                    },
                                    props.form_
                                )

                                props.set_form(new_form)

                                set_sel_var(true)
                                setCity(element.dn);
                                setVariants(null)
                            }

                            }
                            key={index + element.dn}
                        >

<span>
    {element.dn}
                </span>
                        </div>
                )}
            </div> : null}
        </div>
    )
}

export default AddressInput;