import React, { useEffect, useCallback, useState } from "react";

import { withStyles, makeStyles } from '@material-ui/core/styles';
// import Slider from '@material-ui/core/Slider';
import Slider from "@mui/material/Slider";
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import imgs from './jsonImgs';
import {format} from 'mathjs'
let full_w  = false;


let color_over_rep = '#e00052';

let out_lims_color = '#e5c83d'

let ThumbComponent = function ThumbComponent(props) {
    // {...props}


    const top_condition = props["data-index"] === 0 ? 32 : "";

    const bottom_condition = props["data-index"] === 1 ? 32 : "";

    let state_ = props.ownerState;

    let trigger_touch = Math.abs(state_.value[1] - state_.value[0]) <= (state_.max - state_.min) * 0.19;

    let trigger_touch_balls = Math.abs(state_.value[1] - state_.value[0]) <= (state_.max - state_.min) * 0.09;
    let colors = {
        0:
            state_.value[0]>props.rp ? trigger_touch_balls?'#dd6b94' : color_over_rep :
// '#e5c83d'
//                 state_.value[0]<props.limits.min||state_.value[0]>props.limits.max ?   trigger_touch_balls?'#f0e193' : out_lims_color :
            trigger_touch_balls&&top_condition ? '#a9d4e4': '#3db9e5',
        1:
            state_.value[1]>props.rp ? color_over_rep :
                // state_.value[1]<props.limits.min||state_.value[1]>props.limits.max ? out_lims_color :
                trigger_touch_balls&&top_condition ? '#a9d4e4': '#3db9e5',
    }
    // const label_tooltip_show_condition =
    return (
        <div {...props} className={"range_thumb double_slider_thumb"}
             style={{...props.style,
                 padding: trigger_touch ? 0 : '0px 8px',
                 marginLeft:trigger_touch_balls&&top_condition&&state_.value[1] === state_.value[0] ? -25: trigger_touch ? -22 : -26,
                 width: trigger_touch? 24 : '',
                 minWidth: trigger_touch? 24 : '',
                 background: colors[props["data-index"]],
                 transition: 'width 0.4s, min-width 0.4s, marginLeft 0.4s, padding 0.4s, background 0.4s',

             }}>

            <span style={{ marginTop: 25, minWidth: 34, display: trigger_touch ? 'none' : 'initial' }}>
                {props.ownerState.value[props["data-index"]]}
            </span>

            {props.children}
            <div
                style={{
                    display: trigger_touch ? 'initial' : 'none',
                    color: "black",
                    zIndex: 654684,
                    // marginBottom: -50
                    boxShadow: `0px ${bottom_condition? -4 : 4}px 8px rgb(0 0 0 / 10%)`,
                    position: "absolute",
                    width: "50px",
                    top: top_condition,
                    left: trigger_touch ? -3 : 0,
                    bottom: bottom_condition,
                    transition: 'all 0.4s'
                }}
                className={"modal_double_range"}
            >
                <div style={{
                    position: 'absolute',
                    width: 10,
                    height: 10,
                    background: '#ffffff',
                    boxShadow: '0px 4px 8px rgb(0 0 0 / 6%)',
                    border: '1px solid #eeeeee',
                    borderRight: 'none',
                    borderTop: 'none',
                    transform:  `rotate(${bottom_condition ? 315 : 135}deg)`,
                    [bottom_condition? 'bottom' : 'top']: -6,

                }}></div>
                <span>{props.ownerState.value[props["data-index"]]}</span>
            </div>

        </div>
    );
}

function r_s_val(val, matches) {

    let new_val = window.number_to_beauty_string(val)
    if (new_val.length   < 2) {
        return  matches? -55 : -45
    }

    if (new_val.length  < 3) {
        return matches? -55 : -50
    }
    if (new_val.length  < 4) {
        return  matches? -60 :-55
    }
    if (new_val.length < 5) {
        return matches? -65 : -60
    }

    return 10
}
const StyledRangeSlider =(full_w, matches_1010)=> {


    return withStyles({
        root: {
            color: '#3db9e5',
            height: 5,
            maxWidth: full_w||matches_1010 ? '100%'  : 316,
            width: '100%',

        },
        thumb: {
            // height: 24,
            // // width: 50,
            // backgroundColor: '#3db9e5',
            // borderRadius: '12px',
            // border: '2px solid currentColor',
            // marginTop: -10,
            // marginLeft: -30,
            // '&:focus, &:hover, &$active': {
            //   boxShadow: 'inherit',
            // },
        },
        active: {},
        valueLabel: {
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%) !important',
            // left: 'calc(-50% + 4px)',
            // top: 50,
            '& *': {
                background: 'transparent',
                color: '#ffffff',
            },
        },
        track: {
            height: 5,

            borderRadius: 4,
        },
        rail: {
            height: 5,
            // width: 316,
            borderRadius: 4,
        },
    })(Slider)
};

const RangeDoubleSliderComponent = (props) => {
    //  let maxVal = 100;
    //  let minVal = 0;
    // let stepVal = 1;
    const {maxVal, minVal, stepVal, scale_, value_, setNewRangeValue} = props;

let value = value_;
    const matches_1010 = useMediaQuery('(max-width:1025px)');
    let StyledRange =React.useCallback (StyledRangeSlider(props.full_w,  matches_1010), [props.full_w, matches_1010]);

    const [fw, sfw] = React.useState(false);

    const [input_, set_input_] = React.useState(false);
    const [input_hover, set_input_hover] = React.useState(false);

    let Thumb = React.useCallback ((props) => {

     return   <ThumbComponent {...props} rp = {scale_.rep_point} limits={scale_.limits}/>

    }, [scale_.rep_point, scale_.limits]);

    useEffect(()=>{
        if (props.full_w) {
            sfw(true)
            return
        }
        if (matches_1010) {
            sfw(true)
            return
        }
        if(!matches_1010) {
            sfw(false)
            return
        }
        // console.log(matches_1010)
    }, [matches_1010, props.full_w])

    const handle_change_val_1 = (val)=> {
        let temp_ = [...value];
        temp_[0] = val;
        setNewRangeValue([...temp_])
    }
    const handle_change_val_2 = (val)=> {
        let temp_ = [...value];
        temp_[1] = val;
        setNewRangeValue([...temp_])
    }

    useEffect(()=>{

    }, [value,maxVal, minVal ])




    return  (
        <div style={{display: 'flex', width: '100%'}}>
            {
                input_ ?
                    <div style={{flex: 2, display: 'flex'}}>
                    <input
                        type={'number'}
                        value={
                            // props.value_
                            value[0]
                        }
                        onChange={(event) => {
                            if(isNaN(event.target.value)) {
                                return
                            }
                            if (Number(event.target.value)> maxVal) {
                                handle_change_val_1(maxVal)
                                // props.setNewRangeValue(maxVal);
                                return
                            }
                            if (Number(event.target.value)< minVal) {
                                handle_change_val_1(minVal)
                                // props.setNewRangeValue(minVal);
                                return
                            }
                            if (Number(event.target.value)> value[1]) {
                                handle_change_val_1(value[1])
                                // props.setNewRangeValue(minVal);
                                return
                            }

                            if(!Number.isInteger(Number(format(Number(event.target.value)/stepVal, { precision: 14 })))) {
                                let new_val = format(
                                    Math.trunc(
                                        Number(format(Number(event.target.value)/stepVal, { precision: 14 }))
                                    )*Number(stepVal)+Number(stepVal),
                                    { precision: 14 })
                                handle_change_val_1(new_val);
                                // props.setNewRangeValue(
                                //     new_val
                                // )
                                return
                            }
                            handle_change_val_1(event.target.value);

                            // props.setNewRangeValue(event.target.value);
                        }}
                        className={'number_range_input'}
                        min = {minVal}
                        max={maxVal}
                        step={
                            stepVal
                        }
                        style={{
                            borderRadius: '5px 0px 0px 5px',
                            borderColor: value[0]>scale_.rep_point ? color_over_rep :
                                // (value[0]>scale_.limits.max||value[0]<scale_.limits.min) ? out_lims_color:
                                ''
                        }}
                    />
                        <input
                            type={'number'}
                            value={
                                // props.value_
                                value[1]
                            }
                            onChange={(event) => {
                                if(isNaN(event.target.value)) {
                                    return
                                }
                                if (Number(event.target.value)> maxVal) {
                                    handle_change_val_2(maxVal)
                                    // props.setNewRangeValue(maxVal);
                                    return
                                }
                                if (Number(event.target.value)< minVal) {
                                    handle_change_val_2(minVal)
                                    // props.setNewRangeValue(minVal);
                                    return
                                }
                                if (Number(event.target.value)< value[0]) {
                                    handle_change_val_2(value[1])
                                    // props.setNewRangeValue(minVal);
                                    return
                                }


                                if(!Number.isInteger(Number(format(Number(event.target.value)/stepVal, { precision: 14 })))) {
                                    let new_val = format(
                                        Math.trunc(
                                            Number(format(Number(event.target.value)/stepVal, { precision: 14 }))
                                        )*Number(stepVal)+Number(stepVal),
                                        { precision: 14 })
                                    handle_change_val_2(new_val)
                                    // props.setNewRangeValue(
                                    //     new_val
                                    // )
                                    return
                                }
                                handle_change_val_2(event.target.value)
                                // props.setNewRangeValue(event.target.value);
                            }}
                            className={'number_range_input'}
                            min = {minVal}
                            max={maxVal}
                            step={
                                stepVal
                            }
                            style={{
                                borderRadius: 0,
                                borderColor: value[1]>scale_.rep_point ? color_over_rep :
                                    // (value[1]>scale_.limits.max||value[1]<scale_.limits.min) ? out_lims_color :
                                    ''
                            }}
                        />
                    </div>:
                    <div className="rangeContainer" style={
                        {margin: fw ? '0px 70px 0 50px' : '0px 65px ', position: 'relative',
                            borderRadius:  '5px 0px 0px 5px'
                        }

                    }>
    <span className='l_min_range' style={{
        fontSize: 14, fontWeight: 'normal', color: '#6D6E71', position: 'absolute',

        left:  fw ?-38 : -38}}>
        {/*10*/}
        {window.number_to_beauty_string(props.minVal)}
    </span>
                        <StyledRange
                            // ThumbComponent={ThumbCom}
                            style={{color:
                                    value[0]>scale_.rep_point&&value[1]>scale_.rep_point ? color_over_rep :
                                        // (value[0]>scale_.limits.max||value[0]<scale_.limits.min)
                                        // &&(value[1]>scale_.limits.max||value[1]<scale_.limits.min) ? out_lims_color :

                                                '#3DB9E5',
                                transition: 'color 0.4s'
                            }}
                            components={{ Thumb: Thumb }}
                            aria-label="ios slider"
                            min = {
                                // -100
                               minVal
                            }
                            max={
                               // 10
                                maxVal
                            }
                            step={
                                // 0.1
                               stepVal
                            }
                            defaultValue={props.defValue}
                            value={
                                value
                                // props.value_
                            }
                            onChange={(event, newValue) => {

                                setNewRangeValue(newValue);
                                // props.setNewRangeValue(newValue);
                            }}
                            valueLabelDisplay="off"
                        />
                        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', position: 'absolute',
                            right :r_s_val(props.maxVal, matches_1010)
                            // fw ?-45 : -35
                            // r_s_val(props.maxVal)

                        }}>
                            <span className='r_max_range' style={{fontSize: 14, fontWeight: 'normal', color: '#6D6E71'}}>
                                {window.number_to_beauty_string(props.maxVal)}
                                {/*200*/}
                            </span>
                            {/*<span className='r_max_range' style={{fontSize: 14, fontWeight: 'normal', color: '#6D6E71'}}>{props.num_val?props.num_val: '' }</span>*/}
                        </div>

                    </div>
            }




            <div
                style={{
                    opacity: 1,
                    // color: checked ? '#fff' : btns_style?.color ? btns_style.color : '#000',
                    height: '100%',
                    border: '1px solid #EEEEEE',

                    padding: '15px 8px',
                    display: 'flex',
                    justifyContent: 'center',
                    cursor:'pointer',
                    alignItems: 'center',
                    // width: index===0&&card_width===0 ?  'max-content': card_width,
                    background: input_hover ? '#3db9e5' : '#fff',
                    // transform: value===index  ? 'rotate(45deg)' : 'rotate(0deg)',
                    transition: 'color, background 0.2s',
                    // flex:1,
                    borderRadius:  '0px 5px 5px 0px'
                }}
                onMouseOver={()=>set_input_hover(true)}
                onMouseLeave={()=>set_input_hover(false)}
                onClick={()=>set_input_(!input_)}
            >
                <img
                    src={

                        input_? input_hover?imgs.slider_white: imgs.slider_gray : input_hover ?  imgs.input_white : imgs.input_gray
                    }
                    style={{
                        width: 30,
                        height:input_&& input_hover ? 15 : 30,
                        transition: 'display, opacity 0.2s',

                    }}
                    // width={40}
                    // height={40}
                />
            </div>
        </div>
    );
};

export default React.memo(RangeDoubleSliderComponent) ;