import React, {useEffect, useCallback, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useDispatch, useSelector} from "react-redux";
import {fade, makeStyles, withStyles} from "@material-ui/core/styles";
import {Button, Divider, TextField} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import {glv, _steps_, Create_Offer_Context, Watter_Data_Context} from "./App";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";

function PaperComponent(props) {
    return (

        <Paper {...props} />

    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: 0,
        paddingRight: 0,
    },

    fromToLab: {
        marginTop: 20,
    },
    highlight: {
        color: "white",
        backgroundColor: "#000",
    },
    title: {
        flex: "1 1 100%",
    },
    dI: {
        backgroundColor: "#ff6",
    },

    formControl: {
        marginTop: 10,
        width: "100%",
    },

    globalHelpLabel: {
        marginTop: 10,
        textAlign: "center",
        width: "100%",
        // fontWeight: 'bold'
        paddingBottom: 5,
        borderBottom: "rgba(0,0,0,0.1) solid",
    },

    simpleHelp: {
        marginTop: 5,
        textAlign: "center",
        color: "#c2c2c2",
        width: "100%",
    },

    titleLabel: {
        marginTop: 10,
        textAlign: "center",
        fontWeight: "bold",
        paddingBottom: 5,
        width: "100%",
        borderBottom: "rgba(0,0,0,0.1) solid",
    },
    okBtn: {
        background: "#5E5AB9",
        // boxShadow: '-9px -9px 16px rgba(255, 255, 255, 0.6), 4px 4px 8px rgba(63, 60, 255, 0.12)',
        borderRadius: 12,
        color: "white",
        fontFamily: "gilroy",
        fontWeight: 600,
        width: "43%",
        marginRight: 10,
        marginBottom: 15,
        "&:hover": {
            background: "#5e5ab9",
            boxShadow: "none",
        },
    },
    scrPap: {
        maxHeight: "none",
    },
    cancBtn: {
        marginBottom: 15,
        fontWeight: 600,
        width: "43%",
        background: "linear-gradient(0deg, #cfddfc, #E3E9F7)",
        // boxShadow: '-9px -9px 24px rgba(255, 255, 255, 0.570094), 20px 22px 40px rgba(0, 0, 0, 0.0826595)',
        opacity: 0.7,
        borderRadius: 12,
        marginLeft: 10,
        color: "#2F2A61",
        fontFamily: "gilroy",
        marginRight: 10,
        "&:hover": {
            // background: 'linear-gradient(0deg, #cfddfc, #DFE6F6)',
            // boxShadow:' inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)',
        },
        "&:active": {},
    },
}));


const ResultModal = ({close,}) => {
    const classes = useStyles();

    const matches = useMediaQuery("(max-width:1050px)");
    const [create_offer_form, change_form_state] = React.useContext(Create_Offer_Context);
    const [watter_data, set_watter_data] = React.useContext(Watter_Data_Context);
    useEffect(() => {

    }, []);
    const [tab, set_tab] = React.useState(0);
    const [tab_view_tree, set_tab_view_tree] = React.useState(0);

    const renderTree = (nodes, key, index, lvl) => {
        if (nodes?.constructor === String) {

            return (
                <TreeItem
                    classes={{}}
                    key={nodes + index + 'str'}
                    label={
                        `${key}: ${nodes ? nodes : '" "'}`
                        // key+': '+
                    }
                >

                </TreeItem>
            );
        }
        if (nodes?.constructor === Array) {

            return (
                <TreeItem
                    classes={{iconContainer: classes.iconCont}}
                    nodeId={JSON.stringify(nodes)}
                    key={JSON.stringify(nodes) + index}
                    label={key}
                >
                    {nodes.map((node) => renderTree(node))}
                </TreeItem>
            );
        }

        if (nodes?.constructor === Object) {

            if (lvl === 0) {
                return (
                    <TreeItem

                        classes={{}}
                        nodeId={JSON.stringify(nodes) + index + Math.random()}
                        key={JSON.stringify(nodes) + index + Math.random()}
                        collapseIcon={<RemoveIcon/>}
                        expandIcon={<AddIcon/>}
                        label={
                            key
                        }
                        // icon={key==='users'? <UsersSquare /> : key === 'children' ? <ChildrenSquare /> : <PlusSquare />}
                        // //expandIcon={}

                    >
                        {Object.keys(nodes).map((item, index) => {
                            if (nodes[item]?.constructor === Number) {
                                return renderTree(String(nodes[item]), item, index, 1);
                            }
                            if (nodes[item]?.constructor === Boolean) {
                                return renderTree(String(nodes[item]), item, index, 1);
                            }

                            return renderTree(nodes[item], item, index, 0);

                            return null;
                        })}
                    </TreeItem>
                );
            }

        }

        return <Typography>Not data</Typography>;
    };


    return (
        <div style={{position: 'relative'}}>

            <Dialog
                fullScreen={true}
                fullWidth
                maxWidth={"sm"}
                open={true}
                onClose={() => close()}
                // onClose={handleClose}
                classes={{paperScrollPaper: classes.scrPap}}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                PaperProps={{
                    style: {

                        background: "#ffffff",
                        // boxShadow:'-5px -5px 7px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(23, 23, 23, 0.12)',
                        borderRadius: 0,
                        maxWidth: '100%',
                        minWidth: "200px",
                        // height: "80%",
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "space-between",
                        overflow: "hidden",
                    },
                }}
                style={{}}
            >

                <div style={{
                    textAlign: "center",
                    borderBottom: '2px solid #d9d9d9',
                    marginTop: matches ? 25 : 0,
                    display: 'flex', width: '100%',
                    justifyContent: 'space-between',
                    alignItems:'center'
                }}>
                    <div></div>
                    <DialogTitle
                        style={{
                        }}
                        id="draggable-dialog-title"
                    >
                     Form
                    </DialogTitle>
                    <CloseIcon onClick={()=>{
                        close()
                    }} style={{marginRight: 10, cursor: 'pointer'}}/>
                </div>

                <div style={{
                    display: 'flex',
                    marginTop: 10,
                    marginLeft: 5,
                    marginRight: 5,
                    borderBottom: '2px solid blue'
                }}>
                    <Button
                        variant="contained"
                        style={{
                            width: '50%', borderRadius: 0,
                            fontFamily: 'Lato',
                            color: tab === 0 ? 'white' : 'black',
                            background: tab === 0 ? `#3db9e5`
                                : `#ffffff`
                        }}
                        onClick={() => {
                            set_tab(0)
                        }}

                    >Дерево</Button>

                    <Button variant="contained"
                            style={{
                                width: '50%', borderRadius: 0,
                                fontFamily: 'Lato',
                                color: tab === 1 ? 'white' : 'black',
                                background: tab === 1 ? `#3db9e5`
                                    : `#ffffff`
                            }}
                            onClick={() => {
                                set_tab(1)
                            }}
                    >Остальной функционал</Button>



                    <Button variant="contained"
                            style={{
                                width: '50%', borderRadius: 0,
                                fontFamily: 'Lato',
                                color: tab === 3 ? 'white' : 'black',
                                background: tab === 3 ? `#3db9e5`
                                    : `#ffffff`
                            }}
                            onClick={() => {
                                set_tab(3)
                            }}
                    >Дерево с бека на создание оффера</Button>

                </div>
                {
                    tab===0||tab===3?

                        <div>
                            <div style={{
                                display: 'flex',
                                marginTop: 10,
                                marginLeft: 20,
                                marginRight: 20,
                                // borderBottom: '2px solid blue'
                            }}>
                                <Button
                                    variant="contained"
                                    style={{
                                        width: '50%', borderRadius: 0,
                                        fontFamily: 'Lato',
                                        color: tab === 0 ? 'white' : 'black',
                                        background: tab === 0 ? `#3db9e5`
                                            : `#ffffff`
                                    }}
                                    onClick={() => {
                                        set_tab_view_tree(0)
                                    }}

                                >Обычное дерево</Button>

                                <Button variant="contained"
                                        style={{
                                            width: '50%', borderRadius: 0,
                                            fontFamily: 'Lato',
                                            color: tab === 1 ? 'white' : 'black',
                                            background: tab === 1 ? `#3db9e5`
                                                : `#ffffff`
                                        }}
                                        onClick={() => {
                                            set_tab_view_tree(1)
                                        }}
                                >Красивое дерево</Button>

                            </div>
                            {
                                tab_view_tree === 1 ?
                                    <div
                                        style={{
                                            overflowWrap: 'break-word',
                                            maxHeight: '500px',
                                            overflowY: 'auto',
                                            border: '1px solid',
                                            marginTop: 10
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                window.return_beauty_json_html(
                                                    tab===0?
                                                    create_offer_form : watter_data.tree
                                                )
                                        }}/> : null
                            }


                            {   tab_view_tree == 0 ?
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: '0px 20px',
                                    overflowWrap: 'break-word',
                                    maxHeight: '500px',
                                    overflowY: 'auto',
                                    border: '1px solid',
                                    padding: 20,
                                    marginTop: 10
                                }}>
                                    <TreeView>

                                        {
                                            Object.keys(
                                                tab===0?
                                                create_offer_form : watter_data.tree
                                            ).map((item, index) => renderTree(create_offer_form[item], item, index, 0,))
                                        }

                                    </TreeView>
                                </div> : null}
                        </div> :null
                }

                {
                    tab===1 ?
                <div></div> : null
                }

                {/*<DialogActions style={{marginTop: 20, display: 'flex', justifyContent: 'center', }}>*/}
                {/*    <Button onClick={() => close()} style={{width: '100%'}}>Close</Button>*/}
                {/*</DialogActions>*/}

            </Dialog>
        </div>
    );
};

export default ResultModal;
