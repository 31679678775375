import React, { useEffect, useCallback, useState } from "react";

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useMousePosition from "./hooks/useMousePosition";

let full_w  = false;




function ThumbComponent(props) {
    // {...props}
    return (
        <span {...props}  className={'range_thumb'} >
        {props['aria-valuenow']}
    </span>
    );
}

function r_s_val(val) {
    if (String(val).length   < 2) {
        return 26
    }

    if (String(val).length  < 3) {
        return 19
    }
    if (String(val).length  < 4) {
        return 13
    }
    if (String(val) < 6) {
        return 10
    }

    return 10
}
const StyledRangeSlider =(full_w, matches_1010)=> {


    return withStyles({
        root: {
            color: '#3db9e5',
            height: 5,
            maxWidth: full_w||matches_1010 ? '100%'  : 316,
            width: '100%',

        },
        thumb: {
            // height: 24,
            // // width: 50,
            // backgroundColor: '#3db9e5',
            // borderRadius: '12px',
            // border: '2px solid currentColor',
            // marginTop: -10,
            // marginLeft: -30,
            // '&:focus, &:hover, &$active': {
            //   boxShadow: 'inherit',
            // },
        },
        active: {},
        valueLabel: {
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%) !important',
            // left: 'calc(-50% + 4px)',
            // top: 50,
            '& *': {
                background: 'transparent',
                color: '#ffffff',
            },
        },
        track: {
            height: 5,

            borderRadius: 4,
        },
        rail: {
            height: 5,
            // width: 316,
            borderRadius: 4,
        },
    })(Slider)
};

const QueModal = (props) => {
    // const { x, y } = useMousePosition();
    // const matches_1010 = useMediaQuery('(max-width:1025px)');
    // let StyledRange =React.useCallback (StyledRangeSlider(props.full_w,  matches_1010), [props.full_w, matches_1010]);



    const [value, setValue] = React.useState(props.defValue);

    // useEffect(()=>{
    //     console.log(matches_1010)
    // }, [matches_1010])

    return  (
        <div
            className={'que_modal'}
            style={
                {

                    position: 'absolute',
                    background: 'white',
                    top: props.coord.y,
                    left: props.coord.x +23,
                    zIndex: 98864846484464
                }

        }>

            <span style={{whiteSpace: 'nowrap'}}>
                {
                    props.content
                }
            </span>


        </div>

    );
};

export default React.memo(QueModal) ;