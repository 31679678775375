import React from 'react';


export default function SelectComponent(props) {
    const [value, setValue] = React.useState(props.def_value);

    const [color_id, set_color_id] = React.useState(props.def_value);
    const [open, setOpen] = React.useState(false);

    const handleOpenClose = () => {
        setOpen(!open)
    }

    const handleSelect = (val) => {
        setValue(val)
    }

    return (

        <div className="wrap_sel_block" style={!props.style_wsb ? {} : props.style_wsb}>
            <div className="wrap_select" style={!props.style_ws ? {} : props.style_ws}>
                <div className="sel_inp" onClick={() => handleOpenClose()} style={{cursor: 'pointer'}}>
                    <span className="sel_inp_text"
                          style={{fontSize: 16, color: '#111111', fontWeight: 'normal'}}>{props.options[value]}</span>
                </div>

                <div className='wrap_opti' style={!open ? {height: '0px', opacity: 0} : {
                    // display:'flex'
                }}>
                    <div className="options_themes hide_scroll hide_scroll_general" style={open ? {display: 'flex', maxHeight: 300} : {display: 'none'}}>
                        {props.options.map((el, index) => {
                            return <span

                                onClick={() => {
                                    handleSelect(index);
                                    handleOpenClose();

                                    set_color_id(el + index)
                                    if (props.changeVal) {
                                        props.changeVal(index)
                                    }
                                }}
                                style={index === 0 ? {
                                    border: 'none',
                                    color: el + index === color_id ? '#3db9e5' : '#111111'
                                } : el + index === color_id ? {color: '#3db9e5'} : {}}
                                title={props.tooltips? props.tooltips [index] : ''}
                                className="option_thema"
                                key={el + index + 'fdfgd'}>
                    {el}
                    </span>
                        })}
                    </div>
                </div>

                {/*open?{opacity:0} :*/}
                <div style={{}}>
                    <svg style={
                        {
                            ...props.style_svg ? props.style_svg : {},
                            // background: open? 'white' : 'red',
                            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'all 0.4s'
                        }

                    }

                         onClick={() => handleOpenClose()} className="arrow_rep arrow_rep_d"
                         xmlns="http://www.w3.org/2000/svg" width="22" height="18"
                         viewBox="0 0 26 24">
                        <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                    </svg>
                </div>


                {/*<div style={open?{} : {opacity:0}}>*/}
                {/*<svg  style={props.style_svg?props.style_svg: {}} onClick={()=> handleOpenClose()} className="arrow_rep arrow_rep_up" xmlns="http://www.w3.org/2000/svg" width="22" height="18"*/}
                {/*    viewBox="0 0 26 24">*/}
                {/*    <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />*/}
                {/*</svg>*/}
                {/*</div>*/}
            </div>
        </div>
    )

}