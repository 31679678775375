import * as actionTypes from "../types/statistic.types";

import Cookies from "js-cookie";

const initialState = {
 uns_mod: false,
 uns: false,
 editMod: null,
};

export default function statisticReducer(state = initialState, action) {


  if(action.type===actionTypes.EDIT_MOD) {

  }
  switch (action.type) {
    case actionTypes.UNSAVED_MODAL:
      return {
        ...state,
        uns_mod: action.uns_mod,
      };

      case actionTypes.UNSAVED:
        return {
          ...state,
          uns: action.uns,
        };

        case actionTypes.EDIT_MOD:
          return {
            ...state,
            editMod: action.editMod,
          };
  

    default:
      return state;
  }
}
