import React, {useEffect, useCallback, useState} from "react";
import Dialog from "@material-ui/core/Dialog";

import {InlineShareButtons} from 'sharethis-reactjs';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useDispatch, useSelector} from "react-redux";
import {fade, makeStyles, withStyles} from "@material-ui/core/styles";
import {Button, Divider, TextField} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Fade from '@mui/material/Fade';
import CloseIcon from '@material-ui/icons/Close';
import {NavLink} from 'react-router-dom';
import SelectComponent from "./custom_select";
import * as actions from "./redux/actions/env.actions";
import {glv} from "./App"
import {lang_arr} from "./App";

function PaperComponent(props) {
    return (
        // <Draggable
        //     handle="#draggable-dialog-title"
        //     cancel={'[class*="MuiDialogContent-root"]'}
        // >
        <Paper {...props} />
        // </Draggable>
    );
}

const ExpireCopiedModal = props => {

    useEffect(() => {
        setTimeout(() => {
            props.close()
        }, props.delay);
    }, [props.delay]);

    return (
        <Fade in={true}>
            <div
                className={'que_modal'}
                style={{

                    position: 'absolute',
                    top: props.pos.top,
                    left: props.pos.left,
                    zIndex: 9646846,
                    marginLeft: 16,
                    maxWidth: '100%',
                    color: '#6D6E71'
                }}
            >{glv('user_page.share_modal.copied_text')}
            </div>


        </Fade>
    )


};


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: 0,
        paddingRight: 0,
    },

    fromToLab: {
        marginTop: 20,
    },
    highlight: {
        color: "white",
        backgroundColor: "#000",
    },
    title: {
        flex: "1 1 100%",
    },
    dI: {
        backgroundColor: "#ff6",
    },

    formControl: {
        marginTop: 10,
        width: "100%",
    },

    globalHelpLabel: {
        marginTop: 10,
        textAlign: "center",
        width: "100%",
        // fontWeight: 'bold'
        paddingBottom: 5,
        borderBottom: "rgba(0,0,0,0.1) solid",
    },

    simpleHelp: {
        marginTop: 5,
        textAlign: "center",
        color: "#c2c2c2",
        width: "100%",
    },

    titleLabel: {
        marginTop: 10,
        textAlign: "center",
        fontWeight: "bold",
        paddingBottom: 5,
        width: "100%",
        borderBottom: "rgba(0,0,0,0.1) solid",
    },
    okBtn: {
        background: "#5E5AB9",
        // boxShadow: '-9px -9px 16px rgba(255, 255, 255, 0.6), 4px 4px 8px rgba(63, 60, 255, 0.12)',
        borderRadius: 12,
        color: "white",
        fontFamily: "gilroy",
        fontWeight: 600,
        width: "43%",
        marginRight: 10,
        marginBottom: 15,
        "&:hover": {
            background: "#5e5ab9",
            boxShadow: "none",
        },
    },
    scrPap: {
        maxHeight: "none",
    },
    cancBtn: {
        marginBottom: 15,
        fontWeight: 600,
        width: "43%",
        background: "linear-gradient(0deg, #cfddfc, #E3E9F7)",
        // boxShadow: '-9px -9px 24px rgba(255, 255, 255, 0.570094), 20px 22px 40px rgba(0, 0, 0, 0.0826595)',
        opacity: 0.7,
        borderRadius: 12,
        marginLeft: 10,
        color: "#2F2A61",
        fontFamily: "gilroy",
        marginRight: 10,
        "&:hover": {
            // background: 'linear-gradient(0deg, #cfddfc, #DFE6F6)',
            // boxShadow:' inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)',
        },
        "&:active": {},
    },
}));


const ConfirmModal = (props) => {

    const classes = useStyles();

    const matches = useMediaQuery("(max-width:1050px)");
    const matches_500 = useMediaQuery("(max-width:550px)");

    return (
        <Dialog
            // fullScreen={matches ? true : false}
            fullWidth
            maxWidth={"sm"}
            open={props.open}
            onClose={props.close}
            classes={{paperScrollPaper: classes.scrPap}}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={{
                style: {

                    background: "#ffffff",
                    // boxShadow:'-5px -5px 7px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(23, 23, 23, 0.12)',
                    borderRadius: matches ? 0 : 5,
                    maxWidth: matches ? '100%' : "532px",
                    minWidth: "200px",
                    // height: "80%",
                    display: "flex",
                    // flexDirection: "column",
                    // justifyContent: "space-between",
                    overflow: "hidden",
                },
            }}
            style={{}}
        >

            <div style={{
                marginTop: matches? 24:32,
                marginLeft: 8,
                marginBottom: matches? 16 :24
            }}>
                {glv('user_page.confirm_text')}
            </div>




                        <div style={{
                            display: 'flex', justifyContent: 'space-evenly', marginTop:matches? 24: 0, width: '100%',
                            padding: 8
                        }}>

                            <div className="canc_edited_btn"

                                 onClick={() => {
                                     props.close();

                                 }}
                                 style={{
                                     marginRight: 5,

                                     flex:1,

                                 }}>

                                {glv('user_page.btns.cancel_confirm')}

                            </div>


                                    <div className="save_edited_btn" onClick={() => {
                                        props.confirm_f()
                                    }}
                                         style={{
                                             marginLeft: 5,
                                             // width: !is_validated && matches ? '100%' : '',
                                             flex: 1
                                         }}>

                                        {glv('user_page.btns.save_confirm')}

                                    </div>




                        </div>





        </Dialog>

    );
};

export default ConfirmModal;



