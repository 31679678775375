import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StepConnector from '@material-ui/core/StepConnector';
import clsx from 'clsx';
import StepOne from './step_one';
import StepTwo from './step_two';
import StepThree from './step_three';
import StepFour from './step_four';
import StepFive from './step_five';
import StepSix from './step_six';
import StepSeven from './step_seven';
import StepEight from './step_eight';
import {_set_steps_, glv, set_field_in_global_form} from "./App";
import {assocPath,  path} from "ramda";


let title_headers = ['first', 'two', 'three', 'four' ,'five', 'six', 'seven', 'eight'];

const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: '#784af4',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#784af4',
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector);


  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));

  const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
      color: '#784af4',
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
    completed: {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
  });

  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? <div style={{
            width: '12px',
    height: '12px',
    background: '#3DB9E5',
    border: '2px solid #3DB9E5',
    borderRadius: '10px'
    }}></div> : <div className={classes.circle} />}
      </div>
    );
  }



function getStep() {
  return ['Select campaign settings', 'Create an ad group', 'Create an ad'];
}



let steps = [1,2,3,4,5,6,7,8];

let global_ = {
  "global": {
    "date_crt": 0,
    "date_upd": 0,
    "uiid": 0,
    "user_crt": 0,
    "user_upd": 0
  },
  "client":{
    "physics":{
      "select": true,
      "contact_tel": {
        "country_code": "",
        "operator_code": "",
        "tel": ""
      },
      "contact_email": "",
      "contact_adr": {
        "coordinate":{
          "lat": null,
          "lon": null
        },
        "coordinate_arr": {
          "country": "",
          "country_code": "",
          "region": "",
          "state_district": "",
          "city": "",
          "town": "",
          "village": "",
          "house_number": "",
          "house_name": ""
        },
        "string_adr": ""
      },
      "client": {
        "name": "",
        "sname": "",
        "male": true
      },
      "instalation_adr": {
        "coordinate":{
          "lat": null,
          "lon": null
        },
        "coordinate_arr": {
          "country": "",
          "country_code": "",
          "region": "",
          "state_district": "",
          "city": "",
          "town": "",
          "village": "",
          "house_number": "",
          "house_name": ""
        },
        "string_adr": ""
      },
      "text": ""
    },
    "juristic":{
      "select": false,
      "nip": "",
      "company_name": "",
      "company_tel": {
        "country_code": "",
        "operator_code": "",
        "tel": ""
      },
      "contact_adr": {
        "coordinate":{
          "lat": null,
          "lon": null
        },
        "coordinate_arr": {
          "country": "",
          "country_code": "",
          "region": "",
          "state_district": "",
          "city": "",
          "town": "",
          "village": "",
          "house_number": "",
          "house_name": ""
        },
        "string_adr": ""
      },
      "contact_tel": {
        "country_code": "",
        "operator_code": "",
        "tel": ""
      },
      "contact_email": "",
      "contact_name": "",
      "instalation_adr": {
        "coordinate":{
          "lat": null,
          "lon": null
        },
        "coordinate_arr": {
          "country": "",
          "country_code": "",
          "region": "",
          "state_district": "",
          "city": "",
          "town": "",
          "village": "",
          "house_number": "",
          "house_name": ""
        },
        "string_adr": ""
      },
      "text": ""
    }
  },
  "watter":{
    "info":{
      "date_add": 0,
      "user_crt": 0,
      "date_crt": 0,
      "origin_type": "",
      "purpose_type": ""
    },
    "color":{
      "metod": "",
      "scale": "",
      "fraction_error": "",
      "value": ""
    },
    "ph":{
      "metod": "",
      "scale": "",
      "fraction_error": "",
      "value": ""
    },
    "temp":{
      "metod": "",
      "scale": "",
      "fraction_error": "",
      "value": ""
    },
    "iron":{
      "metod": "",
      "scale": "",
      "fraction_error": "",
      "value": ""
    },
    "manganese":{
      "metod": "",
      "scale": "",
      "fraction_error": "",
      "value": ""
    },
    "hardness":{
      "metod": "",
      "scale": "",
      "fraction_error": "",
      "value": ""
    },
    "ammonia_nitrogen":{
      "metod": "",
      "scale": "",
      "fraction_error": "",
      "value": ""
    },
    "ammonia_ions":{
      "metod": "",
      "scale": "",
      "fraction_error": "",
      "value": ""
    },
    "conduction":{
      "metod": "",
      "scale": "",
      "fraction_error": "",
      "value": ""
    },
    "oxidizability":{
      "metod": "",
      "scale": "",
      "fraction_error": "",
      "value": ""
    }
  },
  "object":{}
}

export default function StepperComponent(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(1);
  const [tab_value_second_step, set_tab_value_second_step] = React.useState(false);

  const [steps_, set_steps_] = React.useState([
    {status: 0, name: 'first'},
    {status: 0, name: 'second'},
    {status: 0, name: 'third'},
    {status: 0, name: 'fourth'},
    {status: 0, name: 'fiveth'},
    {status: 0, name: 'sixth'},
    {status: 0, name: 'seventh'},

  ]);
  const [form_, set_form_] = React.useState({...global_});

  React.useEffect(()=> {
      set_field_in_global_form(form_)
  }, [form_])

  React.useEffect(()=> {
    _set_steps_(steps_)
  }, [steps_])

  window.change_form_w = (path, value) => {
    let new_form = assocPath(path.split('.'), value, {...form_})
    set_form_({...new_form})
  }

  const handle_manipulate_form =
      (modificator, path_, value) => {
        // console.log(modificator, path_, value)
        switch (modificator) {
          case 'get': {

            let parsed_path = path_.split('.');
            let path_value = path(parsed_path, {...form_});
            return path_value;
          }
            break;
          case  'set': {

            let parsed_path = path_.split('.');
            let new_form = assocPath(parsed_path, value, {...form_});
            set_form_({...new_form})
          }
            break;
          default : {
            return null
          }
        }
      }
  // const steps = getSteps();

  const isStepOptional = (step) => {
    return step === 1;
  };

  React.useEffect(()=>{
    document.title =glv('document_titles.main_title')+ ' | '+ glv('document_titles.step_titles.data.'+(activeStep-1), );
  }, [activeStep])
  React.useEffect(()=>{

  }, [steps_])


  const nextStep = (step) => {
    let copy_steps_ = [...steps_];

    copy_steps_[activeStep-1].status=1;
    copy_steps_[activeStep].status=1;
    set_steps_([...copy_steps_])
    setActiveStep(activeStep+1)

  };

  const prevStep = (step) => {
    if (activeStep-1<1) {
      return;
    }
    setActiveStep(activeStep-1)
  };


  return (
    <div className={classes.root}>
  <div className="wrapper stepline-wr">
      <div className="container stepline ">
        {
          steps_.map((item, index) => {
            // console.log(steps_)
            let clas = 'stepline-item step'+(index+1);
            // clas+=' active'
            let adding_ =  ''
            if (index===0){
              if(item.status===1&&activeStep!==1) {
                adding_=' active'
              }
            } else {
              if(item.status===1) {
                adding_=' active'
              }
              if(index+1===activeStep&&activeStep!==1) {
                adding_ =' curr'
              }
            }


            clas+=adding_
            return (
              <div className={clas} key={'_step_'+index+item.name}>
              <div className="stepline-item-circle"
                   onClick={item.status===0?()=>{}: ()=>setActiveStep(index+1)}
                   style={{cursor:item.status===0? 'default': 'pointer'}}>
                  <div className="stepline-item-circle-dot"></div>
              </div>
          </div>
            )
          })
        }

      </div>
  </div>

  <div className="mobLineStatus">
     {
       steps_.map((el, index) => {
        return (
          <div className={`mobLineStatus-item step${index+1} ${el.status===1? 'active' : ''} `} key={el.name+index+'offer_step'}></div>
        )
      }
      ) 
      }
      
      {/* // <div class="mobLineStatus-item step2"></div>
      // <div class="mobLineStatus-item step3"></div>
      // <div class="mobLineStatus-item step4"></div>
      // <div class="mobLineStatus-item step5"></div>
      // <div class="mobLineStatus-item step6"></div>
      // <div class="mobLineStatus-item step7"></div>
      // <div class="mobLineStatus-item step8"></div> */}
  </div>
      <div style={{display: activeStep===1 ? 'block' : 'none'}}>
        <StepOne nextStep={nextStep} clas={' active'} lang={props.language} lang_name={props.lang_name}
                 form={form_}
                 set_form={(new_form_)=>set_form_(new_form_)}
                 set_form_={handle_manipulate_form}
        />
      </div>
      <div style={{display: activeStep===2 ? 'block' : 'none'}}>

        <StepTwo tab_val={tab_value_second_step} set_tab_val={set_tab_value_second_step} nextStep={nextStep} prevStep={prevStep} clas={' active'} lang={props.language}/>

      </div>
      <div style={{display: activeStep===3 ? 'block' : 'none'}}>
              <StepThree step={steps_[2]}  nextStep={nextStep} prevStep={prevStep} clas={' active'} lang={props.language}/>
      </div>

      {
        steps_[3].status ?
            <div style={{display: activeStep===4 ? 'block' : 'none'}}>
              <StepFour step={steps_[3]} nextStep={nextStep} prevStep={prevStep} clas={' active'} lang={props.language}/>
            </div> : null
      }

      {/*<div style={{display: activeStep===4 ? 'block' : 'none'}}>*/}
      {/*  <StepFive nextStep={nextStep} prevStep={prevStep} clas={' active'} lang={props.language}/>*/}
      {/*</div>*/}
      {/*<div style={{display: activeStep===5 ? 'block' : 'none'}}>*/}
      {/*  <StepSix nextStep={nextStep} prevStep={prevStep} clas={' active'} lang={props.language}/>*/}
      {/*</div>*/}

      {/*<div style={{display: activeStep===6 ? 'block' : 'none'}}>*/}
      {/*  <StepSeven nextStep={nextStep} prevStep={prevStep} clas={' active'} lang={props.language}/>*/}
      {/*</div>*/}
      {/*<div style={{display: activeStep===7 ? 'block' : 'none'}}>*/}
      {/*  <StepEight nextStep={nextStep} prevStep={prevStep} clas={' active'} lang={props.language}/>*/}
      {/*</div>*/}












{/*{*/}
{/*  getStepContent(activeStep)*/}

{/*}*/}
    </div>
  );
}


