import React, {useCallback, useEffect} from 'react';
import {RowScales} from "./row_scales_component";


const WFhome = ({all_v, conds}) => {
    return (
        <>
            <RowScales
                data_scales_array={
                    ["count_peoples", "count_consumption_taps"]
                        .map((item)=>({...all_v[item], name: item}))
                }
                type_page={'watt_par'}
            />
            <RowScales
                data_scales_array={
                    ["day_water", ""]
                        .map((item)=>({...all_v[item], name: item}))

                }
                type_page={'watt_par'}
            />
            <RowScales
                data_scales_array={
                    ["sewerage_select",conds.sew? "sewerage" : ""]
                        .map((item)=>({...all_v[item], name: item}))

                }
                type_page={'watt_par'}
            />


            <RowScales
                data_scales_array={
                    ["count_bathrooms", ""]
                        .map((item)=>({...all_v[item], name: item}))
                }
                type_page={'watt_par'}
            />

            <RowScales
                data_scales_array={
                    ["has_add_cons",conds.has_add_cons? "additional_consumption" : ""]
                        .map((item)=>({...all_v[item], name: item}))
                }
                type_page={'watt_par'}
            />

            <RowScales
                data_scales_array={
                    ["watering", conds.waterin?  "volume_ck" : '']
                        .map((item)=>({...all_v[item], name: item}))
                }
                type_page={'watt_par'}
            />

            {
                conds.waterin?
                    <RowScales
                        data_scales_array={
                            ["volume_dr", "day_ck" ]
                                .map((item)=>({...all_v[item], name: item}))
                        }
                        type_page={'watt_par'}
                    /> : null
            }

            <RowScales
                data_scales_array={
                    ["has_acc", conds.has_acc?  "acc_type" : '']
                        .map((item)=>({...all_v[item], name: item}))
                }
                type_page={'watt_par'}
            />
            {
                conds.has_acc?
                    <RowScales
                        data_scales_array={
                            ["volume_tan", "hor_ver" ]
                                .map((item)=>({...all_v[item], name: item}))
                        }
                        type_page={'watt_par'}
                    /> : null
            }

            <RowScales
                data_scales_array={
                    ["interruption_water", '']
                        .map((item)=>({...all_v[item], name: item}))
                }
                type_page={'watt_par'}
            />

            <RowScales
                data_scales_array={
                    ["know_pres_watt", conds.known_wat_pr?  "water_pres" : '']
                        .map((item)=>({...all_v[item], name: item}))
                }
                type_page={'watt_par'}
            />
        </>
    )
}

export default WFhome;