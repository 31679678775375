import React, {useCallback, useEffect} from 'react';
import RangeSliderComponent from './range_slider';
import SelectComponent from './custom_select';
import RadioBtnsComponent from './radio-btns';
import imgs from './jsonImgs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Create_Offer_Context, create_template, glv, set_steps, steps, TPW_Context, Watter_Data_Context} from "./App";
import QueModal from "./que_modal";
import useMousePosition from "./hooks/useMousePosition";
import {RowScales} from "./row_scales_component";
import CustomImageLoader from "react-custom-image-loader.";
import Backdrop from "@material-ui/core/Backdrop";
import CustomSelect from "./cust_sel_manipul";
import {format_items_to_global_form} from "./helpers";
import {
    arr_acc, arr_addit_consum, arr_count_laundresses,
    arr_has_addit_consum, arr_plumb_rest,
    arr_sewerage_select,
    arr_wat_pres,
    arr_watering, arr_well_rest,
    tree_water_for
} from "./temp_tree";
import WFhome from "./wf_home.jsx";
import WFflat from "./wf_flat";
import WFferm from "./wf_ferm";
import WFmanuf from "./wf_manuf";
import WFogorod from "./wf_ogorod";
import WFhotel from "./wf_hotel";
import WFrestoran from "./wf_restoran";
import WFhotelrest from "./wf_hotel_restoran";
import {four_arrs} from "./step_four";

let what_for_water = null;

export default function StepThree(props) {
    if (!what_for_water) {
        what_for_water = [
            {
                id: 'home',
                text: glv('mainStepper.third_step.selects.what_for_water.home')

            },
            {
                id: 'flat',
                text: glv('mainStepper.third_step.selects.what_for_water.flat')
            },
            {
                id: 'communal_home',
                text: glv('mainStepper.third_step.selects.what_for_water.communal_home')
            },
            {
                id: 'manufactur_perf',
                text: glv('mainStepper.third_step.selects.what_for_water.manufactur_perf')
            },
            {
                id: 'ferm',
                text: glv('mainStepper.third_step.selects.what_for_water.ferm')
            },
            {
                id: 'ogorod',
                text:glv('mainStepper.third_step.selects.what_for_water.ogorod')
            },
            {
                id: 'hotel',
                text: glv('mainStepper.third_step.selects.what_for_water.hotel')
            },
            {
                id: 'restoraunt',
                text: glv('mainStepper.third_step.selects.what_for_water.restoraunt')
            },
            {
                id: 'hotel_restoran',
                text: glv('mainStepper.third_step.selects.what_for_water.hotel_restoran')
            },
        ]
    }


    const { x, y } = useMousePosition();
    const matches = useMediaQuery('(max-width:1050px)');

    const [que_mod, set_que_mod] = React.useState({
        0: false,
        1: false,
        2: false
    });

    const [conds, set_conds] = React.useState({
        sew: false,
        waterin: false,
        has_acc: false,
        known_wat_pr: false,
        has_add_cons: false
    });

    const [updated_dinamic_form_for_well, set_updated_dinamic_form_for_well] = React.useState('');
    const [updated_dinamic_form_for_water, set_updated_dinamic_form_for_water] = React.useState('');

    const [create_offer_form, change_form_state] = React.useContext(Create_Offer_Context);
    const [watter_data, set_watter_data] = React.useContext(Watter_Data_Context);

    const [tpw, handle_change_tpw] = React.useContext(TPW_Context);
    const [is_validated, set_is_validated] = React.useState(false);


    const _validated_ = ()=> {
        if (!create_offer_form?.watt_par?.water_for){
            set_is_validated(false)
            return
        }

        let one_err = false;
        let arr_by_type = [...four_arrs[create_offer_form?.watt_par?.water_for]];

        if (create_offer_form.anal.where_from_water==='well') {
            arr_by_type =  arr_by_type.concat(four_arrs.watt_par_for_well)
        }

        arr_by_type = arr_by_type.filter(item=>create_offer_form.watt_par.hasOwnProperty(item));
        let av =  watter_data.all_values;

        let cwan =  create_offer_form.watt_par;
        arr_by_type.map(item=>{
            if (av[item].type==='select') {
                console.log(cwan[item],'select' , item)
                if (cwan[item]===null||cwan[item]===undefined) {
                    one_err = item;
                }
            }
            if (av[item].type==='switch') {
                console.log(cwan[item],'switch' , item)
                if (cwan[item]===null||cwan[item]===undefined) {
                    one_err = item+'';
                    return
                }

                if (cwan[item].constructor!==Boolean) {
                    one_err = item;
                }
            }
            if (av[item].type==='muisliderX1') {

                if (!av[item]?.scale) {
                    one_err = item+'';
                    return
                }
                if (cwan[item].value===null||cwan[item].value===undefined||cwan[item].value===av[item].scale.find(item_=>item_.name===cwan[item].type).size.min) {
                    one_err = item;
                }
            }
            if (av[item].type==='muisliderX2') {
                if (!av[item]?.scale) {
                    one_err = item+'';
                    return
                }
                if (cwan[item].value===null||cwan[item].value===undefined||
                    (cwan[item].value[0]===av[item].scale.find(item_=>item_.name===cwan[item].type).size.min&&
                        cwan[item].value[1]===av[item].scale.find(item_=>item_.name===cwan[item].type).size.max
                    )) {
                    one_err = item;
                }
            }
        })

        if (one_err) {
            console.log(one_err, arr_by_type)
            set_is_validated(false)
        }else {
            set_is_validated(true)
        }
    }

    React.useEffect(() => {
        _validated_()
    }, [create_offer_form?.watt_par])

    React.useEffect(() => {
        let a_v = watter_data?.all_values;
        let wfw = create_offer_form?.anal?.where_from_water;
        if (wfw!=='well') {
            set_updated_dinamic_form_for_well(null)
            return
        }
        if ( wfw && a_v &&props.step.status) {
            let arr = rend_creating_datas['data_wall']();
            // set_updated_dinamic_form_for_well([...arr])
        }
    }, [watter_data, create_offer_form?.anal?.where_from_water, props.step.status])

    React.useEffect(() => {

            if (create_offer_form?.watt_par?.water_for) {

                let arr = rend_creating_datas['water_for']();
                // set_updated_dinamic_form_for_water([...arr])
            } else (
                set_updated_dinamic_form_for_water(null)
            )


    }, [
        create_offer_form?.watt_par?.water_for,

        ])


    let rend_creating_datas = {
        data_wall: ()=> {
            if (!watter_data?.all_values||!create_offer_form?.watt_par) {
                set_updated_dinamic_form_for_well(null)
                return []
            }
            let all_vals =  {
                ...watter_data.all_values,
                // type_manip_pump: {
                //     type: 'select',
                //     label: glv('mainStepper.second_step.legends.type_manip_pump.title'),
                //     options: [
                //         {id: 'switchmat', text: glv('mainStepper.third_step.selects.type_manip_pump.switchmat')},
                //         {id: 'chastot', text: glv('mainStepper.third_step.selects.type_manip_pump.chastot')},
                //         {id: 'without_manipulating', text: glv('mainStepper.third_step.selects.type_manip_pump.without_manipulating')},
                //     ]
                // }
            }

            let branch_keys_ = ["h_wall", 'volume_wal', 'limit_wall', 'limit_pump','type_manip_pump', 'route_tank' ]
            let rend_arr_temp = branch_keys_.map((branch_key)=>({
                ...all_vals[branch_key],
                name: branch_key,
                [branch_key==='route_tank'? 'path_local_switch' : '']:
                    'mainStepper.third_step.switches.route_tank.data.'
            }));

            let temp_clone_cr_offer_form_watt_par = {...create_offer_form.watt_par};
            rend_arr_temp.map((item) => {
                temp_clone_cr_offer_form_watt_par[item.name] = format_items_to_global_form(item)
            })
            change_form_state('watt_par', '', {...temp_clone_cr_offer_form_watt_par})
            set_updated_dinamic_form_for_well([...rend_arr_temp])
            return rend_arr_temp
        },
        water_for: ()=> {


            if (!watter_data?.all_values||!create_offer_form?.watt_par) {
                set_updated_dinamic_form_for_water(null)
                return []
            }
            // console.log(create_offer_form?.watt_par, 'ertert')
            let a_v = watter_data?.all_values;
            let branch_keys_ = tree_water_for[create_offer_form?.watt_par?.water_for].keys;
            let rend_arr_temp = branch_keys_.map((branch_key) => ({
                ...a_v[branch_key],
                name: branch_key
            }));
            let temp_clone_cr_offer_form_watt_par = {...create_offer_form.watt_par};
            rend_arr_temp.map((item) => {

                if (item.type==='select'&&item.name==='sewerage_select'&&create_offer_form?.watt_par?.water_for==='flat') {
                    temp_clone_cr_offer_form_watt_par[item.name] = 0;
                    return
                }
                temp_clone_cr_offer_form_watt_par[item.name] = format_items_to_global_form(item)
            })

            if (create_offer_form.anal.where_from_water==='well'&&create_offer_form.watt_par.water_for==='restoraunt') {
                rend_arr_temp.push(       {
                    ...a_v["has_acc"],
                    name: "has_acc"
                })

                temp_clone_cr_offer_form_watt_par["has_acc"] =
                    format_items_to_global_form({
                        ...a_v["has_acc"],
                        name: "has_acc"
                    })
            }
            if (create_offer_form.anal.where_from_water==='plumb'&&create_offer_form.watt_par.water_for==='restoraunt') {
                rend_arr_temp.push(                 {
                    ...a_v["interruption_water"],
                    name: "interruption_water"
                }

                )
                rend_arr_temp.push(         {
                    ...a_v["know_pres_watt"],
                    name: "know_pres_watt"
                })

                temp_clone_cr_offer_form_watt_par["interruption_water"] =
                    format_items_to_global_form({
                        ...a_v["interruption_water"],
                        name: "interruption_water"
                    })
                temp_clone_cr_offer_form_watt_par["know_pres_watt"] =
                    format_items_to_global_form({
                        ...a_v["know_pres_watt"],
                        name: "know_pres_watt"
                    })
            }
            // console.log(temp_clone_cr_offer_form_watt_par, 'asda')
            change_form_state('watt_par', '', {...temp_clone_cr_offer_form_watt_par})
            console.log([...rend_arr_temp])
            set_updated_dinamic_form_for_water([...rend_arr_temp])
            return rend_arr_temp
        }
    }

    const  set_fields = (mod_add, field_name, arr_fieds)=> {
        if (!updated_dinamic_form_for_water) {
            return
        }
        if (mod_add&&create_offer_form?.watt_par?.hasOwnProperty(arr_fieds[0])) {
            return
        }
        if (!mod_add&&!create_offer_form?.watt_par?.hasOwnProperty(arr_fieds[0])) {
            return
        }
        console.log(field_name, arr_fieds,mod_add, updated_dinamic_form_for_water)
        let a_v = watter_data?.all_values;
        let new_br_keys = [];
        if (mod_add) {
            updated_dinamic_form_for_water.map((item)=>{
                new_br_keys.push(item.name)
                if (item.name===field_name) {
                    new_br_keys=new_br_keys.concat([...arr_fieds])
                }
            })
        } else {
            new_br_keys = updated_dinamic_form_for_water
                .map(item=>item.name)
                .filter((item)=>
                    !arr_fieds.find(item_=>item===item_))
        }

        let rend_arr_temp = new_br_keys.map((branch_key) => ({
            ...a_v[branch_key],
            name: branch_key
        }));
        let temp_clone_cr_offer_form_watt_par = {...create_offer_form.watt_par};
        console.log(temp_clone_cr_offer_form_watt_par, rend_arr_temp,new_br_keys, 'sdfsfd')
        if (!mod_add) {
            arr_fieds.forEach((item)=> {
                delete temp_clone_cr_offer_form_watt_par[item]
            })
        }
        rend_arr_temp.map((item) => {
            if (!temp_clone_cr_offer_form_watt_par.hasOwnProperty(item.name)&&mod_add) {
                temp_clone_cr_offer_form_watt_par[item.name] = format_items_to_global_form(item)
            }
        })

        console.log(temp_clone_cr_offer_form_watt_par, )
        change_form_state('watt_par', '', {...temp_clone_cr_offer_form_watt_par})
        set_updated_dinamic_form_for_water([...rend_arr_temp])
    }

    const set_conds_for_fieds = (f_name) => {
        console.log(f_name)
        let wf  = create_offer_form?.watt_par?.water_for;
        if (!wf) {
            return
        }
        if (!create_offer_form?.watt_par.hasOwnProperty(f_name)&&f_name!=='cond_res'){
            return
        }
        let fnv  = create_offer_form?.watt_par[f_name];
        switch (f_name) {
            case 'watering': {
                if (f_name!=='watering') {
                    return
                }
                if (wf==='restoraunt') {
                    return
                }
                if ((fnv||wf==='ogorod')) {
                    console.log('watering_truuuuuue')
                    set_conds({...conds,waterin: true })
                    set_fields(true, 'watering', arr_watering)
                } else {
                    set_conds({...conds,waterin: false })
                    set_fields(false, 'watering', arr_watering)
                }
            }
            case 'has_add_cons': {
                if (f_name!=='has_add_cons') {
                    return
                }
                if (wf==='restoraunt') {
                    return
                }
                let cond_arr = [...arr_has_addit_consum];
                if (create_offer_form.watt_par.additional_consumption&&fnv) {
                    arr_has_addit_consum.concat(arr_addit_consum)
                }

                if (wf==='manufactur_perf'||
                    wf==='ferm'||wf==='ogorod'||wf==='hotel'||wf==='hotel_restoran') {
                    cond_arr= [...arr_addit_consum];
                }
                if ((fnv)) {
                    console.log(fnv, f_name)
                    set_conds({...conds,has_add_cons: true })
                    set_fields(true, 'has_add_cons',
                        cond_arr)
                } else {
                    set_conds({...conds,has_add_cons: false })
                    set_fields(false, 'has_add_cons',
                        cond_arr)
                }
            }
            case 'additional_consumption': {
                if (f_name!=='additional_consumption') {
                    return
                }
                if (wf==='restoraunt') {
                    return
                }
                if (wf!=='flat') {
                    return
                }
                if (fnv===null) {
                    return
                }
                console.log(fnv, f_name, )

                if ((fnv&&fnv!==0)) {
                    set_conds({...conds,add_cons: true })
                    set_fields(true, 'additional_consumption', arr_addit_consum)
                } else {
                    set_conds({...conds,add_cons: false })
                    set_fields(false, 'additional_consumption', arr_addit_consum)
                }
            }
            case 'has_acc': {
                if (f_name!=='has_acc') {
                    return
                }
                if (wf==='flat') {
                    return
                }
                if ((fnv)) {
                    set_conds({...conds,has_acc: true })
                    set_fields(true, 'has_acc', arr_acc)
                } else {
                    set_conds({...conds,has_acc: false })
                    set_fields(false, 'has_acc', arr_acc)
                }
            }
            case 'cond_res': {
                if (f_name!=='cond_res') {
                    return
                }
                    console.log('cond_res')
                if (wf==='restoraunt') {
                    if (create_offer_form.anal.where_from_water==='well') {
                        let tmp_form = {
                            ...create_offer_form.watt_par
                        }
                        let tmp_arr = [...updated_dinamic_form_for_water];
                        arr_plumb_rest.map((item)=> {
                            tmp_arr= tmp_arr.filter(item_=>item_.name!==item)
                            if (tmp_form.hasOwnProperty(item))  {
                              delete  tmp_form[item]
                            }
                        })
                        arr_well_rest.map((item)=> {
                            tmp_arr.push({...watter_data.all_values[item], name: item})
                            tmp_form[item] =format_items_to_global_form({...watter_data.all_values[item], name: item})
                        })
                        set_updated_dinamic_form_for_water([...tmp_arr])
                        change_form_state('watt_par', '', {...tmp_form})
                    }
                    if (create_offer_form.anal.where_from_water==='plumb') {
                        let tmp_form = {
                            ...create_offer_form.watt_par
                        }
                        let tmp_arr = [...updated_dinamic_form_for_water];
                        arr_well_rest.map((item)=> {
                            tmp_arr= tmp_arr.filter(item_=>item_.name!==item)
                            if (tmp_form.hasOwnProperty(item))  {
                                delete  tmp_form[item]
                            }
                        })
                        arr_plumb_rest.map((item)=> {
                            tmp_arr.push({...watter_data.all_values[item], name: item})
                            tmp_form[item] =format_items_to_global_form({...watter_data.all_values[item], name: item})
                        })
                        set_updated_dinamic_form_for_water([...tmp_arr])
                        change_form_state('watt_par', '', {...tmp_form})
                    }
                }

            }

            case 'has_launddress': {
                if (f_name!=='has_launddress') {
                    return
                }
                if (wf!=='hotel'&&wf!=='hotel_restoran') {
                    return
                }
                if ((fnv)) {
                    set_conds({...conds,has_launddress: true })
                    set_fields(true, 'has_launddress', arr_count_laundresses)
                } else {
                    set_conds({...conds,has_launddress: false })
                    set_fields(false, 'has_launddress', arr_count_laundresses)
                }
            }
            case 'know_pres_watt': {
                if (f_name!=='know_pres_watt') {
                    return
                }
                console.log('know_pres_watt')
                if (fnv) {
                    set_conds({...conds,known_wat_pr: true })
                    set_fields(true, 'know_pres_watt', arr_wat_pres)
                } else {
                    set_conds({...conds,known_wat_pr: false })
                    set_fields(false, 'know_pres_watt', arr_wat_pres)
                }
            }
            case 'sewerage_select': {
                if (f_name!=='sewerage_select') {
                    return
                }
                console.log('sewerage_select', f_name)
                if (fnv>0) {
                    set_conds({...conds,sew: true })
                    set_fields(true, 'sewerage_select', arr_sewerage_select)
                }
                if (fnv===0) {
                    set_conds({...conds,sew: false })
                    set_fields(false, 'sewerage_select', arr_sewerage_select)
                }
            }
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(()=> {
        set_conds_for_fieds('watering')
    }, [create_offer_form?.watt_par?.watering, ])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(()=> {
        set_conds_for_fieds('has_acc')
    }, [ create_offer_form?.watt_par?.has_acc])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(()=> {
        set_conds_for_fieds('know_pres_watt')
    }, [create_offer_form?.watt_par?.know_pres_watt])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(()=> {
        set_conds_for_fieds('sewerage_select')
    }, [create_offer_form?.watt_par?.sewerage_select])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(()=> {
        set_conds_for_fieds('has_add_cons')
    }, [create_offer_form?.watt_par?.has_add_cons])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(()=> {
        set_conds_for_fieds('additional_consumption')
    }, [create_offer_form?.watt_par?.additional_consumption])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(()=> {
        set_conds_for_fieds('has_launddress')
    }, [create_offer_form?.watt_par?.has_launddress])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(()=> {
        console.log('trigger')
        set_conds_for_fieds('cond_res')
    }, [  create_offer_form?.anal?.where_from_water])



    const render_scales = (data_name)=> {
        let rend_arr = []
        let rend_arr_temp = [];
        if (data_name==='data_wall'&&updated_dinamic_form_for_well) {
            rend_arr_temp = [...updated_dinamic_form_for_well];
        }
        if (data_name==='water_for'&&updated_dinamic_form_for_water) {
            rend_arr_temp = [...updated_dinamic_form_for_water];
        }
        for (let i = 0; i< rend_arr_temp.length; i+=2){
            let temp_arr = [];
            temp_arr.push({...rend_arr_temp[i]})
            if (rend_arr_temp[i+1]) {
                temp_arr.push({...rend_arr_temp[i+1]})
            } else {

            }
            rend_arr.push([...temp_arr])
        }
        // console.log(rend_arr)
        return rend_arr.map((blocks, index)=> {
            return <RowScales
                data_scales_array={blocks}
                key_ ={index + 'blochok'}
                key ={index+'blochok_____'+'_Tretiy_Block'}
                type_page={'watt_par'}
            />
        })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const listener_0 = useCallback(()=> {
        set_que_mod({...que_mod, 0: false});
    }, [])
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const listener_1 =useCallback(()=> {
        set_que_mod({...que_mod, 1: false});
    }, [])
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const listener_2 = useCallback(()=> {
        set_que_mod({...que_mod, 2: false});
    }, [])

    // eslint-disable-next-line react-hooks/rules-of-hooks
   useEffect(()=> {

        if (que_mod[0]) {
            document.addEventListener('click', listener_0)
        }
        if (que_mod[1]) {
            document.addEventListener('click', listener_1)
        }
        if (!que_mod[0]) {
            document.removeEventListener('click', listener_0)
        }
        if (!que_mod[1]) {
            document.removeEventListener('click', listener_1)
        }
        if (!que_mod[2]) {
            document.addEventListener('click', listener_2)
        }
        if (!que_mod[2]) {
            document.removeEventListener('click', listener_2)
        }

    }, [que_mod])
    let wf  = create_offer_form?.watt_par?.water_for;
    let sew_sel  = create_offer_form?.watt_par?.sewerage_select;
    let all_v = watter_data?.all_values;


    return !watter_data||!create_offer_form?.watt_par? <Backdrop
        open={true} style={{
        zIndex: 100000,
        color: "#fff",
    }}>
        <CustomImageLoader
            image={`${process.env.PUBLIC_URL}/images/icon.svg`}
            isLoaded={true}
            circle={false}
            speed={2}
            animationType={"pulse"}
        />
    </Backdrop> :(
<form  className ={"container step step3"+props.clas}>
    <div className="step-toptext" style={{paddingTop: 24}}>

        <div className="dect">
            {glv(`mainStepper.third_step.description.p1`)}
            <br/>
            {/*<br/>*/}
            {/*{glv(`mainStepper.third_step.description.p2`)}*/}
        </div>
        <div className="mob">
            {glv(`mainStepper.third_step.description.p1`)}
            <br/>
            {/*<br/>*/}
            {/*{glv(`mainStepper.first_step.description.p2`)}*/}
        </div>
    </div>

    <div className="step-row text">
        <div className="textR_line">
            <span>{glv(`mainStepper.third_step.title`)}</span>
        </div>
    </div>
    {
        updated_dinamic_form_for_well&&create_offer_form.watt_par ?
            <>
                {render_scales('data_wall') }
                <div className="step-row text">
                    <div className="textR_line">
                        <span></span>
                    </div>
                    {/*<div className="text_under_textR_line">*/}
                    {/*    <span>*</span> {glv(`mainStepper.second_step.second_tab.ps_title`)}*/}
                    {/*</div>*/}
                </div>
            </>
            : null
    }



    <div className="step-row w50" >
        <div className="step-row-field">
            <label style={{ display: 'flex'}}>

                           <span>
                                {glv(`mainStepper.third_step.selects.what_for_water.label`)}
                               <sub>*</sub>
                           </span>

                <div
                    style={{position:'relative'}}
                    onClick={()=>set_que_mod({...que_mod, [0]: true})}
                >
                    <img
                        src={imgs.que} className="question "
                        alt="question "
                        style={{cursor: 'pointer', width: '16px', height: '16px'}}

                    />
                    {
                        que_mod[0]?
                            <QueModal
                                coord={{y: 0, x: 10}}
                                content={glv(`mainStepper.third_step.selects.what_for_water.help`)}
                            />
                            : null
                    }
                </div>
            </label>
            <CustomSelect
                options={what_for_water}
                def_value={null}
                mh={100}
                change_val={(val)=>{
                    let new_form = {...create_offer_form.watt_par};
                    if (updated_dinamic_form_for_water) {
                        updated_dinamic_form_for_water.map((item)=>{
                            delete  new_form[item.name]
                        })
                    }

                    new_form['water_for'] = val;
                    console.log(new_form)
                    change_form_state('watt_par', '', {...new_form})
                }}
                // tooltips={data_types.origin.text_arr}
            />
        </div>
    </div>
    {/*{*/}
    {/*    updated_dinamic_form_for_water&&create_offer_form.watt_par?.water_for*/}
    {/*    // &&create_offer_form.watt_par?.water_for!=='hotel_restoran'*/}
    {/*        ?*/}
    {/*        <>*/}
    {/*            {render_scales('water_for') }*/}
    {/*        </>*/}
    {/*        : null*/}
    {/*}*/}
    {create_offer_form.watt_par?.water_for&&create_offer_form.watt_par?.water_for === 'home' ?
        <WFhome all_v={all_v} conds={conds} />
        : null
    }
    {create_offer_form.watt_par?.water_for&&create_offer_form.watt_par?.water_for === 'communal_home' ?
        <WFhome all_v={all_v} conds={conds} />
        : null
    }
    {create_offer_form.watt_par?.water_for&&create_offer_form.watt_par?.water_for === 'flat' ?
        <WFflat all_v={all_v} conds={conds} />
        : null
    }

    {create_offer_form.watt_par?.water_for&&create_offer_form.watt_par?.water_for === 'ferm' ?
        <WFferm all_v={all_v} conds={conds} />
        : null
    }
    {create_offer_form.watt_par?.water_for&&create_offer_form.watt_par?.water_for === 'manufactur_perf' ?
        <WFmanuf all_v={all_v} conds={conds} />
        : null
    }
    {create_offer_form.watt_par?.water_for&&
    create_offer_form.watt_par?.water_for === 'ogorod' ?
        <WFogorod all_v={all_v} conds={conds} />
        : null
    }
    {create_offer_form.watt_par?.water_for&&
    create_offer_form.watt_par?.water_for === 'hotel' ?
        <WFhotel all_v={all_v} conds={conds} />
        : null
    }
    {create_offer_form.watt_par?.water_for&&
    create_offer_form.watt_par?.water_for === 'restoraunt' ?
        <WFrestoran all_v={all_v} conds={conds} rest_cond={create_offer_form.anal.where_from_water}/>
        : null
    }

    {create_offer_form.watt_par?.water_for&&
    create_offer_form.watt_par?.water_for === 'hotel_restoran' ?
        <WFhotelrest all_v={all_v} conds={conds} rest_cond={create_offer_form.anal.where_from_water}/>
        : null
    }

    <div className="step-row last" style={{marginTop: matches? 24 : 32}}>
        <div className="prevBtn" onClick={() => props.prevStep()}
             style={{marginRight: is_validated ? '' : 0,
                 width: !is_validated&&matches ? '100%' : ''
             }}>
            {
                glv(`mainStepper.prevStepTitle`)
            }
        </div>
        {
            is_validated? null : <div></div>
        }
        {
            is_validated ?
                <div className="nextBtnWr ">
                    <div className="nextBtn" onClick={() => {
                        set_steps({...steps, 3: true})
                        props.nextStep()
                    }}>{glv(`mainStepper.nextStepTitle`)}</div>
                    {/* <input type="submit" className="nextBtn" value={props.lang.mainStepper.nextStepTitle} onClick = {()=>props.nextStep()}/> */}
                </div> : null
        }

    </div>

</form>)
}