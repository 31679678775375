import React from 'react';
import RangeSliderComponent from './range_slider';
import SelectComponent from './custom_select';
import RadioBtnsComponent from './radio-btns';
import imgs from './jsonImgs';
import {Create_Offer_Context, glv, set_steps, steps, Watter_Data_Context} from "./App";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {format_items_to_view_form, formatDate, trim_value} from "./helpers";

export  let four_arrs = {
    fiz: [
        {
            tip: 'double',
            data: ['name', 'sname']
        },
        {
            tip: 'double',
            data: ['email', 'tel']
        },
        {
            tip: 'one',
            data: ['address']
        },
        {
            tip: 'one',
            data: ['comment']
        },
        {
            tip: 'one',
            data: ['instalation_adr']
        }
    ],
    jur: [
        {
            tip: 'double',
            data: ['nip', 'company_name']
        },
        {
            tip: 'one',
            data: ['address']
        },
        {
            tip: 'double',
            data: ['email', 'tel']
        },
        {
            tip: 'double',
            data: ['contact_name', 'comment']
        },
        {
            tip: 'one',
            data: ['instalation_adr']
        }
    ],
    "plumb": {
        "human":
            [
                {
                    tip: 'double',
                    data: ['bdsm', 'microlog']
                },
                {
                    tip: 'double',
                    data: ['ph', 'temp']
                },

                {
                    tip: 'one',
                    data: ['dir_ger']
                },
                {
                    tip: 'double',
                    data: ['color_iron', 'iron']
                },
            ],

        "technic":
            [
                {
                    tip: 'double',
                    data: ['bdsm', 'ph']
                },
                {
                    tip: 'double',
                    data: ['temp', 'dir_ger']
                },
                {
                    tip: 'double',
                    data: ['color_iron', 'iron']
                },
            ],
        "mixed":
            [
                {
                    tip: 'double',
                    data: ['bdsm', 'microlog']
                },
                {
                    tip: 'double',
                    data: ['ph', 'temp']
                },

                {
                    tip: 'one',
                    data: ['dir_ger']
                },
                {
                    tip: 'double',
                    data: ['color_iron', 'iron']
                },
            ],
    },
    "well": {
        "human":
            [
                {
                    tip: 'double',
                    data: ['iron', 'marg']
                },
                {
                    tip: 'double',
                    data: ['bdsm', 'ph']
                },
                {
                    tip: 'double',
                    data: ['temp', 'color_df']
                },
                {
                    tip: 'double',
                    data: ['color_dr', 'elect']
                },

                {
                    tip: 'double',
                    data: ['amiac_ion', 'dir_ger']
                },
                {
                    tip: 'double',
                    data: ['amoniy_nit', 'nitrats']
                },
                {
                    tip: 'double',
                    data: ['spoke', 'bleach']
                },
            ],
        "technic":
            [
                {
                    tip: 'double',
                    data: ['microlog', 'iron']
                },
                {
                    tip: 'double',
                    data: ['marg', 'bdsm']
                },
                {
                    tip: 'double',
                    data: ['ph', 'temp']
                },
                {
                    tip: 'double',
                    data: ['color_df', 'color_dr ']
                },
            ],
        "mixed":
            [
                {
                    tip: 'double',
                    data: ['iron', 'marg']
                },
                {
                    tip: 'double',
                    data: ['bdsm', 'ph']
                },
                {
                    tip: 'double',
                    data: ['temp', 'color_df']
                },
                {
                    tip: 'double',
                    data: ['color_dr', 'elect']
                },

                {
                    tip: 'double',
                    data: ['amiac_ion', 'dir_ger']
                },
                {
                    tip: 'double',
                    data: ['amoniy_nit', 'nitrats']
                },
                {
                    tip: 'double',
                    data: ['spoke', 'bleach']
                },
            ],
    },
    flat: [
        "count_peoples",
        "count_consumption_taps",
        "day_water",
        "sewerage_select",
        "count_bathrooms",
        "has_add_cons",
        "additional_consumption",
        "watering",
        "volume_ck",
        "volume_dr",
        "day_ck",
        "has_acc",
        "acc_type",
        "volume_tan",
        "hor_ver",
        "interruption_water",
        'know_pres_watt',
        "water_pres",
    ],
    manufactur_perf: [
        "inlet_pipe_diameter",
        "contin_cons_water",
        "hours_load",
        "sewerage_select",
        "has_add_cons",
        "additional_consumption",
        "watering",
        "volume_ck",
        "volume_dr",
        "day_ck",
        "has_acc",
        "acc_type",
        "volume_tan",
        "hor_ver",
        "interruption_water",
        'know_pres_watt',
        "water_pres",
    ],
    ferm: [
        "inlet_pipe_diameter",
        "contin_cons_water",
        "hours_load",
        "sewerage_select",
        "has_add_cons",
        "additional_consumption",
        "watering",
        "volume_ck",
        "volume_dr",
        "day_ck",
        "has_acc",
        "acc_type",
        "volume_tan",
        "hor_ver",
        "interruption_water",
        'know_pres_watt',
        "water_pres",
    ],
    ogorod: [
        "inlet_pipe_diameter",
        "hours_load",
        "sewerage_select",
        "has_add_cons",
        "additional_consumption",
        "volume_ck",
        "volume_dr",
        "day_ck",
        "has_acc",
        "acc_type",
        "volume_tan",
        "hor_ver",
        "interruption_water",
        'know_pres_watt',
        "water_pres",
    ],
    hotel: [
        "count_numbers",
        "count_cons_taps_simultaneously",
        "day_water",
        "sewerage_select",
        "has_launddress",
        "count_laundresses",
        "has_add_cons",
        "additional_consumption",
        "watering",
        "volume_ck",
        "volume_dr",
        "day_ck",
        "has_acc",
        "acc_type",
        "volume_tan",
        "hor_ver",
        "interruption_water",
        'know_pres_watt',
        "water_pres",
    ],
    restoraunt: [
        "count_consumption_taps",
        "day_water",
        "sewerage_select",
        "has_paramoka",
        'volume_oth',
        "has_acc",
        "acc_type",
        "volume_tan",
        "hor_ver",
        "interruption_water",
        'know_pres_watt',
        "water_pres",
    ],
    home: [
        "count_peoples",
        "count_consumption_taps",
        "day_water",
        "sewerage_select",
        "sewerage",
        "count_bathrooms",
        "has_add_cons",
        "additional_consumption",
        "watering",
        "volume_ck",
        "volume_dr",
        "day_ck",
        "has_acc",
        "acc_type",
        "volume_tan",
        "hor_ver",
        "interruption_water",
        'know_pres_watt',
        "water_pres",
    ],
    communal_home: [
        "count_peoples",
        "count_consumption_taps",
        "day_water",
        "sewerage_select",
        "sewerage",
        "count_bathrooms",
        "has_add_cons",
        "additional_consumption",
        "has_acc",
        "acc_type",
        "volume_tan",
        "hor_ver",
        "interruption_water",
        'know_pres_watt',
        "water_pres",
    ],
    hotel_restoran:[
        "count_numbers",
        "count_cons_taps_simultaneously",
        "count_consumption_taps",
        "day_water",
        "sewerage_select",
        "has_launddress",
        "count_laundresses",
        "has_paramoka",
        'has_filter',
        "has_add_cons",
        "additional_consumption",
        "watering",
        "volume_ck",
        "volume_dr",
        "day_ck",
        "has_acc",
        "acc_type",
        "volume_tan",
        "hor_ver",
        "interruption_water",
        'know_pres_watt',
        "water_pres",
    ],
    watt_par_for_well: [
        "h_wall", 'volume_wal', 'limit_wall', 'limit_pump','type_manip_pump', 'route_tank'
    ]
}


export default function StepFour(props) {
    const [distanceFromWaterIntake, setDistanceFromWaterIntake] = React.useState(0);
    const matches = useMediaQuery('(max-width:1050px)');
    const [create_offer_form, change_form_state] = React.useContext(Create_Offer_Context);
    const [watter_data, set_watter_data] = React.useContext(Watter_Data_Context);
    let a_v = watter_data?.all_values;
    let third_arr= [];
let  third_arr_wfw = [];
    if (create_offer_form.anal?.where_from_water==='well') {
        third_arr_wfw = [...four_arrs.watt_par_for_well]
            let temp_arr_ = [...third_arr_wfw];
        third_arr_wfw = [];
        for (let i = 0; i < temp_arr_.length; i += 2) {
            let temp_arr = [];
            temp_arr.push(temp_arr_[i])
            if (temp_arr_[i + 1]) {
                temp_arr.push(temp_arr_[i + 1])
            } else {

            }
            third_arr_wfw.push(
                {
                    tip: temp_arr.arr>1 ? 'double' : 'one',
                    data:     [...temp_arr]
                }
            )
        }
    }

  if (create_offer_form.watt_par.water_for) {
      third_arr  = four_arrs[create_offer_form.watt_par.water_for].filter(name=>create_offer_form.watt_par.hasOwnProperty(name));
  }

  if (third_arr.length) {
      let temp_arr_ = [...third_arr];
        third_arr = [];
      for (let i = 0; i < temp_arr_.length; i += 2) {
          let temp_arr = [];
          temp_arr.push(temp_arr_[i])
          if (temp_arr_[i + 1]) {
              temp_arr.push(temp_arr_[i + 1])
          } else {

          }
          third_arr.push(
              {
                  tip: temp_arr.arr>1 ? 'double' : 'one',
                  data:     [...temp_arr]
              }
          )
      }

  }

    // ["h_wall", 'volume_wal', 'limit_wall', 'limit_pump','type_manip_pump', 'route_tank' ]


    return (
        <form className={"container step step4" + props.clas}>

            <div className="step-row " style={{display: "flex"}}>
                <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 0 : 0}}>

                        <span style={{fontWeight: '500', color: '#000000',}}>
                            <span style={{color: '#6D6E71'}}>{glv('account.third_step.type_acc.title')}</span>
                            {': '}
                            {create_offer_form.type === 'fiz' ?
                                glv('account.third_step.type_acc.physics') :
                                glv('account.third_step.type_acc.juristics')}
                        </span>
                </div>
                <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 24 : 0}}>


                    <span style={{fontWeight: '500', color: '#000000'}}>
                        <span style={{color: '#6D6E71'}}>
                           {glv(`mainStepper.first_step.inputs.data.label`)}
                        </span>
                        {': '}
                        {
                            create_offer_form.date_crt
                            // formatDate(new Date(), 'mm.dd.yy')
                        }
                    </span>

                </div>
            </div>

            <div className="step-row " style={{display: "flex"}}>
                <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 0 : 0}}>

                        <span style={{fontWeight: '500', color: '#000000',}}>
                            <span style={{color: '#6D6E71'}}>{glv(`mainStepper.first_step.inputs.n_offer.label`)}</span>
                            {': '}
                            {
                                create_offer_form.user_arr
                            }
                        </span>
                </div>
                <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 24 : 0}}>


                    <span style={{fontWeight: '500', color: '#000000'}}>
                        <span style={{color: '#6D6E71'}}>
                           {glv(`mainStepper.first_step.inputs.id_.label`)}
                        </span>
                        {': '}
                        {
                            create_offer_form.doc_id
                            // formatDate(new Date(), 'mm.dd.yy')
                        }
                    </span>

                </div>
            </div>


            <div className="" style={{
                width: '100%', height: 1, backgroundColor: '#D9D9D9',
                marginBottom: matches ? 0 : 32,
                marginTop: matches ? 24 : 0
            }}>
                {/*<div className="textR_line">*/}
                {/*    /!*<span></span>*!/*/}
                {/*</div>*/}
            </div>

            {
                four_arrs[create_offer_form.type].map((obj) => {
                    return  (
                        <div className="step-row " style={{display: "flex"}}>
                            {
                                obj.data.map(item => {
                                    return (
                                        <div className="step-row-field" style={{
                                            width: matches ? '100%' : obj.tip ==='one' ?'100%' : '47%',
                                            marginTop: matches ? 24 : 0
                                        }}>


                                    <span style={{fontWeight: '500', color: '#6D6E71',}}>
                                        {glv(`mainStepper.first_step.inputs.${item}.label`)}{': '}
                                    </span>
                                            <div className={'fake_input'}>
                                                                        <span>
                                                                                {
                                                                                    format_items_to_view_form({
                                                                                        name: item,
                                                                                        data: create_offer_form[create_offer_form.type][item]
                                                                                    })
                                                                                }
                                                                        </span>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>

                    )
                })
            }


            <div className="" style={{
                width: '100%', height: 1, backgroundColor: '#D9D9D9',
                marginBottom: matches ? 0 : 32,
                marginTop: matches ? 24 : 0
            }}>
                {/*<div className="textR_line">*/}
                {/*    /!*<span></span>*!/*/}
                {/*</div>*/}
            </div>




            <div className="step-row " style={{display: "flex"}}>
                <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 0 : 0}}>

                        <span style={{fontWeight: '500', color: '#000000',}}>
                            <span style={{color: '#6D6E71'}}>{glv(`mainStepper.second_step.first_tab.selects.origin.label`)}</span>
                            {': '}
                            {
                                glv('mainStepper.second_step.inputs_.type_water.'+ create_offer_form.anal.where_from_water)

                            }
                        </span>
                </div>
                <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 24 : 0}}>


                        <span style={{fontWeight: '500', color: '#000000',}}>
                            <span style={{color: '#6D6E71'}}>{glv(`mainStepper.second_step.first_tab.selects.purpose.label`)}</span>
                            {': '}
                            {
                                glv('mainStepper.second_step.inputs_.type_perf_water.' +create_offer_form.anal.type_consumer)

                            }
                        </span>

                </div>
            </div>

            {
                create_offer_form.anal?.where_from_water&&create_offer_form.anal?.type_consumer ?
                four_arrs[create_offer_form.anal.where_from_water][create_offer_form.anal.type_consumer].map((obj) => {
                    return  (
                        <div className="step-row " style={{display: "flex"}}>
                            {
                                obj.data.map(item => {
                                    let data_format = {
                                        name___: item,
                                        name: 'watt_par',
                                        data: create_offer_form.anal[item]
                                    };
                                    if (a_v&&a_v[item].type==='select') {
                                        if ( create_offer_form.anal[item]) {
                                            data_format.data = a_v[item].options[create_offer_form.anal[item]].text;
                                        }
                                    }
                                    return (
                                        <div className="step-row-field" style={{
                                            width: matches ? '100%' : obj.tip ==='one' ?'100%' : '47%',
                                            marginTop: matches ? 24 : 0
                                        }}>


                                    <span style={{fontWeight: '500', color: '#6D6E71',}}>

                                      {
                                          create_offer_form.anal.hasOwnProperty(item) ?
                                          glv('mainStepper.second_step.legends.'+ item+'.title', 'str', true)
                                        : null
                                      }
                                    </span>
                                            <div className={'fake_input'}>
                                                                        <span>
                                                                                {
                                                                                    format_items_to_view_form(data_format)
                                                                                }
                                                                        </span>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>

                    )
                })
                    : null
            }


            <div className="" style={{
                width: '100%', height: 1, backgroundColor: '#D9D9D9',
                marginBottom: matches ? 0 : 32,
                marginTop: matches ? 24 : 0
            }}>
                {/*<div className="textR_line">*/}
                {/*    /!*<span></span>*!/*/}
                {/*</div>*/}
            </div>


            {
                create_offer_form.anal?.where_from_water ?
                    third_arr_wfw.map((obj) => {

                        return  (
                            <div className="step-row " style={{display: "flex"}}>
                                {
                                    obj.data.map(item => {
                                        let data_format = {
                                            name___: item,
                                            name: 'watt_par',
                                            data: create_offer_form.watt_par[item]
                                        };
                                        if (a_v&&a_v[item].type==='select') {
                                            if (create_offer_form.watt_par[item]!==null&&create_offer_form.watt_par[item]!==undefined) {
                                                data_format.data = a_v[item].options.find((option)=>option.id===create_offer_form.watt_par[item])?.text
                                            }
                                        }

                                        return (
                                            <div className="step-row-field" style={{
                                                width: matches ? '100%' : obj.tip ==='one' ?'100%' : '47%',
                                                marginTop: matches ? 24 : 0
                                            }}>


                                    <span style={{fontWeight: '500', color: '#6D6E71',}}>

                                      {
                                          create_offer_form.watt_par.hasOwnProperty(item) ?
                                              glv('mainStepper.second_step.legends.'+ item+'.title', 'str', true)
                                              : null
                                      }
                                    </span>
                                                <div className={'fake_input'}>
                                                                        <span>
                                                                                {
                                                                                    format_items_to_view_form(data_format)
                                                                                }
                                                                        </span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>

                        )
                    })
                    : null
            }



            {
                create_offer_form.anal?.where_from_water==='well' ?
                    <div className="" style={{
                        width: '100%', height: 1, backgroundColor: '#D9D9D9',
                        marginBottom: matches ? 0 : 32,
                        marginTop: matches ? 24 : 0
                    }}>
                        {/*<div className="textR_line">*/}
                        {/*    /!*<span></span>*!/*/}
                        {/*</div>*/}
                    </div> : null
            }



            <div className="step-row " style={{display: "flex"}}>
                <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 0 : 0}}>

                        <span style={{fontWeight: '500', color: '#000000',}}>
                            <span style={{color: '#6D6E71'}}>{glv(`mainStepper.third_step.selects.what_for_water.label`)}</span>
                            {': '}
                            {
                                glv('mainStepper.third_step.selects.what_for_water.'+create_offer_form.watt_par?.water_for)
                            }
                        </span>
                </div>
            </div>

            {
                create_offer_form.anal?.where_from_water&&create_offer_form.anal?.type_consumer&&create_offer_form.watt_par?.water_for ?
                    third_arr.map((obj) => {
                        return  (
                            <div className="step-row " style={{display: "flex"}}>
                                {
                                    obj.data.map(item => {
                                        let data_format = {
                                            name___: item,
                                            name: 'watt_par',
                                            data: create_offer_form.watt_par[item]
                                        };
                                        if (a_v&&a_v[item].type==='select') {
                                            if (create_offer_form.watt_par[item]!==null&&create_offer_form.watt_par[item]!==undefined)  {
                                                data_format.data = a_v[item].options.find((option)=>option.id===create_offer_form.watt_par[item])?.text
                                            }
                                        }
                                        return (
                                            <div className="step-row-field" style={{
                                                width: matches ? '100%' : obj.tip ==='one' ?'100%' : '47%',
                                                marginTop: matches ? 24 : 0
                                            }}>


                                    <span style={{fontWeight: '500', color: '#6D6E71',}}>

                                      {
                                          create_offer_form.watt_par.hasOwnProperty(item) ?
                                              glv('mainStepper.second_step.legends.'+ item+'.title', 'str', true)
                                              : null
                                      }
                                    </span>
                                                <div className={'fake_input'}>
                                                                        <span>
                                                                                {
                                                                                    format_items_to_view_form(data_format)
                                                                                }
                                                                        </span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>

                        )
                    })
                    : null
            }

            <div class="step-row last">
                <div class="prevBtn" onClick={() => props.prevStep()}>
                    {glv(`mainStepper.prevStepTitle`)}
                </div>
                <div class="nextBtnWr ">
                    <div class="nextBtn" onClick={() => {
                        set_steps({...steps, 4: true})
                        props.nextStep()
                    }}>{glv(`mainStepper.nextStepTitle`)}</div>
                    {/* <input type="submit" class="nextBtn" value={props.lang.mainStepper.nextStepTitle} onClick = {()=>props.nextStep()}/> */}
                </div>
            </div>
        </form>)
}