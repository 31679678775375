import { combineReducers } from "redux";
// import authReducer from "./authReducers/authReducer";
import envReducer from "./env.reducer";
import statisticReducer from './statistic.reducer';
import devicesReducer from './devices.reducer';

const rootReducer = combineReducers({
  envReducer,
  statisticReducer,
  devicesReducer
  // authReducer,
});

export default rootReducer;
