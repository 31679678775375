import React from 'react';
import RadioBtnsComponent from './radio-btns';
import {useInput} from './hooks/useInput';
import Map from './Map';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import '@paraboly/react-osm-geocoding/dist/index.css'
import useDebounce from './hooks/useDebounce';
import {Create_Offer_Context, glv, steps, TPW_Context} from "./App";
import AdresInput from "./AdresInput";
import PhoneInput from "./phone_number_input"
import {  set_steps,create_template} from "./App";
import RadioParaComponent from './radio_btn_para'
import { IMaskInput } from 'react-imask';
import {assocPath} from 'ramda';
import MainService from "./services/main_service";
import CustomImageLoader from "react-custom-image-loader.";
import Backdrop from "@material-ui/core/Backdrop";
import  {nip_validate, pesel_validate, validateEmail} from './helpers';
import RadioTabComponent from "./RadioParaTabComponent";
import {ErrorText} from "./helpers_components";
import {Docs} from "./cr_offer_doc_const_component";
import {four_arrs} from "./step_four";
const debounce = require('debounce');




const convert_type = {
    true: 'fiz',
    false: 'jur',
    fiz: true,
    jur: false

}

export default function StepOne(props) {
    const matches = useMediaQuery('(max-width:1025px)');
    const [create_offer_form, change_form_state] = React.useContext(Create_Offer_Context);

    let {type} = create_offer_form;

    //general

    const [oferty, set_oferty] = React.useState('');

    const [firm_osob, set_firm_osob] = React.useState(false);

    const [show_pub_loading, set_show_pub_loading] = React.useState(false);

    const [city_err, set_city_err] = React.useState(false);

    // fiz

    const [errors, set_errors] = React.useState({
        email_error: false,
        nip: false,
        sname: false,
        fname: false,
        c_address: false,
        cont_name: false,
        tel_err: false
    });

    const [is_loading, set_is_loading] = React.useState(false);

    const [is_validated, set_is_validated] = React.useState(false);


    const change_type = (new_type, old_type, )=> {
        if (type===new_type) {
            return
        }else {
            let new_form =                 {
                ...create_offer_form,
                type:  new_type,
                [new_type]: {
                    ...create_template(new_type)
                }
            }
            delete new_form[old_type];

            change_form_state(
                'g',
                '',
                {...new_form}
            )
        }
    }

    const change_email = React.useCallback((value)=> {
        let check = validateEmail(value)


        if (!check && value) {
            set_errors({
                ...errors,
               email_error: glv(`mainStepper.first_step.inputs.errors.email_error`)
        })
        } else {
            set_errors({
                ...errors,
                email_error:false
            })
        }
        change_form_state('fiz_jur', 'email', value)
    }, [errors, type])

    const change_input = React.useCallback((value, path)=> {
        change_form_state('fiz_jur', path, value)
    }, [errors, type])

    const validate_nip_input = (nip)=> {

        if(nip.length === 10) {
            let validate = nip_validate(nip);
            if (!validate) {
                return glv('account.second_step.errors.nip_error')
            } else {
                return false
            }
        }
        if (nip.length<11&&create_offer_form[type].nip) {
            return false
        }
        return false
    }

    const handle_on_accept =
        (value, mask) => {
            let space_removed_val = value.replace(/\s+/g, '').replace(/[^\d]/g, '').trim();

            if (Number.isNaN(space_removed_val)) {
                return
            }
            if (/\D/.test(space_removed_val)) {
                return
            }
            let error_ = validate_nip_input(space_removed_val)

            let nip = value;
            let cn= '';
            let adr = '';

            let change_state = ()=> {
                change_form_state('g', '',{
                    ...create_offer_form,
                    'jur': {
                        ...create_offer_form['jur'],
                        nip: nip,
                        company_name: cn,
                        address: adr
                    }
                })
            }

            if (!error_&&space_removed_val.length===10) {
                set_is_loading(true)
                MainService.get_firm_for_nip(space_removed_val).then((res)=> {

                    if (!res?.info?.nip?.reg) {
                        set_is_loading(false)
                        set_errors({...errors, nip:glv('account.second_step.errors.no_such_firm')})
                        change_state()
                        return
                    } else {
                        set_is_loading(false)
                        set_errors({...errors, nip:error_})
                        // change_input(value, 'nip')
                         cn = res?.info?.nip?.name;
                          adr =  res?.info?.nip?.full_adr;
                        change_state()
                        return
                    }
                })
                return
            }

            set_errors({...errors, nip:error_})
            change_state()


            return

        }

   const _validate_ = ()=> {
        let one_err = false;
        let arr_by_type = [];
        four_arrs[create_offer_form.type].map((item)=> {
            arr_by_type =  arr_by_type.concat(item.data)

        })
       arr_by_type.map(item=>{
           if (item==='instalation_adr') {
               if (!create_offer_form[type][item].string_adr) {
                   one_err =item;
               }
           } else {
               if (!create_offer_form[type][item]) {
                   one_err=item;
               }
           }

       })
       Object.keys(errors).map((item)=>{
           if (errors[item]) {
               one_err = item
           }
       })

       if (one_err&&one_err!=='comment') {
           set_is_validated(false)
       }else {
           set_is_validated(true)
       }
   }


    React.useEffect(() => {
        _validate_()
    }, [create_offer_form, errors]);

    function validate_osob_form() {
        let some_error = false;
        return !some_error
    }

    function validate_firm_form() {
        let some_error = false;

        return !some_error
    }

    function validate_form_before_next_step() {
        switch (firm_osob) {
            case 0:
                return validate_osob_form()
            case 1:
                return validate_firm_form()
            default:
                return false
        }

    }



    return !create_offer_form.hasOwnProperty(type) ?
        <Backdrop
            open={true} style={{
                zIndex: 100000,
                color: "#fff",
            }}>
            <CustomImageLoader
                image={`${process.env.PUBLIC_URL}/images/icon.svg`}
                isLoaded={true}
                circle={false}
                speed={2}
                animationType={"pulse"}
            />
        </Backdrop> :

        (
        <form className={"container step step1" + props.clas}>
            <Backdrop
                open={is_loading} style={{
                zIndex: 100000,
                color: "#fff",
            }}>
                <CustomImageLoader
                    image={`${process.env.PUBLIC_URL}/images/icon.svg`}
                    isLoaded={is_loading}
                    circle={false}
                    speed={2}
                    animationType={"pulse"}
                />
            </Backdrop>
           <Docs create_offer_form={create_offer_form}/>
            <div className="step-toptext" style={{marginTop: matches ? 24 : 0}}>

                <div className="dect">
                    {glv(`mainStepper.first_step.description.p1`)}
                    <br/>
                    <br/>
                    {glv(`mainStepper.first_step.description.p2`)}
                </div>
                <div className="mob">
                    {glv(`mainStepper.first_step.description.p1`)}
                    <br/>
                    <br/>
                    {glv(`mainStepper.first_step.description.p2`)}
                </div>
            </div>


            <div style={{marginTop: matches ? 24 : 0}}>
                <RadioTabComponent
                    tab_value={convert_type[type]}
                    set_tab_value={(type_) => {
                        change_type(convert_type[type_], convert_type[!type_])
                    }}
                    btn_w={'192px'}
                    wr_style={{marginBottom: 0}}
                    sub_lab={"*"}
                    path_={`mainStepper.first_step.radio_btns_blocks.representation_block.text_values.data.`}
                />
            </div>



            {
                !create_offer_form[type]||create_offer_form[type].constructor!==Object ? <div></div> : <>
                    <div style={{marginTop: matches ? 0 : 32, }}>


                        <div className="step-row " style={{display:convert_type[type] ? 'flex': "none" }}>

                            <div className="step-row-field"
                                 style={{width: matches ? '100%' : '47%', justifyContent: 'space-between'}}>
                                <label
                                    htmlFor="sf8">{glv(`mainStepper.first_step.inputs.tel.label`)}<sub>*</sub></label>
                                {

                                    type==='fiz' ?
                                        <PhoneInput
                                            id={'ph_fiz'}
                                            ln={props.lang_name}
                                            form_={create_offer_form[type]}
                                            set_form={(f_) => {
                                                change_input({...f_.tel}, 'tel')
                                            }}
                                            change_err_state={(status)=>{
                                                if (errors.tel_err!==status) {
                                                    set_errors({...errors, tel_err: status})
                                                }
                                            }
                                            }
                                            path={'tel'}
                                        /> : null

                                }

                            </div>
                            <div style={{
                            }}>
                            </div>


                        </div>

                        <div className="step-row w100" style={{display:convert_type[type] ? 'flex': "none" }}>
                            <div className="step-row-field"
                                 style={{width: matches ? '100%' : '47%', position: 'relative'}}>
                                <label >{glv(`mainStepper.first_step.inputs.name.label`)}
                                    <sub>*</sub></label>
                                <input type={"text"} id="sf001"
                                       style={{border: errors.fname ? '1px solid #e00052' : ''}}
                                       placeholder={glv(`mainStepper.first_step.inputs.name.placeholder`)}
                                       value={create_offer_form[type].name}
                                       onChange={(e) => {
                                           change_input(e.target.value, 'name')
                                       }
                                       }
                                       required/>

                                <ErrorText err={errors.fname}/>

                            </div>

                            <div className="step-row-field"
                                 style={{width: matches ? '100%' : '47%', position: 'relative'}}>
                                <label htmlFor="sf10">{glv(`mainStepper.first_step.inputs.sname.label`)}
                                    <sub>*</sub></label>
                                <input type={"text"} id="sf001"
                                       placeholder={glv(`mainStepper.first_step.inputs.sname.placeholder`)}
                                       style={{border: errors.sname ? '1px solid #e00052' : ''}}
                                       value={create_offer_form[type].sname}
                                       onChange={(e) => {
                                           change_input(e.target.value, 'sname')
                                       }
                                       }
                                       required/>
                                <ErrorText err={errors.sname}/>
                            </div>
                        </div>

                        <div className="step-row " style={{display:convert_type[type] ? 'flex': "none" }}>
                            <div className="step-row-field"
                                 style={{width: matches ? '100%' : '47%', position: 'relative'}}>
                                <label style={{fontFamily:'Lato'}}>{glv(`mainStepper.first_step.inputs.email.label`)}
                                    <sub>*</sub></label>
                                <input
                                    type="email"
                                    id="sf10"
                                    style={{border: errors.email_error ? '1px solid #e00052' : ''}}
                                    placeholder={glv(`mainStepper.first_step.inputs.email.placeholder`)}
                                    value={create_offer_form[type].email}
                                    onChange={(e) => {
                                        change_email(e.target.value)
                                    }
                                    }
                                    required/>

                                <ErrorText err={errors.email_error}/>
                            </div>
                            <AdresInput
                                custom_write={true}
                                handle_custom_write={(val)=> {
                                    change_input(val, 'address')
                                }}
                                form_={create_offer_form}
                                path={`${type}.address`}
                                sub={true}
                                select_variant={(element) => {
                                }}
                                set_error={(status) => {
                                    set_errors({...errors, c_address: status})
                                }
                                }
                                error={errors.c_address}
                                bind_map={false}
                                wrap_style={{width: matches ? '100%' : '47%'}}
                                label={glv(`mainStepper.first_step.inputs.address.label`)}
                                placeholder={glv(`mainStepper.first_step.inputs.address.placeholder`)}
                            />

                        </div>

                        <div className="step-row " style={{display:convert_type[type] ? 'none': "flex" }}>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%'}}>
                                <label
                                    htmlFor="sf6">{glv(`mainStepper.first_step.inputs.nip.label`)}<sub>*</sub></label>

                                <IMaskInput
                                    // type="number"
                                    inputmode="numeric"
                                    id="sf6"
                                    placeholder={glv(`mainStepper.first_step.inputs.nip.placeholder`)}
                                    // value={
                                    //     // nipnum
                                    //     create_offer_form[type].nip
                                    // }
                                    style={{border: errors.nip ? '1px solid #e00052' : ''}}
                                    mask="000-00-00-000"
                                    onAccept={

                                        handle_on_accept
                                    }
                                    onChange={(e) => {
                                        // props.set_form_('set', 'juristics.nip', e.target.value)
                                    }
                                    }
                                    required
                                />

                                <ErrorText err={errors.nip}/>
                            </div>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%'}}>
                                <label htmlFor="sf7">{glv(`mainStepper.first_step.inputs.company_name.label`)}<sub>*</sub></label>
                                <input type="text" id="sf7"
                                       placeholder={glv(`mainStepper.first_step.inputs.company_name.placeholder`)}
                                       value={create_offer_form[type].company_name}
                                       onChange={(e) => {
                                       }}
                                       style={{background: '#eeeeee'}}
                                       disabled
                                       required/>

                            </div>
                        </div>



                        <div className="step-row " style={{display:convert_type[type]  ? 'none': "flex" }}>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%'}}>
                                <label
                                    htmlFor="sf8">{glv(`mainStepper.first_step.inputs.tel.label`)}<sub>*</sub></label>

                                {
                                    type==='jur' ?
                                        <PhoneInput
                                            id={'ph_firm_contact'}
                                            ln={props.lang_name}
                                            form_={create_offer_form[type]}
                                            set_form={(f_) => {
                                                change_input({...f_.tel}, 'tel')
                                            }}
                                            change_err_state={(status)=>{
                                                if (errors.tel_err!==status) {
                                                    set_errors({...errors, tel_err: status})
                                                }
                                            }
                                            }
                                            path={'tel'}
                                        />

                                        : null
                                }


                            </div>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%'}}>
                                <label
                                    htmlFor="sf6">{glv(`account.second_step.inputs.address.label`)}<sub>*</sub></label>
                                <input
                                    id="sf6"
                                    placeholder={glv(`account.second_step.inputs.address.placeholder`)}
                                    value={create_offer_form[type].address}
                                    onChange={(e) => {
                                        change_input(e.target.value, 'address')
                                    }
                                    }
                                    style={{background: '#eeeeee'}}
                                    disabled={true}
                                    required
                                />
                                <ErrorText />
                            </div>
                        </div>


                        <div className="step-row " style={{display:convert_type[type]  ? 'none': "flex" }}>




                            <div className="step-row-field"
                                 style={{width: matches ? '100%' : '47%', position: 'relative'}}>
                                <label htmlFor="sf10">{glv(`mainStepper.first_step.inputs.email.label`)}
                                    <sub>*</sub></label>
                                <input
                                    type="email"
                                    id="sf10"
                                    style={{border: errors.email_error ? '1px solid #e00052' : ''}}
                                    placeholder={glv(`mainStepper.first_step.inputs.email.placeholder`)}
                                    value={create_offer_form[type].email}
                                    onChange={(e) => {
                                        change_email(e.target.value)
                                    }
                                    }
                                    required/>

                                <ErrorText err={errors.email_error}/>

                            </div>

                            <div className="step-row-field" style={{width: matches ? '100%' : '47%', position: 'relative'}}>
                                <label htmlFor="sf10">{glv(`mainStepper.first_step.inputs.contact_name.label`)}
                                    <sub>*</sub></label>
                                <input type={"text"} id="sf001"
                                       placeholder={glv(`mainStepper.first_step.inputs.contact_name.placeholder`)}
                                       value={create_offer_form[type].contact_name}
                                       style={{border: errors.cont_name ? '1px solid #e00052' : ''}}
                                       onChange={(e) => {

                                           change_input(e.target.value, 'contact_name')
                                       }}
                                       required/>
                                <ErrorText err={errors.cont_name}/>
                            </div>
                        </div>


                    </div>
                    <div className="step-row w100">
                        <div className="step-row-field" style={{}}>
                            <label style={{fontFamily:'Lato'}}>{glv(`mainStepper.first_step.inputs.comment.label`)}
                            </label>
                            <textarea
                                rows={5}
                                type={"text"} id="sf001"
                                placeholder={glv(`mainStepper.first_step.inputs.comment.placeholder`)}
                                value={create_offer_form[type].comment}
                                onChange={(e) => {
                                    change_input(e.target.value, 'comment')
                                }
                                }
                            />
                        </div>
                    </div>
                    <div className="step-row w100">

                        <AdresInput
                            form_={create_offer_form}
                            set_form={(f_)=> {
                                change_input({...f_[type].instalation_adr}, 'instalation_adr')
                            }}
                            path={type+'.instalation_adr'}
                            set_error={(status) => {
                                set_city_err(status)
                            }
                            }
                            error={city_err}
                            bind_map={true}
                            label={glv(`mainStepper.first_step.inputs.instalation_adr.label`)}
                            placeholder={glv(`mainStepper.first_step.inputs.instalation_adr.placeholder`)}
                        />



                    </div>

                    {
                        (   create_offer_form[type]?.instalation_adr?.coordinate?.lat&&
                            create_offer_form[type]?.instalation_adr?.coordinate?.lon)?
                            <div style={{
                                marginTop: 30, position: 'relative',
                                // display: debouncedSearchTerm  ? 'block' : 'none'
                                // display: 'block'
                            }}>
                                <div style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: 300,
                                    background: 'rgba(61,185,229,0.75)',
                                    zIndex: 9999,
                                    display: show_pub_loading ? 'flex' : 'none',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <div style={{fontSize: 20, color: 'white'}}>Loading...</div>
                                </div>
                                <Map
                                    form_={create_offer_form[type]}
                                    set_form={(f_)=> {
                                        change_input({...f_.instalation_adr}, 'instalation_adr')
                                    }}
                                    path_adr={type+'instalation_adr'}
                                    markerPosition={{lat: 52.237049, lng: 21.017532}}
                                    popupContent={`asd`}
                                    lang_name={props.lang_name}
                                    set_show_pub_loading={(status) => set_show_pub_loading(status)}/>

                            </div> : null
                    }
                </>
            }





            <div className="step-row last" style={{marginTop: 15}}>
                {
                    is_validated ?
                        <div className="nextBtnWr first">
                            {}
                            <div className="nextBtn" onClick={() => {
                                if (!steps[1]) {
                                    change_form_state('l1', 'anal', {...create_template('anal')})
                                }
                                set_steps({...steps, 1: true})
                                props.nextStep()
                            }}>{glv(`mainStepper.nextStepTitle`)}</div>

                        </div> : null
                }

            </div>
        </form>)
}