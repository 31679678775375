import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function CustomSelect(props) {
    const ref_wrap_options = React.useRef(null);
    const ref_inp = React.useRef(null);
    const [value, setValue] = React.useState(props.def_value||'null');
    const [color_id, set_color_id] = React.useState(props.def_value);
    const [open, setOpen] = React.useState(false);
    const [mh, s_mh] = React.useState(false);

    const handleOpenClose = () => {
        if (Number.isInteger(props.only_)) {
            setOpen(false)
            return
        }

        setOpen(!open)
    }

    const handleSelect = (val) => {

        setValue(val)
        props.change_val(val)
    }

    const listener_0 = React.useCallback(()=> {
        console.log('list')
            setOpen(false);
        // document.removeEventListener('click', listener_0)
    }, [setOpen, open])
    React.useEffect(()=> {

        if (open) {
            console.log('aaaaaa')

                if (ref_wrap_options.current&&ref_inp.current) {
                    let coords_wo =  ref_wrap_options.current.getBoundingClientRect();
                    let coords_inp =  ref_wrap_options.current.getBoundingClientRect();
                    // eslint-disable-next-line no-restricted-globals

                    // eslint-disable-next-line no-restricted-globals
                    if (coords_wo.top + pageYOffset + coords_inp.height+48 > document.body.clientHeight) {

                        // eslint-disable-next-line no-restricted-globals
                        s_mh(coords_wo.height-((coords_wo.top + pageYOffset + coords_inp.height+48)- document.body.clientHeight)-20)
                        // ref_wrap_options?.current?.scrollIntoView({ behavior: "smooth" })
                    } else{
                        s_mh(false)
                    }
                // ref_wrap_options?.current?.scrollIntoView({ behavior: "smooth" })
            }

            // document.addEventListener('click', listener_0)
        }

        if (!open) {
            // document.removeEventListener('click', listener_0)
        }
        return ()=> {
            console.log('unmount')
            // document.removeEventListener('click', listener_0)
        }
    }, [open])

    React.useEffect(()=> {
        console.log('asd')

    }, [ref_wrap_options.current])
    //

    //
    // }


    return (

        <div className={`wrap_sel_block ${Number.isInteger(props.only_)? 'disabled_bg' : ''}`} onClick={() => handleOpenClose()} style={!props.style_wsb ? {} : props.style_wsb}>
            <div className="wrap_select_manipulating" style={!props.style_ws ? {} : props.style_ws}  ref={ref_inp}>
                <div className="sel_inp" style={{cursor: 'pointer'}}>
                    <span className="sel_inp_text"
                          style={{fontSize: 16, color: '#111111', fontWeight: 'normal'}}>{
                              Number.isInteger(props.only_) ? props.options[props.only_].text :
                              props.options.find((item)=>item.id===value)?.text||' '
                          }</span>
                </div>

                <div className={'wrap_options__'} >
                    <div className='wrap_options'   ref={ref_wrap_options} style={!open ? {height: '0px', opacity: 0} : {
                        // marginBottom: 25
                        // display:'flex'
                    }}>
                        <div className="options_themes select_scroll" style={open ? {display: 'flex',
                            maxHeight: mh ? mh :''
                        } : {display: 'none'}}>
                            {props.options.map((el, index) => {
                                let col_id = props.id_sel ? el.id + index + props.id_sel: el.id + index
                                return <span

                                    onClick={() => {
                                        if (color_id===col_id) {
                                            handleOpenClose();
                                            return
                                        }
                                        handleSelect(el.id);
                                        handleOpenClose();
                                        set_color_id(col_id )
                                        if (props.changeVal) {
                                            props.changeVal(el.id)
                                        }
                                    }}
                                    style={index === 0 ? {
                                        border: 'none',
                                        color: col_id === color_id ? '#3db9e5' : '#111111'
                                    } :  col_id === color_id ? {color: '#3db9e5'} : {}}
                                    title={props.tooltips? props.tooltips [el.id] : ''}
                                    className="option_thema"
                                    key={col_id}>
                    {el.text}
                    </span>
                            })}
                        </div>
                    </div>
                </div>

                {/*open?{opacity:0} :*/}
                <div style={{}}>
                    <svg style={
                        {
                            ...props.style_svg ? props.style_svg : {},
                            // background: open? 'white' : 'red',
                            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'all 0.4s'
                        }

                    }

                         onClick={() => handleOpenClose()} className="arrow_rep arrow_rep_d"
                         xmlns="http://www.w3.org/2000/svg" width="22" height="18"
                         viewBox="0 0 26 24">
                        <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                    </svg>
                </div>


                {/*<div style={open?{} : {opacity:0}}>*/}
                {/*<svg  style={props.style_svg?props.style_svg: {}} onClick={()=> handleOpenClose()} className="arrow_rep arrow_rep_up" xmlns="http://www.w3.org/2000/svg" width="22" height="18"*/}
                {/*    viewBox="0 0 26 24">*/}
                {/*    <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />*/}
                {/*</svg>*/}
                {/*</div>*/}
            </div>

        </div>
    )

}