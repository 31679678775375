import React, {useEffect} from 'react';
import {glv, setlogos} from "./App";
import ReactPhoneInput from "react-phone-input-2";
import {path, assocPath} from 'ramda'

function format_tel(tel) {
    if (!tel){
        return ''
    }
    let tel_ = tel.tel.replace(/\D/g,'');
    let tel_operator = tel.operator_code.replace(/[')', '(']/g, '');
    let tel_value = tel_operator+tel_;
    return tel_value

}

export default React.memo(
    function PhoneInput(props) {


        const [phone_number, set_phone_number] = React.useState('');
        // const [phone_number_err, set_phone_number] = React.useState('');
        const [focus_phone, set_focus_phone] = React.useState(false);
        const [phone_number_error, set_phone_number_error] = React.useState(false);
        let num_value = format_tel(path(props.path.split('.'),props.form_))
        let country_ = path(props.path.split('.'),props.form_)?.country_code;


        React.useEffect(()=>{
            // console.log(phone_number_error)
            if (props.change_err_state) {
                // console.log(phone_number_error)
                props.change_err_state(phone_number_error)
            }
        }, [phone_number_error, props.change_err_state])

        React.useEffect(()=> {
            if (props.page_==='account') {
                if (phone_number_error) {
                    if (!props.set_form('get',props.error_path)) {
                        props.set_form('set',
                            props.error_path
                            , phone_number_error)
                    }

                }
                if (!phone_number_error) {
                    if ( props.set_form('get',props.error_path)) {
                        props.set_form('set',props.error_path , phone_number_error)
                    }
                }
            }

        }, [props.set_form, props.path, phone_number_error, props.page_, props.form_, props.error_path])

        const change_tel = React.useCallback((cc, oc, tel)=>{
            if (props.page_==='account') {
                props.set_form('set', props.path,                 {
                    country_code: cc,
                    operator_code: oc,
                    tel: tel
                })
                return
            }

            let  new_form = assocPath(
                [...props.path.split('.')],
                {
                    country_code: cc,
                    operator_code: oc,
                    tel: tel
                },
                props.form_
            )
            props.set_form(new_form)

        }, [props.set_form, props.form_, props.path, props.page_])


        // console.log(country_, props.ln)
        useEffect(()=> {
            if (!country_) {
                change_tel(props.ln,
                    path(props.path.split('.'),props.form_)?.operator_code,
                    path(props.path.split('.'),props.form_)?.tel)
                country_ =props.ln;
            }
        }, [props.path,props.ln, props.form_, country_ ])


        return      (
            <ReactPhoneInput
                containerStyle={{}}
                inputExtraProps={{
                    name: "phone",
                    required: true,
                    // autoFocus: true
                }}
                disableDropdown={props.disable_change_country}
                countryCodeEditable={false}
                inputStyle={{
                    borderRadius: 4,
                    width: '100%',
                    height: 48,
                    border: `1px solid ${phone_number_error? '#e00052':  !focus_phone ? '#EEEEEE':'#3DB9E5'}` ,
                    fontFamily: 'Lato'
                }}

                buttonStyle={{
                    background: 'transparent',
                    borderRight: 'none',
                    border: `1px solid ${phone_number_error? '#e00052':  !focus_phone ? '#EEEEEE':'#3DB9E5'}` ,

                }}

                dropdownStyle={
                    {
                        boxShadow: 'none',
                        border: '1px solid #3DB9E5',
                        scrollbarWidth: 'thin',
                        scrollbarColor: ' #5e5ab9 #E3EDF7',
                        padding: '10px 0px',
                        borderRadius: 4,
                        width: '100%'
                    }
                }
                onFocus={() => set_focus_phone(true)}
                onBlur={() => set_focus_phone(false)}
                country={country_}
                value={num_value}
                onChange={(value, country) => {

                    if (value.length<country.dialCode.length) {
                        return
                    }

                    change_tel(
                        country.countryCode,
                        '(' + country.dialCode + ')',
                        '['+ value.slice(country.dialCode.length)+']'
                    )
                }
                }
                defaultErrorMessage={glv(`mainStepper.first_step.inputs.errors.phone_num_error`)}
                isValid={
                    (value, country, countries, hiddenAreaCodes) => {

                        if (!country) {
                            set_phone_number_error(false)
                            return true
                        }

                        if (value.length<country.countryCode.length) {
                            num_value = country.countryCode
                        }
                        let count_ = country.format.split(".").length - 1;
                        if (value.length < count_) {
                            set_phone_number_error(true)
                            return false
                        }

                        set_phone_number_error(false)
                        return true
                    }
                }
            />

        )

    }
)