import React, {useEffect} from 'react';

import SelectComponent from './custom_select';
import * as actions from "./redux/actions/env.actions";
import { useDispatch, useSelector } from "react-redux";
import {glv} from "./App";

// let path = './images/'

function ViewOffer(props) {
    const dispatch = useDispatch();

    const language = useSelector((state) => state.envReducer.language);


    useEffect(() => {
        document.title =glv('document_titles.main_title');
    }, [])
    const set_language = React.useCallback((language) => dispatch(actions.setLanguage(language)), [
        dispatch,
    ]);
    // const [currLang, setCurrLang] = React.useState('ru');
    // const [path, setPath] = React.useState('./images/ru.png');

    // React.useEffect(()=>{
    //     setPath(`./images/${currLang}.png`);
    // },[currLang])
    return (
        <div>
            Здесь отобразится существующий оффер
        </div>
    )
}

export default ViewOffer;