import React from "react";
import {makeStyles, withStyles,fade} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EditIcon from '@mui/icons-material/Edit';
import {glv, route_paths, routes, steps} from "./App";
import ShareIcon from '@mui/icons-material/Share';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import BindBankModal from "./bind_bank_modal";
import AdresInput from "./AdresInput";
import { IMaskInput } from 'react-imask';
import ShareModal from "./Social_share_modal";
import Fade from '@mui/material/Fade';
import ConfirmModal from "./confirm_modal";
import {formatDate} from './helpers';
import {NavLink} from "react-router-dom";

const BlueOnGreenTooltip = withStyles({
    tooltip: {
        color: "#6D6E71 !important",
        backgroundColor: "white !important",
        borderRadius: '5px !important',
        boxShadow:'0px 4px 8px rgb(0 0 0 / 10%) !important',
        padding: '8px 10px !important'
    }
})(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const styles = makeStyles({
    tooltip: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        border: '.5px solid #999999',
        fontSize: '.85rem',
        fontWeight: '400'
    }
});

const ExpireCopiedModal = props => {

    React.useEffect(() => {
        setTimeout(() => {
            props.close()
        }, props.delay);
    }, [props.delay]);

    return (
        <Fade in={true}>
            <div
                className={'que_modal'}
                style={{

                    position: 'absolute',
                    top: props.pos.top,
                    left: props.pos.left,
                    zIndex: 9646846,
                    marginLeft: 16,
                    maxWidth: '200px',
                    color: '#6D6E71'
                }}
            >{glv('user_page.share_modal.copied_text')}
            </div>


        </Fade>
    )


};

function ErrorText(props) {
    const matches = useMediaQuery('(max-width:1025px)');
    return  <div style={{
        position: 'absolute',
        top: matches ? 45 : 20,
        fontSize: 13,
        left: 10,
        padding: 2,
        background: 'white',
        color: '#e00052',
        display: props.err ? 'block' : 'none'
    }}> {
        props.err
    }
    </div>
}

function trim_value(str){
    return str.replace(/\s+/g, '').replace(/[^\d]/g, '').trim()
}

function pesel_validate(r) {
    var t = Number(r.substring(2, 4));
    if (!t || t % 20 > 12)
        return !1;
    var n = Number(r.substring(4, 6));
    if (!n || n < 1 || n > 31)
        return !1;
    if (!/^[0-9]{11}$/u.test(r))
        return !1;
    var e = [1, 3, 7, 9]
        , u = ("" + r).split("").map((function(r) {
            return parseInt(r, 10)
        }
    ))
        , i = function(r, t) {
        var n = "function" == typeof Symbol && r[Symbol.iterator];
        if (!n)
            return r;
        var e, u, i = n.call(r), a = [];
        try {
            for (; (void 0 === t || t-- > 0) && !(e = i.next()).done; )
                a.push(e.value)
        } catch (r) {
            u = {
                error: r
            }
        } finally {
            try {
                e && !e.done && (n = i.return) && n.call(i)
            } finally {
                if (u)
                    throw u.error
            }
        }
        return a
    }(u.splice(-1), 1)[0]
        , a = u.reduce((function(r, t, n) {
            return r + t * e[n % 4]
        }
    )) % 10;
    return 10 - (0 === a ? 10 : a) === i
}

let copy_form = null;

const PhysicsComponent = (props) => {
    const matches = useMediaQuery('(max-width:1025px)');
    let this_form = {...props.form_};
    const [open_modal_bind_bank, set_open_modal_bind_bank] = React.useState(false);
    const [banks, set_banks] = React.useState(null);
    const [is_validated, set_is_validated] = React.useState(false);
    const {edit_mod} = props;

    let styles = useStyles();

    const validate_pesel_input = (pesel) => {

        let space_removed_val = pesel;

        if (space_removed_val.length===11) {

            let validate = pesel_validate(space_removed_val);
            if (!validate) {
                return glv('account.second_step.errors.pesel_error')
            } else {
                return false
            }
        }
        if (space_removed_val.length<11&&props.form_.physics.errors.nal_num) {
            return false
        }
    }

    React.useEffect(() => {
     if (!banks) {
         // console.log('test')
         fetch('https://offer.bregus.eu/json/banks.json')
             .then((res) => {
                 return res.json();
             })
             .then((res) => {
                 if (!res) {
                     alert('load banks error')
                     return
                 }

                 // let banks_arr = Object.keys(res).map((key)=>{
                 //     return res[key]
                 // })
                 set_banks(res)

             }).catch((err) => {
             alert('load banks error')

         })
     }

    }, [banks]);

    const physics_form_validate = () => {
        let err=false;
        let {name, sname, tel, email, nal_num, adress, errors, binded_bank} = props.form_.physics;



        if (!nal_num||errors.nal_num||!pesel_validate(trim_value(nal_num))) {
            err=true;
        }

        if (!adress.string_adr||adress.string_adr.length<10) {
            err=true;
        }

        if (!binded_bank.card_number|| binded_bank.is_valid_card_num||trim_value(binded_bank.card_number).length<26||!binded_bank.confirmed) {
            err=true;
        }
        if (!err) {
            set_is_validated(true)
        }else {
            set_is_validated(false)
        }
    }
    React.useEffect(() => {
        if (props.edit_mod) {
            physics_form_validate()
        }

    }, [props.edit_mod, props.form_]);

    return !banks ? null : (
        <>
            <BindBankModal
                card_num={props.form_.physics.binded_bank.card_number}
                change_bank_data={props.set_form_}
                banks_={banks}
                open={open_modal_bind_bank}
                close={() => {
                    set_open_modal_bind_bank(false);
                }
                }
                curr_bank={props.form_.physics.binded_bank}
            />

            {
                edit_mod ?
                    <AdresInput
                        form_={props.form_}
                        set_form={(f_)=> {
                            props.set_form_('set', 'physics.adress', f_.physics.adress)
                        }}
                        path={'physics.adress'}
                        custom_write={true}
                        handle_custom_write={(adr)=>{
                            props.set_form_('set', 'physics.adress.string_adr', adr)
                        }
                        }
sub={true}
                        error={false}
                        wrap_style={{width: matches ? '100%' : '100%'}}
                        bind_map={false}
                        label={glv(`account.second_step.inputs.address.label`)}
                        placeholder={glv(`account.second_step.inputs.address.placeholder`)}
                    /> :
                    <div className="step-row " style={{display: "flex"}}>

                        <div className="step-row-field" style={{width: '100%', marginTop: matches ? 24 : 0}}>


                    <span style={{
                        fontWeight: '500',
                        color: '#6D6E71',
                    }}>{glv('account.third_step.physics.address')}{': '}</span>
                            <div className={'fake_input'}>
                                <span>{this_form.physics.adress.string_adr}</span>
                            </div>


                        </div>
                    </div>
            }


            <div className="step-row " style={{display: "flex", marginTop: !matches&&edit_mod ? 32 : '', alignItems:edit_mod ? 'flex-end' : ''}}>

                {
                    edit_mod?

                            <div className="step-row-field" style={{width: matches ? '100%' : '100%',
                                flex: 1,
                                marginRight: matches? 0 : '20px',
                                marginBottom: matches? 24 : 0
                            }}>
                                <label
                                    htmlFor="sf6">{glv(`account.second_step.inputs.nal_num.label`)}<sub>*</sub></label>
                                <IMaskInput
                                    id="sf6"
                                    style={{border: props.form_.physics.errors.nal_num ? '1px solid #e00052' : '', borderRadius:'0px 5px 5px 0px',}}
                                    placeholder={glv(`account.second_step.inputs.nal_num.placeholder`)}
                                    mask="0000 00 0000 0"
                                    inputmode="numeric"
                                    name="pesel"
                                    value={props.form_.physics.nal_num}
                                    onAccept={
                                        (value, mask) => {
                                            let space_removed_val = value.replace(/\s+/g, '').replace(/[^\d]/g, '').trim();

                                            if (Number.isNaN(space_removed_val)) {
                                                return
                                            }
                                            if (/\D/.test(space_removed_val)) {
                                                return
                                            }
                                            let error_ = validate_pesel_input(space_removed_val)
                                            props.set_form_('set', 'physics', {
                                                ...props.form_.physics,
                                                nal_num: value,
                                                errors: {
                                                    ...props.form_.physics.errors,
                                                    nal_num: error_
                                                }
                                            })

                                        }
                                    }
                                    required
                                />

                                <ErrorText err={props.form_.physics.errors.nal_num}/>
                            </div>

                        :
                        <div className="step-row-field"
                             style={{width: matches ? '100%' : '100%', marginTop: matches ? 24 : 0, flex: 1}}>


                    <span style={{
                        fontWeight: '500',
                        color: '#6D6E71',
                    }}>{glv('account.third_step.physics.pesel')}{': '}</span>
                            <div className={'fake_input'}>

                                                                            <span>{
                                                                                this_form.physics.nal_num
                                                                            }</span>
                            </div>

                        </div>
                }


                {
                    edit_mod ?

                            <div className="step-row-field" style={{width:  '100%', flex: 2 }}>
                                {

                                     props.form_.physics.binded_bank.card_number

                                    && props.form_.physics.binded_bank.confirmed
                                        ?
                                        <div style={{width: '100%', display: 'flex', cursor: 'pointer', }}

                                        >

                                            <div style={{border: '1px solid rgb(238,238,238)', width: 48, height: 48,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius:'5px 0px 0px 5px',
                                                position: 'relative'
                                            }}>
                                                <div style={{position: 'absolute', width: '100%', height: '100%', zIndex: 65866868846}}
                                                     onClick={()=>{
                                                         set_open_modal_bind_bank('sel_bank')
                                                     }
                                                     }
                                                ></div>
                                                <img src={props.form_.physics.binded_bank.logo_img}
                                                     style={{
                                                         width: '55%',
                                                         height: '55%',
                                                         borderRadius:'2px',
                                                     }}/>
                                            </div>
                                            <div style={{width: '100%', position: 'relative'}}>
                                                <div style={{position: 'absolute', width: '100%', height: '100%', zIndex: 65866868846}}
                                                     onClick={()=>{
                                                         set_open_modal_bind_bank('card_num')
                                                     }
                                                     }
                                                ></div>
                                                <input type="text" id="sf7"
                                                       style={{width: '100%', borderRadius: '0px 5px 5px 0px',cursor: 'pointer', background: '#eeeeee'}}
                                                       placeholder={glv(`account.second_step.inputs.email_f.placeholder`)}
                                                    // style={{border: firmname_err ? '1px solid #e00052' : ''}}
                                                       value={props.form_.physics.binded_bank.card_number}
                                                       onChange={(e) => {
                                                           // props.set_form_('set', 'physics.sname', e.target.value)
                                                       }}
                                                       disabled={true}
                                                       required/>
                                            </div>

                                        </div> :
                                        <div className="nextBtn" style={{width: '100%', minWidth: 126, height: 48,}}
                                             onClick={() => {
                                                 set_open_modal_bind_bank(true)
                                             }}>{glv(`account.second_step.buttons.bind_bank`)}
                                        </div>
                                }

                            </div>

                        :
                <div className="step-row-field"
                     style={{width: matches ? '100%' : '78%', marginTop: matches ? 24 : 0, flex: 2, marginBottom: matches? 24 : 0}}>
                    <span style={{fontWeight: '500', color: '#6D6E71',}}>
                                    {/*{props.form_.physics.binded_bank.name}*/}
                        {glv('account.third_step.physics.card_num')}
                        {': '}
                    </span>
                    <div className={'fake_input'}
                         style={{display: 'flex', height: 48, padding: 0, alignItems: 'center'}}>
                        <div
                            style={{
                                // borderRight: '1px solid rgb(238,238,238)',
                                width: 48,
                                height: 48,
                                borderRadius: '2px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <img src={this_form.physics.binded_bank.logo_img}
                                 style={{width: '55%', height: '55%', borderRadius: '2px'}}/>
                        </div>
                        <span style={{marginLeft: 10}}>{this_form.physics.binded_bank.card_number}</span>
                    </div>


                </div>

                }
            </div>

            {
                props.edit_mod ?
                    <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop:matches? 24: 0}}>

                        <div className="canc_edited_btn"

                             onClick={() => {
                            props.close_edit_mod();
                            props.set_form_('full_set', '', {...copy_form})
                            // copy_form=props.form_;
                        }}
                             style={{
                                 marginRight: 10,
                                 width: !is_validated&&matches ? '100%' : '',
                                 flex:1,

                             }}>

                            {glv('user_page.btns.cancel_edited')}

                        </div>

                        {
                            is_validated ?
                            <div className="save_edited_btn" onClick={() => {
                            props.close_edit_mod();
                            // props.set_form_('set', '', {...copy_form})
                            copy_form = {...props.form_};
                        }}
                              style={{
                                  marginRight: 10,
                                  width: !is_validated && matches ? '100%' : '',
                                  flex: 1
                              }}>

                            {glv('user_page.btns.save_edited')}

                        </div> : null}
                        {/*<div className="canc_edited_btn" onClick={() => {*/}
                        {/*    props.close_edit_mod();*/}
                        {/*    props.set_form_('set', '', {...copy_form})*/}
                        {/*    copy_form=props.form_;*/}
                        {/*}}*/}
                        {/*     style={{marginRight: is_validated ? '' : 0,*/}
                        {/*         width: !is_validated&&matches ? '100%' : ''*/}
                        {/*     }}>*/}

                        {/*    {glv('user_page.btns.cancel_edited')}*/}

                        {/*</div>*/}



                    </div> : null
            }

        </>
    )
}

const JuristicsComponent = (props) => {
    const matches = useMediaQuery('(max-width:1025px)');
    let this_form = {...props.form_};
    return (
        <>
            <div className="step-row " style={{display: "flex"}}>
                <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 24 : 0}}>


                    <span style={{
                        fontWeight: '500',
                        color: '#6D6E71',
                    }}>{glv('account.third_step.juristics.nip')}{': '}</span>
                    <div className={'fake_input'}>
                        <span>{this_form.juristics.nip}</span>
                    </div>


                </div>
                <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 24 : 0}}>


                    <span style={{
                        fontWeight: '500',
                        color: '#6D6E71',
                    }}>{glv('account.third_step.juristics.region')}{': '}</span>
                    <div className={'fake_input'}>
                        <span>{this_form.juristics.reg}</span>
                    </div>


                </div>
            </div>
            <div className="step-row " style={{display: "flex"}}>

                <div className="step-row-field" style={{width: '100%', marginTop: matches ? 24 : 0}}>


                    <span style={{
                        fontWeight: '500',
                        color: '#6D6E71',
                    }}>{glv('account.third_step.juristics.address')}{': '}</span>
                    <div className={'fake_input'}>
                        <span>{this_form.juristics.adress}</span>
                    </div>


                </div>
            </div>

        </>
    )
}
let type_acc = {
    'f': 'physics',
    'j': 'juristics'
}

let data_component = {
    'f': (props) => <PhysicsComponent {...props}/>,
    'j': (props) => <JuristicsComponent {...props}/>
}



const UserPageComponent = (props) => {
    const classes = useStyles();
    const matches = useMediaQuery('(max-width:1025px)');
    const matches_500 = useMediaQuery('(max-width:500px)');

    const [edit_mode, set_edit_mode] = React.useState(false);
    const [open_share_modal, set_share_modal] = React.useState(false);
    const [copied_mouse_pos, set_copied_mouse_pos] = React.useState(null);
    const [hover_edit_info_btn, set_hover_edit_info_btn] = React.useState(false);
    const [not_sliced_useragents, set_not_sliced_useragents] = React.useState([]);
    let this_form = {...props.form_};

    const [open_confirm_modal, set_open_confirm_modal] = React.useState(false);
    function zapasnoy_copytext(e) {
        e.clipboardData.setData('text/plain', glv('user_page.share_modal.description_post') + ' '+this_form[t_a].link_qr);
        e.preventDefault();
    }


    const handle_copy_to_buffer = ()=> {

        navigator.clipboard.writeText(glv('user_page.share_modal.description_post') + ' '+`https://i.bregus.eu/register#${this_form.ref_code}`).then(function() {

            set_copied_mouse_pos(true)
        }, function() {
            // document.execCommand("copy");
        });

    }
    // console.log(this_form)
    let t_a = type_acc[this_form.physics_juristics];

    let DataComponent = (props_) => data_component[this_form.physics_juristics](props_)



    React.useEffect(()=> {
        // document.addEventListener('copy', zapasnoy_copytext);
        return ()=> {
            // document.removeEventListener('copy', zapasnoy_copytext)
        }
    }, [])

    const format_useragent = (ses) => {
        if (!ses?.text) {
            console.log(ses)
            return 'error useragent'
        }
        if (not_sliced_useragents.find((id)=>id===ses.id))
        {

            return ses.text
        }  else {
            return ses.text.slice(0, 51)+'...'
        }
    }

    const handle_click_user_agent_ses = (e) => {
        // if (not_sliced_useragents.find((id)=>id===ses.id))
        // {
        //     return ses.text
        // }  else {
        //     return ses.text.slice(0, 21)+'...'
        // }
    }



    return <div style={{width: '100%', marginTop: matches ? 1 : 96}}>
        <ShareModal open={open_share_modal} qr_link={this_form[t_a].link_qr} close = {()=>set_share_modal(false)}/>
        <div className={"container step step1 active " + props.clas} style={{boxShadow: matches ? 'none' : ''}}>
            <div className={'step-row user_page_header'}
                 style={{display: 'flex', flexDirection: matches ? 'column-reverse' : '',}}>
                <div className={'step-row-field user_page_datablock'} style={{marginTop: matches ? '24px' : ''}}>
                    <div className={'user_page_ucode_date'}>
                        <div className={'user_page_ucode'}>
                            <span className={'user_page_ucode_text'}>{this_form.u_code}</span>
                            {/*<span className={'user_page_text-divider'}>|</span>*/}
                        </div>
                        <div className={'user_page_date'}>
                            <span className={'user_page_date_text'}>{this_form.date}</span>
                        </div>
                    </div>
                    {/*<div className={'user_page_fio'}>{this_form[t_a].name_}</div>*/}
                    <div className={'user_page_mail_tel'}
                         style={{flexDirection:"column", marginTop: matches_500 ? '24px' : '', color: 'black'}}>
                        <div className={'user_page_fio'} style={{
                            marginTop: matches_500? 24:0,
                            marginBottom: 32,
                            fontSize: 20,

                            flex: 1}}>{this_form[t_a].name_}</div>
                       <div style={{display: 'flex', flexDirection:matches_500 ?"column" : ''}}>
                           <div className={'user_page_mail'}
                                style={{justifyContent: matches_500 ? "center" : '', display: 'flex'}}>
                               {this_form[t_a].email}
                           </div>

                           <div className={'user_page_tel'}
                                style={{justifyContent: matches_500 ? "center" : '', marginTop: matches_500 ? 24 : ''}}>
                               {this_form[t_a].tel_}
                           </div>
                       </div>


                    </div>
                </div>
                <div className={' step-row-field user_page_qr_block'}>
                    <div className={'wrap_qr_img'}>
                        <div className={"wrapper_qr_block"}>
                            <div className={"qr_block"}>
                                <img src={this_form[t_a].link_qr}
                                     style={{
                                         width: '100%',
                                         height: '100%',
                                         border: '1px solid #d9d9d9',
                                         borderRadius: 5
                                     }}/>
                            </div>
                        </div>
                    </div>
                    <div className={"wrap_qr_link"}
                         onClick={()=> {
                             if (navigator.share) {
                                 navigator.share({
                                     title: glv('user_page.share_modal.description_post'),
                                     // text: `https://i.bregus.eu/register#${this_form.ref_code}`,
                                     url: `https://i.bregus.eu/register#${this_form.ref_code}`
                                 })
                             } else {
                                 handle_copy_to_buffer()
                             }
                         }}

                         style={{
                        textAlign: matches ? 'center' : 'center', width: matches ? '100%' : '',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                             position:'relative'
                    }}>


                        <div style={{position: 'relative'}}>
                            {
                                copied_mouse_pos ?
                                    <ExpireCopiedModal delay={'1000'} close={()=>set_copied_mouse_pos(false)} pos={{top: -40, left:-15}} />
                                    :null
                            }
                            <a className={"qr_link"} href={'#'}>{glv('user_page.link_title')}</a>
                        </div>

                        <ShareIcon
                            // color={"#d9d9d9"}
                            className={'qr_link_icon'}
                            style={{marginLeft: 8, transition:'all 0.3s ease'}}/>
                    </div>

                </div>
            </div>
            <div className="" style={{
                width: '100%', height: 1, backgroundColor: '#D9D9D9',
                marginBottom: matches ? 0 : 32,
                marginTop: matches ? 31.5 : 0,
                position: 'relative'
            }}>

                <div
                    onClick={() => {
                        if (t_a === 'physics') {
                            set_edit_mode(true)
                            copy_form = {...this_form};
                        }
                    }}
                    onMouseOver={()=>{set_hover_edit_info_btn(true)}}
                    onMouseLeave={()=>{set_hover_edit_info_btn(false)}}
                    className={'edit_info_btn'}
                    style={{
                        display: edit_mode ? 'none' : 'flex'
                    }}
                >
                    {
                        t_a === 'physics' ? edit_mode ? null:
                            <EditIcon
                                style={{fill: hover_edit_info_btn ? '#6D6E71' : '#D9D9D9'}}

                                // className={'edit_icon_btn'}
                                color={
                                // '#D9D9D9'
                                'disabled'
                            }/> :
                            // <Tooltip title={glv("user_page.jur_info")}>
                            <BlueOnGreenTooltip
                                title={glv("user_page.jur_info")}
                                // classes={{tooltip: styles().tooltip}}
                                open={hover_edit_info_btn}
                            >
                            <div
                                onMouseOver={()=>{set_hover_edit_info_btn(true)}}
                                onMouseLeave={()=>{set_hover_edit_info_btn(false)}}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'}}
                                // title={glv("user_page.jur_info")}
                            >


                                    <InfoIcon color={
                                        'disabled'
                                    }
                                              style={{
                                                  fill: hover_edit_info_btn ? '#6D6E71' : '#D9D9D9'
                                              }}
                                    />


                            </div>
                             </BlueOnGreenTooltip>
                    }

                </div>

            </div>


            {
                t_a==='physics' ?
                    <PhysicsComponent form_={this_form} set_form_={props.set_form_}
                                      edit_mod={edit_mode}
                                      close_edit_mod={()=> {
                                          set_hover_edit_info_btn(false)
                                          set_edit_mode(false)
                                      }}
                    />
                    :  <JuristicsComponent form_={this_form} set_form_={props.set_form_}/>
            }
            {/*<DataComponent />*/}

            {/*<div className="step-row text">*/}
            {/*    <div className="textR_line">*/}
            {/*        <span style={{fontWeight: '500',  color: '#6D6E71', }}>*/}
            {/*            /!*{glv(`account.third_step.type_acc.title_content`)}*!/*/}
            {/*        </span>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="step-row text" style={{marginTop: edit_mode? matches? 24 : 32 : t_a!=='physics' ? 24 : 0}}>
                <div className="textR_line">
                    <span style={{color: '#6d6e71'}}>{glv('user_page.sessions_title')} </span>
                </div>

            </div>

            <div style={{display: 'flex', flexWrap: 'wrap', width: '100%',marginTop: matches? 24 : 0 }}>
                {this_form.sessions?.length ?
                    this_form.sessions.map((item, i)=> {
                        return (
                            <div style={{width: matches_500 ? '100%' : '100%',
                                marginTop: i!==0? matches ? 24 : 32 : 0,
                                // textAlign: 'center'
                            }}>


                                <div style={{
                                    display: 'flex',
                                    flexDirection: matches? 'column' : 'row',
                                    marginTop: 8,
                                    color: this_form.curr_session.id===item.id ? '#6D6E71' : '#000000'
                                }}>
                                                                    <span
                                                                        style={{  cursor: 'pointer'}}
                                                                        onClick={()=> {
                                                                        if (not_sliced_useragents.find((id)=>id===item.id))
                                                                        {
                                                                            set_not_sliced_useragents([...not_sliced_useragents].filter((id)=>id!==item.id))
                                                                        }  else {
                                                                            set_not_sliced_useragents([...not_sliced_useragents, item.id])
                                                                        }
                                                                    }}>{
                                                                        format_useragent(item)
                                                                    }</span>
                                    <span style={{
                                        flex:1,
                                        textAlign:matches ? 'start' : 'end',
                                        marginTop: matches ? 8 : 0,

                                    }}> {item.begin}{' '}-{' '}{item.end} </span>
                                    {/*<span style={{*/}
                                    {/*    marginTop: matches_500 ? 8 : 0,*/}
                                    {/*    flex:1*/}
                                    {/*}}>{glv('user_page.sessions_block.expired')}: {item.end} </span>*/}
                                </div>
                            </div>
                        )
                    }) : null
                }
            </div>
            <div
                onClick={()=>set_open_confirm_modal(true)}
                className={'cl_sess_btn'}
                style={{textAlign: 'center', marginTop: 32, cursor: 'pointer'}}>
                <span style={{}}>{glv('user_page.btns.clear_sessions')}</span>
            </div>
            <div className="" style={{
                width: '100%', height: 1, backgroundColor: '#D9D9D9',
                marginBottom: matches ? 0 : 32,
                marginTop: matches ? 24 : 32,
                position: 'relative'
            }}> </div>

            <div style={{display: 'flex', flexWrap: 'wrap', marginTop:matches? 24: 0}}>

                    {
                        routes.map((route)=> (
                            <NavLink to={route} style={{textDecoration: 'none',  flex:1, }}>
                                <div className="canc_edited_btn"

                                     onClick={() => {


                                     }}
                                     style={{
                                         marginRight: 10,
                                         marginTop: 8,
                                        whiteSpace: 'nowrap'

                                     }}>

                                    { glv(route_paths[route])}

                                </div>

                            </NavLink>
                        ))
                    }


            </div>
        </div>

        <ConfirmModal
            open={open_confirm_modal}
                      close={()=>set_open_confirm_modal(false)}
          confirm_f={()=>{
              // console.log(this_form.curr_session)
              props.set_form_('set', 'sessions',
                  [this_form.curr_session]);
              set_open_confirm_modal(false)
          }}
        />
    </div>
}

export default UserPageComponent;