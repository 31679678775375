import React from 'react';
import HeaderComponent from "./header";
import StepperComponent from "./stepper";
import * as actions from "./redux/actions/env.actions";
import { useDispatch, useSelector } from "react-redux";
import ResultModal from './result_modal';
import AccountStepper from "./AccountStepper";
import UserPageComponent from "./user_page";
// let path = './images/'
import {assocPath,  path} from "ramda";
import CustomImageLoader from "react-custom-image-loader.";
import Backdrop from "@material-ui/core/Backdrop";
import {Offer_User_Context} from "./App";
import {formatDate} from './helpers';
const ibantools = require('ibantools');

let ts_acc_ = {
    individual: 'f',
    entity: 'j'
}



function trim_value(str){
    return str.replace(/\s+/g, '').replace(/[^\d]/g, '').trim()
}

function wrap_str(value) {
    return String(value)
}

function UserPage(props) {
    const dispatch = useDispatch();
    const [offer_acc_data, set_offer_acc_data] = React.useContext(Offer_User_Context)
    const language = useSelector((state) => state.envReducer.language);
    const set_language = React.useCallback((language) => dispatch(actions.setLanguage(language)), [
        dispatch,
    ]);

    let sessions_ = [
        ...offer_acc_data.sessions.map((session, index)=> {
            return {
                id: session.hash_key+index,
                text: session.useragent,
                begin:formatDate(new Date( session.begin*1000), 'mm-dd-yy'),
                end: formatDate(new Date( session.end*1000), 'mm-dd-yy'),
                hash: session.hash_key
            }
        })
    ]

    const fiz = offer_acc_data.type==='individual';
    const [form, set_form] = React.useState({
        date: formatDate(new Date(offer_acc_data.date*1000), 'mm-dd-yy'),
        u_code: offer_acc_data.id,
        ref_code: offer_acc_data.ref_id,
        physics_juristics: ts_acc_[offer_acc_data.type],
        sessions:[
            ...sessions_
        ],
        curr_session: {
            ...sessions_.find((session) => session.hash.length > 10)
        },
        physics: {
            link_qr: 'https://api.bregus.eu/ap43x6z6375gxud4/load/qr_partner?data=T12345678&fiz',
            errors: {
                email: false,
                nal_num: false,
                phone_num: false,
                name: false,
                sname: false,

            },
            binded_bank: {
                bank_id:  fiz ? offer_acc_data?.akk_data?.bank?.id_bank||'mBank'  : 'mBank',
                card_number:fiz ? ibantools.friendlyFormatIBAN('PL'+wrap_str(offer_acc_data?.akk_data?.bank?.num).slice(1)).slice(2) : '55',
                is_valid_card_num: false,
                logo_img: '',
                    // 'https://offer.bregus.eu/images/bank/ico/mbank.png',
                name: '',
                bank_code: '',
                confirmed: true
            },
            name: '',
            sname: '',
            tel_: '+'+ trim_value(wrap_str(offer_acc_data?.akk_data?.tel?.operator_code||'55').slice(1))+' '+
                trim_value(wrap_str(offer_acc_data?.akk_data?.tel?.tel||'55').slice(1)).match(new RegExp('.{1,3}', 'g')).join("-"),
            name_: offer_acc_data?.akk_data?.name||'name',
            tel: {
                "country_code": "pl",
                "operator_code": "48",
                "tel": ""
            },
            email: offer_acc_data.akk_data.email,
            nal_num: wrap_str(offer_acc_data?.akk_data?.pesel||'').slice(1).slice(0,4)+' '
                +wrap_str(offer_acc_data?.akk_data?.pesel||'').slice(1).slice(4,6)+' '
                +wrap_str(offer_acc_data?.akk_data?.pesel||'').slice(1).slice(6, 10)+' '
                +wrap_str(offer_acc_data?.akk_data?.pesel||'').slice(1).slice(10),


            adress: {
                "coordinate": {
                    "lat": null,
                    "lon": null
                },
                "coordinate_arr": {
                    "country": "",
                    "country_code": "",
                    "region": "",
                    "state_district": "",
                    "city": "",
                    "town": "",
                    "village": "",
                    "house_number": "",
                    "house_name": ""
                },
                string_adr: offer_acc_data?.akk_data?.adr||' ',
            },
        },
        juristics: {
            link_qr: 'https://api.bregus.eu/ap43x6z6375gxud4/load/qr_partner?data=T12345678',
            errors: {
                nip: false,
                email: false,
                tel_1:  false,
                tel_2: false
            },
            nip: wrap_str(offer_acc_data?.akk_data?.nip||'').slice(1).slice(0,3)+'-'
                +wrap_str(offer_acc_data?.akk_data?.nip||'').slice(1).slice(3,5)+'-'
                +wrap_str(offer_acc_data?.akk_data?.nip||'').slice(1).slice(5, 7)+'-'
                +wrap_str(offer_acc_data?.akk_data?.nip||'').slice(1).slice(7, 11),
            reg: wrap_str(offer_acc_data?.akk_data?.reg||'').slice(1),
            name: '',
            name_: offer_acc_data?.akk_data?.name||'name',
            tel_: '+'+ trim_value(wrap_str(offer_acc_data?.akk_data?.tel?.operator_code||'55').slice(1))+' '+
                trim_value(
                    wrap_str(offer_acc_data?.akk_data?.tel?.tel||'55')
                    .slice(1)
                )
                    .match(new RegExp('.{1,3}', 'g')).join("-")|| '+48',
            adress: offer_acc_data?.akk_data?.adr||' ',
            email: offer_acc_data?.akk_data?.email||' ',
            tel_1: {
                "country_code": "pl",
                "operator_code": "48",
                "tel": ""
            },
            tel_2: {
                "country_code": "pl",
                "operator_code": "48",
                "tel": ""
            },

        },
    });

    const handle_manipulate_form =
        (modificator, path_, value) => {
            switch (modificator) {
                case 'get': {

                    let parsed_path = path_.split('.');
                    let path_value = path(parsed_path, {...form});
                    return path_value;
                }
                    break;
                case  'set': {
                    // console.log(path, value)
                    let parsed_path = path_.split('.');
                    let new_form = assocPath(parsed_path, value, {...form});
                    set_form({...new_form})
                }
                    break;
                case  'full_set': {
                    set_form({...value})
                }
                    break;
                default : {
                    return null
                }
            }
        }

    // const [path, setPath] = React.useState('./images/ru.png');

    // React.useEffect(()=>{
    //     let hash = window.location.hash.slice(1, window.location.hash.length);
    //     if (hash) {
    //         switch (hash) {
    //             case 'fiz': {
    //                 handle_manipulate_form('set', 'physics_juristics', 'f')
    //             }
    //             break;
    //             case 'jur': {
    //                 handle_manipulate_form('set', 'physics_juristics', 'j')
    //             }
    //             break;
    //             default : {
    //
    //             }
    //         }
    //     }
    // },[])
    return form.physics_juristics ?  (
        <div>
            <HeaderComponent
                changeLang ={(lan)=> {
                    // window.ln = lan;
                    set_language(lan)
                }}
                def_lang={language}
                op_res={()=> {
                    // set_open(true)
                }}
            />
            <UserPageComponent form_={form} set_form_={handle_manipulate_form}/>
            {/*{*/}
            {/*    open   ?*/}
            {/*        <ResultModal close={()=>set_open(false)}/> : null*/}
            {/*}*/}
        </div>
    ) :  <Backdrop
        open={true} style={{
        zIndex: 100000,
        color: "#fff",
    }}>
        <CustomImageLoader
            image={`${process.env.PUBLIC_URL}/images/icon.svg`}
            isLoaded={true}
            circle={false}
            speed={2}
            animationType={"pulse"}
        />
    </Backdrop>;

}

export default UserPage;