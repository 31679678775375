import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {_set_steps_, glv, localization_tree, set_field_in_global_form} from "./App";
import FirstAccStep from "./account_first_step";
import SecondAccStep from "./account_second_step";
import ThirdAccStep from "./account_third_step";
import {assocPath,  path} from "ramda";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));


export default function AccountStepper(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(1);

    const [steps_, set_steps_] = React.useState([
        {status: 0, name: 'first'},
        {status: 0, name: 'second'},
        {status: 0, name: 'third'},
    ]);

    const [form_reg, set_form_reg] = React.useState({
        physics_juristics: null,
        physics: {
            errors: {
                email: false,
                nal_num: false,
                phone_num: false,
                name: false,
                sname: false,

            },
            binded_bank: {
                bank_id: '',
                card_number: '',
                is_valid_card_num: false,
                logo_img: '',
                name: '',
                bank_code: '',
                confirmed: false
            },
            type_pay_nal: true,
            name: '',
            sname: '',
            tel: {
                "country_code": "pl",
                "operator_code": "48",
                "tel": ""
            },
            email: 'asddfs@ddd.com',
            nal_num: '',
            adress: '',
        },
        juristics: {
            errors: {
                nip: false,
                email: false,
                tel_1:  false,
                tel_2: false
            },
            nip: '',
            reg: '',
            name: '',
            adress: '',
            email: 'asddfs@ddd.com',
            tel_1: {
                "country_code": "pl",
                "operator_code": "48",
                "tel": ""
            },
            tel_2: {
                "country_code": "pl",
                "operator_code": "48",
                "tel": ""
            },

        },
    });

    const handle_manipulate_form =
        (modificator, path_, value) => {
            switch (modificator) {
                case 'get': {

                    let parsed_path = path_.split('.');
                    let path_value = path(parsed_path, {...form_reg});
                    return path_value;
                }
                    break;
                case  'set': {
                    // console.log(path, value)
                    let parsed_path = path_.split('.');
                    let new_form = assocPath(parsed_path, value, {...form_reg});
                    set_form_reg({...new_form})
                }
                    break;
                default : {
                    return null
                }
            }
        }


    React.useEffect(() => {
        _set_steps_(steps_)
    }, [steps_])

    React.useEffect(() => {
        // console.log(form_reg)
    }, [form_reg])


    React.useEffect(() => {
        // document.title = glv('document_titles.main_title') + ' | ' + glv('document_titles.step_titles.data.' + (activeStep - 1),);
    }, [activeStep])


    const nextStep = (step) => {
        let copy_steps_ = [...steps_];
        copy_steps_[activeStep - 1].status = 1;
        copy_steps_[activeStep].status = 1;
        set_steps_([...copy_steps_])
        setActiveStep(activeStep + 1)
    };

    const prevStep = (step) => {
        if (activeStep - 1 < 1) {
            return;
        }
        setActiveStep(activeStep - 1)
    };


    return (
        <div className={classes.root}>
            <div className="wrapper stepline-wr">
                <div className="container stepline ">
                    {
                        steps_.map((item, index) => {
                            // console.log(steps_)
                            let clas = 'stepline-item step' + (index + 1);
                            // clas+=' active'
                            let adding_ = ''
                            if (index === 0) {
                                if (item.status === 1 && activeStep !== 1) {
                                    adding_ = ' active'
                                }
                            } else {
                                if (item.status === 1) {
                                    adding_ = ' active'
                                }
                                if (index + 1 === activeStep && activeStep !== 1) {
                                    adding_ = ' curr'
                                }
                            }


                            clas += adding_
                            return (
                                <div className={clas} key={'_step_' + index + item.name}
                                     style={{flex: index === 0 ? 0 : 1}}>
                                    <div className="stepline-item-circle"
                                         onClick={item.status === 0 ? () => {
                                         } : () => setActiveStep(index + 1)}
                                         style={{cursor: item.status === 0 ? 'default' : 'pointer'}}>
                                        <div className="stepline-item-circle-dot"></div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>

            <div className="mobLineStatus">
                {
                    steps_.map((el, index) => {
                            return (
                                <div
                                    style={{flex: 1}}
                                    className={`mobLineStatus-item step${index + 1} ${el.status === 1 ? 'active' : ''} `}
                                    key={el.name + index + 'offer_step'}></div>
                            )
                        }
                    )
                }

            </div>
            <div style={{display: activeStep === 1 ? 'block' : 'none'}}>
                <FirstAccStep
                    nextStep={nextStep}
                    clas={' active'}
                    lang={props.language}
                    lang_name={props.lang_name}
                    form_={form_reg}
                    set_form_={handle_manipulate_form}
                />
            </div>
            <div style={{display: activeStep === 2 ? 'block' : 'none'}}>
                <SecondAccStep
                    nextStep={nextStep}
                    prevStep={prevStep}
                    clas={' active'}
                    lang={props.language}
                    form_={form_reg}
                    set_form_={handle_manipulate_form}
                />
            </div>
            <div style={{display: activeStep === 3 ? 'block' : 'none'}}>
                <ThirdAccStep
                    nextStep={nextStep}
                    prevStep={prevStep}
                    clas={' active'}
                    lang={props.language}
                    form_={form_reg}
                />
            </div>

        </div>
    );
}


