import React, { useEffect, useRef, useCallback } from "react";
import L from "leaflet";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./redux/actions/env.actions";
import {setlogos} from "./App";
import {create_dn} from "./helpers";
import {path, assocPath} from 'ramda';

const style = {
  width: "100%",
  height: "300px",
  position: 'relative',
    borderRadius: 4
//   marginLeft: 10
};

let ln = 'ru';

function get_point(point, ln) {

  return  fetch(`
        https://nominatim.openstreetmap.org/reverse?format=json&lat=${point.lat}&lon=${point.lon}&zoom=18&addressdetails=1&accept-language=${ln}`)
        .then((res)=>res.json()).catch((err)=> {
            setlogos(err, 'nominatim zapros na karte')
          console.log(err)
      })
}

function Map({ set_show_pub_loading, lang_name, form_, path_adr, set_form }) {
    const dispatch = useDispatch();
    const curr_address = useSelector((state) => state.envReducer.curr_address);

    const [snp, set_snp] = React.useState(false);
    const [popup_content, set_popup_content] = React.useState();


    let _adress_ = form_?.instalation_adr;

    // console.log(_adress_)
    const set_curr_address = useCallback((address) => dispatch(actions.set_address(address)), [
        dispatch,
    ]);

  // create map
  const mapRef = useRef(null);
  useEffect(() => {
      if (mapRef.current) {
          return
      }
    mapRef.current = L.map("map", {
      center: [_adress_.coordinate.lat, _adress_.coordinate.lon],
      zoom: 16,
      layers: [
        L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        })
      ],
      zoomControl: true,
      dragging: true,
      doubleClickZoom: false,
      scrollWheelZoom: true
    }).on('click', function(e){

        if (markerRef.current) {
            set_show_pub_loading(true)
            get_point( {lat: e.latlng.lat, lon: e.latlng.lng}, lang_name)
                .then((res)=>{

                    set_show_pub_loading(false)
                let element = create_dn(res)

                    let  new_form = assocPath(
                        ['instalation_adr'],
                        {
                            "coordinate":{
                                "lat": element.lat,
                                "lon": element.lon
                            },
                            "coordinate_arr": {
                                "country": element.address.country??" " ,
                                "country_code": element.address.country_code??" ",
                                "region": element.address.county??" ",
                                "state_district": element.address.state??" ",
                                "street": element.address.road??" ",
                                "city": element.address.city??" ",
                                "town": element.address.town??" ",
                                "village": element.address.village??" ",
                                "house_number": element.address.house_number??" ",
                                "house_name": element.address.house_number??" "
                            },
                            "string_adr": element.dn??" "
                        },
                        form_
                    )
                    set_form(new_form)
                markerRef.current._popup.setContent(element.dn)
            })
        }
    });
  }, [_adress_]);

  // add marker
  const markerRef = useRef(null);
  
  
  // useEffect(
  //   () => {
  //     // if (markerRef.current) {
  //     //   markerRef.current.setLatLng(mark_pos);
  //     // } else {
  //     //   markerRef.current = L.marker(mark_pos).addTo(mapRef.current).bindPopup(popup_content).openPopup()
  //     //   .on('click', function(e) {
  //     //     console.log(mark_pos);
  //     //     // window.open(`https://www.google.com/maps/search/?api=1&query=${mark_pos.lat},${mark_pos.lng}`);
  //     // });
  //
  //     // }
  //   },
  //   [mark_pos, popup_content]
  // );

  useEffect(
    () => {

      if (markerRef.current) {
          set_show_pub_loading(true)
          get_point(_adress_.coordinate).then((res)=>{
              set_show_pub_loading(false)
            markerRef.current._popup.setContent(create_dn(res).dn)
        })
              // console.log(markerRef.current._popup.getContent());
      }

    },
    [ _adress_]
  );

    useEffect(() => {
        ln = lang_name;
    }, [lang_name]);
  
  useEffect(
    () => {
      if (markerRef.current) {

          markerRef.current.setLatLng({lat: _adress_.coordinate.lat, lng: _adress_.coordinate.lon});
          mapRef.current.panTo({lat: _adress_.coordinate.lat, lng: _adress_.coordinate.lon});

      } else {
        if (_adress_) {

                  markerRef.current = L.marker({lat: _adress_.coordinate.lat, lng: _adress_.coordinate.lon}, {autoPan: true})
                      .addTo(mapRef.current).bindPopup(_adress_.string_adr).openPopup()
        .on('click', function(e) {

          // window.open(`https://www.google.com/maps/search/?api=1&query=${mark_pos.lat},${mark_pos.lng}`);
      });
        }

        
      }
    },
    [_adress_]
  );

  return <div id="map" style={style} />;
}

export default Map;