import {env_url, set_env_urls, setlogos, env_urls, release_build, debug_ta} from "../App";
import sha1 from "sha1";
// import {env_urls} from "../../../LoginBuild/src/App";
import Cookies from 'js-cookie';

let walid_apikey = false;
let counter_apikey = 0;
let form = false

function CheckWrap(

    url,
    body,
    type,
    point,
    returned_type,
    prevFunc,
    postFunc,
    form_or_no,
    sha1,
    moment,
    Cookies,
) {

    let newApiKey = url.apiKey;

    prevFunc ?  prevFunc() : console.log();

    let recursive_update = ()=> {
        if (!walid_apikey) {
            return CheckWrap(

                {...url,apiKey: newApiKey },
                body,
                type,
                point,
                returned_type,
                prevFunc,
                postFunc,
                form_or_no,
                sha1,
                moment,
                Cookies,
            )
        }
        return setTimeout(()=>recursive_update(), 4)
    }

    let update_confs =  () => {

        let curr_url = `${env_url}/json/conf.json`;
        fetch(curr_url)
            .then((data) => {
                return data.json();
            })
            .then((res) => {
                // обновляется ключ
                newApiKey = res.keys.apiKey;

                let new_urls = {
                    api_key: res.keys.apiKey,
                    api_url: res.url.api,
                    login: res.url.login,
                    api_home: res.url.home,
                    timepoints: res.timepoints,
                };
                set_env_urls({...new_urls})
                walid_apikey =  false;
                return true
            })
            .catch((error) => {

                setlogos(
                    error,
                    "error when get new api key",
                    sha1,
                    moment,
                    Cookies
                );
                walid_apikey =  false;
                return  false
            });

    }


    let push_req =  () => {


        return  fetch(
            `${url.api_url}/${newApiKey}${url.path}${url.params}`,
            {
                method: type,
                headers: form_or_no ? {} :{
                    ["Content-Type"]:  "application/json;charset=utf-8",
                },
                body: type === "GET" ? null : form_or_no ?body : JSON.stringify(body),
            }
        ).then( (res)=>{

            return res
        }).catch((error) => {

            setlogos(
                error,
                point,
                sha1,
                moment,
                Cookies
            );
            return {status: 'error'}
        });

    }


    // обертка трай для отлова ошибок
    let future_res =null;
    try {
        if (!walid_apikey) {
            return  push_req().then((res_req)=> {
                if (res_req.status === 200) {
                    postFunc ? postFunc() : console.log();
                    counter_apikey = 0;
                    future_res =  res_req.json();
                    if (returned_type==='blob'){
                        future_res =  res_req.blob();
                    }

                    return future_res;
                }

                if (res_req.status !== 200) {
                    walid_apikey = true;

                    if (counter_apikey + 1 > 10) {

                        // window.location.href = "https://i.bregus.eu/page_info#404";
                        return;
                    }
                    counter_apikey = counter_apikey + 1;
                    let  resres = update_confs();
                    return recursive_update();
                }

            });

        }
    } catch (error) {
        // вызов функции записи ошибок
        setlogos(
            error,
            point,
            sha1,
            moment,
            Cookies
        );
    }
}


export default class MainService {



    static logosErr = async(form) => {
        let params = ``;

        let req_url = {
            ...env_urls,
            url: env_urls.api_url,
            apiKey: '4c723g8yu5pg7g5s',
            path: '/set/front_catch_log',
            params: params
        };
        let req_body = form;
        let req_type = "POST";
        let req_point = " Page: Function send logos/ Type: request error/ URL: /set/front_catch_log function: logosErr";

        return await CheckWrap(
            req_url,
            req_body,
            req_type,
            req_point
        );
    };

    static get_watter_list_param = async() => {
        let params = `?new`;

        let req_url = {
            ...env_urls,
            url: env_urls.api_url,
            apiKey: env_urls.api_key,
            path: '/load/watter_list_param',
            params: params
        };
        let req_body = form;
        let req_type = "GET";
        let req_point = " Page: step two Function get_watter_list_param / Type: request error/ URL: /load/watter_list_param function: get_watter_list_param";

        return await CheckWrap(
            req_url,
            req_body,
            req_type,
            req_point
        );
    };

    static get_firm_for_nip = async(nip) => {
        let login = Cookies.get("hash_key", { domain: ".bregus.eu" });
        let params = `?nip=${'N'+nip}&hash_key=${release_build ? login :'b07dde55010ac7170699c170269171d9edfb27bc'}`;

        let req_url = {
            ...env_urls,
            url: env_urls.api_url,
            apiKey: env_urls.api_key,
            path: '/load/load_as_nip',
            params: params
        };
        let req_body = form;
        let req_type = "GET";
        let req_point = " Page: step two Function get_watter_list_param / Type: request error/ URL: /load/watter_list_param function: get_watter_list_param";

        return await CheckWrap(
            req_url,
            req_body,
            req_type,
            req_point
        );
    };

    static get_first_load = async() => {
        let login = Cookies.get("hash_key", { domain: ".bregus.eu" });
        let params = `?hash_key=${release_build ? login :'b07dde55010ac7170699c170269171d9edfb27bc'}${release_build?debug_ta?'&type='+debug_ta : '':'&type=individual'}`;

        let req_url = {
            ...env_urls,
            url: env_urls.api_url,
            apiKey: env_urls.api_key,
            path: '/user/offer',
            params: params
        };
        let req_body = form;
        let req_type = "GET";
        let req_point = " Page: step two Function get_first_load / Type: request error/ URL: /load/watter_list_param function: get_first_load";

        return await CheckWrap(
            req_url,
            req_body,
            req_type,
            req_point
        );
    };

    static getAppRequest = async (
        url
    ) => {

        return await   fetch(url)
            .then((data) => data.json())
    };


}
