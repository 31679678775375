import React, { useEffect, useCallback, useState } from "react";

import { withStyles, makeStyles } from '@material-ui/core/styles';
// import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import imgs from './jsonImgs';
import {format} from 'mathjs'
import Slider from "@mui/material/Slider";



let full_w  = false;

let color_over_rep = '#e00052';
let out_lims_color = '#e5c83d'

let ph_colors = [
    '#fa2945',
    '#e86f3b',
    '#ef9e34',
    '#f6c42c',
    '#fbff24',
    '#d1ec36',
    '#8fd64c',
    '#4cd66a',
    '#7b77c5',
    '#504baf',
    '#403c8b',
    '#363276'
]

let ThumbComponent = function ThumbComponent(props) {
  // {...props}
  //   console.log(props.rp)
    let color_ph = '';
    if (props.is_ph) {
        if (props.ownerState.value<=8.19&&props.ownerState.value>=7.2) {
            color_ph='#2a98f8';
        } else {
            ph_colors.forEach((color_, index)=> {

                if (props.ownerState.value<=index+1&&props.ownerState.value>=index) {
                    color_ph=color_
                }
            })
        }

    }
    let bg_color =
        props.is_ph ? color_ph :
        props.ownerState.value > props.rp ? color_over_rep :
            // (props.ownerState.value<props.limits.min||props.ownerState.value>props.limits.max) ?out_lims_color  :
        '';
  return (
      <span {...props}  className={'range_thumb'} style={{...props.style,
          background: bg_color,
          color: props.is_ph&&props.ownerState.value <6 ? '#000000' : '',
          transition: 'color 0.4s,  background 0.4s',

      }}>
        {window.number_to_beauty_string(props.ownerState.value)}
        {props.children}
    </span>
  );
}

function r_s_val(val, matches) {

   let new_val = window.number_to_beauty_string(val)
  if (new_val.length   < 2) {
  return  matches? -55 : -45
  }

  if (new_val.length  < 3) {
    return matches? -55 : -50
  }
  if (new_val.length  < 4) {
    return  matches? -60 :-55
  }
  if (new_val.length < 5) {
    return matches? -65 : -60
  }

  return 10
}

let color_ph = 'linear-gradient(90deg, rgba(153,225,251,1) 0%, rgba(61,185,229,1) 100%) !important';

const StyledRangeSlider =(full_w, matches_1010, isPH)=> {


  return withStyles({
    root: {
      color: '#3db9e5',
      height: 5,
      maxWidth: full_w||matches_1010 ? '100%'  : 316,
      width: '100%',

    },
    thumb: {
      // height: 24,
      // // width: 50,
      // backgroundColor: '#3db9e5',
      // borderRadius: '12px',
      // border: '2px solid currentColor',
      // marginTop: -10,
      // marginLeft: -30,
      // '&:focus, &:hover, &$active': {
      //   boxShadow: 'inherit',
      // },
    },
    active: {},
    valueLabel: {
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%) !important',
      // left: 'calc(-50% + 4px)',
      // top: 50,
      '& *': {
        background: 'transparent',
        color: '#ffffff',
      },
    },
    track: {
        display: isPH? 'none !important' : '',
      height: 5,
        background: isPH ? color_ph : '',
      borderRadius: 4,
    },
    rail: {
      height: 5,
        background: isPH ? color_ph : '',
      // width: 316,
      borderRadius: 4,
    },
  })(Slider)
};


  const RangeSliderComponent = (props) => {
     //  let maxVal = 10;
     //  let minVal = 0;
     // let stepVal = 2.5;
       const {maxVal, minVal, stepVal, _scale_, value_, isPH} = props;

       let scale_ = _scale_;
       if (!scale_) {
           scale_= {rep_point: maxVal, limits: {
               min: minVal, max: maxVal-stepVal
               }}
       }



    const matches_1010 = useMediaQuery('(max-width:1025px)');
    let StyledRange =React.useCallback (StyledRangeSlider(props.full_w,  matches_1010, isPH), [props.full_w, matches_1010, isPH]);

    const [fw, sfw] = React.useState(false);

    const [input_, set_input_] = React.useState(false);
    const [input_hover, set_input_hover] = React.useState(false);

    const [value, setValue] = React.useState(scale_.size.min);






    // let Thumb = React.useCallback((props)=> {
    //     return <ThumbComponent {...props} rp={scale_.rep_point}/>
    // })

      let Thumb = React.useCallback ((props) => {

          return   <ThumbComponent {...props} rp = {scale_.rep_point} limits={scale_.limits} is_ph={isPH} />

      }, [scale_.rep_point, scale_.limits]);


    useEffect(()=>{
      if (props.full_w) {
        sfw(true)
        return
      }
      if (matches_1010) {
        sfw(true)
        return
      }
      if(!matches_1010) {
        sfw(false)
        return
      }
      // console.log(matches_1010)
    }, [matches_1010, props.full_w])

      useEffect(()=>{
          if (Number(props.value_)> maxVal) {
              props.setNewRangeValue(maxVal);
              return
          }
          if (Number(props.value_)< minVal) {
              props.setNewRangeValue(minVal);
              return
          }

          if(!Number.isInteger(Number(format(Number(props.value_)/stepVal, { precision: 14 })))) {
              let new_val = format(
                  Math.trunc(
                      Number(format(Number(props.value_)/stepVal, { precision: 14 }))
                  )*Number(stepVal)+Number(stepVal),
                  { precision: 14 })

              props.setNewRangeValue(
                  new_val
              )
              return
          }
          // console.log(matches_1010)
      }, [props.value_,maxVal, minVal ])

      useEffect(()=>{
          if (Number(value)> maxVal) {
              setValue(maxVal);
              return
          }
          if (Number(value)< minVal) {
              setValue(minVal);
              return
          }

          if(!Number.isInteger(Number(format(Number(value)/stepVal, { precision: 14 })))) {
              let new_val = format(
                  Math.trunc(
                      Number(format(Number(value)/stepVal, { precision: 14 }))
                  )*Number(stepVal)+Number(stepVal),
                  { precision: 14 })

              setValue(
                  new_val
              )
              return
          }
          // console.log(matches_1010)
      }, [value,maxVal, minVal ])


    return  (
        <div style={{display: 'flex', width: '100%'}}>
            {
                input_ ?
                    <input
                        type={'number'}
                        value={props.value_}
                        onChange={(event) => {
                            if(isNaN(event.target.value)) {
                                return
                            }
                            if (Number(event.target.value)> maxVal) {
                                props.setNewRangeValue(maxVal);
                                return
                            }
                            if (Number(event.target.value)< minVal) {
                                props.setNewRangeValue(minVal);
                                return
                            }


                                if(!Number.isInteger(Number(format(Number(event.target.value)/stepVal, { precision: 14 })))) {
                                    let new_val = format(
                                            Math.trunc(
                                                Number(format(Number(event.target.value)/stepVal, { precision: 14 }))
                                            )*Number(stepVal)+Number(stepVal),
                                            { precision: 14 })

                                    props.setNewRangeValue(
                                        new_val
                                        )
                                    return
                                }
                            props.setNewRangeValue(event.target.value);
                        }}
                        className={'number_range_input'}
                        min = {minVal}
                        max={maxVal}
                        step={
                           stepVal
                        }
                        style={{
                            borderRadius: '5px 0px 0px 5px',
                            borderColor:
                                isPH? color_ph :
                                props.value_>scale_.rep_point ? color_over_rep :''
                        }}
                    /> :
                    <div className="rangeContainer" style={
                        {margin: fw ? '0px 70px 0 50px' : '0px 65px ', position: 'relative',
                            borderRadius:  '5px 0px 0px 5px'
                        }

                    }>
    <span className='l_min_range' style={{
        fontSize: 14, fontWeight: 'normal', color: '#6D6E71', position: 'absolute',

        left:  fw ?-38 : -38}}>{window.number_to_beauty_string(minVal)}</span>
                        <StyledRange
                            components={{ Thumb: Thumb }}
                            aria-label="ios slider"
                            min = {minVal}
                            max={maxVal}
                            step={
                                props.stepVal
                            }
                            style={{
                                // background: isPH?color_ph :'',
                                color:

                                    value>scale_.rep_point&&!isPH ? color_over_rep :
                                        // (value<scale_.limits.min||value>scale_.limits.max) ? out_lims_color :
                                        '#3DB9E5',


                            transition: 'color 0.4s'}}
                            // defaultValue={props.defValue}
                            value={ value}
                            onChange={(event, newValue) => {setValue(newValue); props.setNewRangeValue(newValue);}}
                            valueLabelDisplay="off"
                        />
                        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', position: 'absolute',
                            right :r_s_val(props.maxVal, matches_1010)
                            // fw ?-45 : -35
                            // r_s_val(props.maxVal)

                        }}>
                            <span className='r_max_range' style={{fontSize: 14, fontWeight: 'normal', color: '#6D6E71'}}>{window.number_to_beauty_string(props.maxVal)}</span>
                            {/*<span className='r_max_range' style={{fontSize: 14, fontWeight: 'normal', color: '#6D6E71'}}>{props.num_val?props.num_val: '' }</span>*/}
                        </div>

                    </div>
            }




          <div
              style={{
                opacity: 1,
                // color: checked ? '#fff' : btns_style?.color ? btns_style.color : '#000',
                height: '100%',
                border: '1px solid #EEEEEE',

                padding: '15px 8px',
                display: 'flex',
                justifyContent: 'center',
                cursor:'pointer',
                alignItems: 'center',
                // width: index===0&&card_width===0 ?  'max-content': card_width,
                background: input_hover ? '#3db9e5' : '#fff',
                // transform: value===index  ? 'rotate(45deg)' : 'rotate(0deg)',
                transition: 'color, background 0.2s',
                flex:1,
                borderRadius:  '0px 5px 5px 0px'
              }}
              onMouseOver={()=>set_input_hover(true)}
              onMouseLeave={()=>set_input_hover(false)}
              onClick={()=>set_input_(!input_)}
          >
            <img
                src={

                    input_? input_hover?imgs.slider_white: imgs.slider_gray : input_hover ?  imgs.input_white : imgs.input_gray
                }
                style={{
                    width: 30,
                    height:input_&& input_hover ? 15 : 30,
                    transition: 'display, opacity 0.2s',

                }}
                // width={40}
                // height={40}
            />
          </div>
        </div>
    );
  };

  export default React.memo(RangeSliderComponent) ;