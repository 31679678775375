import * as actionTypes from "../types/env.types";

import Cookies from "js-cookie";

const initialState = {
  urls: {
    api_key: "",
    api_url: "",
  },
  language: null,
  lang_values: null,
  lang_conf: null,
};

export default function devicesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_URLS:
      return {
        ...state,
        urls: action.urls,
      };

    case actionTypes.LANG_CONF:
      return {
        ...state,
        lang_conf: action.lang_conf,
      };

    case actionTypes.LANG_VALUES:
      return {
        ...state,
        lang_values: action.lang_values,
      };
    case actionTypes.SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };

    default:
      return state;
  }
}
