import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {glv} from "./App";
import {path, assocPath} from 'ramda'
import imgs from "./jsonImgs";
const vals={
    0: 'osob',
    1: 'firm'
}

function Btn (props){
    const [value, setValue] = React.useState(props.defVal);
    return (
        <div
            key={
                props.key
            }
            onClick={()=>{
                props.click_()

            }}
            style={
                {
                    opacity: 1,
                    color:  props.isChecked ? '#fff' : props.btns_style_color,

                    border:'1px solid #EEEEEE',

                    padding:'15px 26px',
                    display: 'flex',
                    justifyContent:'center',
                    cursor: 'pointer',
                    alignItems: 'center',
                    width: props.matches_1010 ? '50%' : props.btn_w,
                    background: props.isChecked ? '#3db9e5' : '#fff',
                    transform: props.isChecked  ? 'rotate(45deg)' : 'rotate(0deg)',
                    transition: 'all  9s',
                    borderRadius: props.bord_rad

                }
            }
        >
            {props.isChecked ? <CheckIcon style={{width:19, height: 16}}/>: null}
            <span style={

                props.val_style

            }>{props.el}</span>
        </div>
    )
}


export default React.memo(
    function RadioParaComponent(props) {

        const [value, setValue] = React.useState(props.defVal);
        const matches_1010 = useMediaQuery('(max-width:1025px)');

         let value_  = props.condition(props.form_)

        // let form_val =props.path_f ? path(props.path_f.split('.'),props.form_) : 'not val_form';

        // const change_form_val= (value)=>{
        //     let  new_form = assocPath(
        //         [...props.path.split('.')],
        //         value,
        //         props.form_
        //     )
        //     props.set_form(new_form)
        // }
        //
        const change_form_val= (value)=>{
            let  new_form = assocPath(
                [...props.path.split('.')],
                value,
                props.form_
            )
            props.set_form(new_form)
        }
        React.useEffect(()=> {

        }, [value_])


        return      (
            <div className="step-row radios" style={{...props.wr_style, marginTop: matches_1010&&props.rad_label ? 24 : 0}}>
                {
                    props.rad_label ?
                        <div className="step-row-label" style={{fontWeight: 500}}>{props.rad_label}{props.sub_lab?<sub>{props.sub_lab}</sub>:null}</div>
                        : null
                }

                <div className="step-row-checks" style={{...props.btns_style, width: '100%', marginTop: matches_1010&&props.rad_label ? 0 : 0}}>

                                <div

                                    onClick={()=>{
                                        setValue(true)
                                        props.select_(true)
                                        // setValue(index);
                                        // let vals ={
                                        //     0: true,
                                        //     1: false
                                        // }
                                        // change_form_val(vals[index])
                                        // props.selectVariant(index);

                                    }}
                                    style={{
                                        opacity: 1,
                                        color:  value_ ? '#fff' : props.btns_style?.color ?props.btns_style.color :'#000',

                                        border:'1px solid #EEEEEE',

                                        padding:'15px 26px',
                                        display: 'flex',
                                        justifyContent:'center',
                                        cursor: 'pointer',
                                        alignItems: 'center',
                                        width: matches_1010 ? '50%' : props.btn_w,
                                        background: value_ ? '#3db9e5' : '#fff',
                                        // transform: value===index  ? 'rotate(45deg)' : 'rotate(0deg)',
                                        transition: 'color, background 0.2s',

                                        borderRadius:  '5px 0px 0px 5px'
                                    }}
                                >
                                    {value_ ?
                                        <img src={imgs.check_img} style={{width: 12, height: 12}}/>
                                        // <CheckIcon style={{width:19, height: 16}}/>

                                        : null}
                                    <span style={
                                        {
                                            // opacity: value===index? 0.5 : 1,
                                            marginLeft: value_? 10 : 0,
                                            fontSize: matches_1010? '12px' : '16px',
                                            transition: value_ ? 'margin-left 0.2s' : 'none',
                                        }
                                    }>{glv(props.path_+0 )}</span>
                                </div>

                    <div

                        onClick={()=>{
                            setValue(false)
                            props.select_(false)
                            // setValue(index);
                            // let vals ={
                            //     0: true,
                            //     1: false
                            // }
                            // change_form_val(vals[index])
                            // props.selectVariant(index);

                        }}
                        style={{
                            opacity: 1,
                            color:  !value_ ? '#fff' : props.btns_style?.color ?props.btns_style.color :'#000',

                            border:'1px solid #EEEEEE',

                            padding:'15px 26px',
                            display: 'flex',
                            justifyContent:'center',
                            cursor: 'pointer',
                            alignItems: 'center',
                            width: matches_1010 ? '50%' : props.btn_w,
                            background: !value_ ? '#3db9e5' : '#fff',
                            // transform: value===index  ? 'rotate(45deg)' : 'rotate(0deg)',
                            transition: 'color, background 0.2s',

                            borderRadius:   '0px 5px 5px 0px'
                        }}
                    >
                        {!value_ ? <CheckIcon style={{width:19, height: 16}}/>: null}
                        <span style={
                            {
                                // opacity: value===index? 0.5 : 1,
                                marginLeft: !value_? 10 : 0,
                                fontSize: matches_1010? '12px' : '16px',
                                transition: !value_ ? 'margin-left 0.2s' : 'none',
                            }
                        }>{glv(props.path_+1 )}</span>
                    </div>

                </div>
            </div>

        )

    }
)