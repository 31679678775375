import React, {useEffect} from 'react';
import {Create_Offer_Context, glv} from "./App";
import imgs from "./jsonImgs";
import QueModal from "./que_modal";
import RadioNumList from "./RadioNumList";
import RangeSliderComponent from "./range_slider";
import RangeDoubleSliderComponent from "./range_slider_x2";
import RadioTabComponent from "./RadioParaTabComponent";
import CustomSelect from "./cust_sel_manipul";
import {form_num_list, format_items_to_global_form} from "./helpers";
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import {tree_} from "./temp_tree";

export function RowScales({data_scales_array, key_, type_page, has_bottom_line}) {
    const [create_offer_form, change_form_state] = React.useContext(Create_Offer_Context);
    const [que_mod, set_que_mod] = React.useState({
        0: false,
        1: false
    });

    const listener_0 = React.useCallback(()=> {
        set_que_mod({...que_mod, 0: false});
    }, [])
    const listener_1 = React.useCallback(()=> {
        set_que_mod({...que_mod, 1: false});
    }, [])
    React.useEffect(()=> {

        if (que_mod[0]) {
            document.addEventListener('click', listener_0)
        }
        if (que_mod[1]) {
            document.addEventListener('click', listener_1)
        }
        if (!que_mod[0]) {
            document.removeEventListener('click', listener_0)
        }
        if (!que_mod[1]) {
            document.removeEventListener('click', listener_1)
        }


    }, [que_mod])


    // console.log(data_scales_array, type_page)
    return <>
    <div className="step-row w50"

                 key={key_}
    >
        {
            data_scales_array.map((item, index)=> {
                // console.log(item, create_offer_form)
                // if (item.name==='h_wall') {
                //     return <div></div>
                // }
                // console.log(item.only_, 'only')
                if (item.only_) {
                    // console.log(item.only_, 'only')
                    // console.log(item,data_scales_array, create_offer_form[type_page].hasOwnProperty(item?.name))
                }
                let wfw = create_offer_form?.anal?.where_from_water;
                let tc = create_offer_form?.anal?.type_consumer;
                if (wfw&&tc) {
                    let cond_branch_type =                     (
                        tree_[wfw][tc]?.type_==='plumb_human'
                        || tree_[wfw][tc]?.type_==='plumb_tech'
                        || tree_[wfw][tc]?.type_==='plumb_mix'
                    )
                    let is_iron = item.name === 'iron';
                    let has_ci = create_offer_form?.anal?.hasOwnProperty('color_iron');
                    let has_ir = create_offer_form?.anal?.hasOwnProperty('iron');
                    let sw_ci_val = create_offer_form?.anal?.color_iron;
                    if (is_iron&&
                        has_ir&&
                        has_ci&&
                        !sw_ci_val&&
                        cond_branch_type
                    ) {
                        let new_form = {...create_offer_form }
                        delete new_form.anal.iron
                        change_form_state('g', '', {...new_form});
                        return null
                    }else if(is_iron&&
                        !has_ir&&
                        has_ci&&
                        sw_ci_val&&
                        cond_branch_type
                    ) {
                        let new_form = {...create_offer_form }
                        new_form.anal.iron = format_items_to_global_form(item)
                        change_form_state('g', '', {...new_form});
                        return null
                    }
                }





                return item&&create_offer_form[type_page].hasOwnProperty(item?.name) ?  (
                    <Grow in={true} direction={'up'} timeout={800}>
                    <div className="step-row-field" key={index+'mini_blochok'+key_} >

                        <label style={{ display: 'flex'}}>
                     <span> {
                         glv('mainStepper.second_step.legends.'+ item?.name+'.title', 'str', true)
                     }</span>
                            <div
                                style={{
                                    position:'relative',
                                    display:
                                        glv('mainStepper.second_step.legends.'+ item?.name+'.description', 'str', true)
                                        ==='' ? 'none' : 'initial'
                                }}

                            >
                                <div
                                    style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%',
                                        zIndex: 568768464, cursor: 'pointer' }}
                                    onClick={()=>set_que_mod({...que_mod, [index]: true})}
                                ></div>
                                <img
                                    src={imgs.que} className="question "
                                    alt="question "
                                    style={{
                                        cursor: 'pointer',
                                        width: '16px',
                                        height: '16px',

                                    }}

                                />
                                {
                                    que_mod[index]?
                                        <QueModal
                                            coord={{y: 0, x: 10}}
                                            content={glv('mainStepper.second_step.legends.'+ item?.name+'.description', 'str', true)}
                                        />
                                        : null
                                }

                            </div>
                        </label>

                        {
                            // (item?.type_==='muisliderX1')&&
                        }
                        {(item.type==='muisliderX1'&&item.scale)&&
                        <div>

                            <RadioNumList
                                value_={create_offer_form[type_page][item.name].type +'.'+item.name}
                                list={
                                    form_num_list(item.scale,item)
                                }
                                item_={item}
                                wr_style={{marginBottom: 15, marginTop: 0}}
                                path={`mainStepper.second_step.second_tab.radio_btns_blocks.iron_num_block.text_values.data.`}
                                btns_style={{}}
                                selectVariant={(n) => {
                                    change_form_state(type_page, item.name+'.type', n.split('.')[0])
                                }
                                }
                            />

                            <div className="rangeWr">
                                <RangeSliderComponent
                                    isPH ={item.name==='ph'}
                                    minVal={data_scales_array[index].scale.find((scale_, i)=> {

                                        return scale_.name+'.'+ item.name ===create_offer_form[type_page][item.name].type +'.'+item.name
                                    })?.size.min}

                                    _scale_={data_scales_array[index]
                                        .scale.find((scale_, i)=>scale_.name+'.'+ item.name ===create_offer_form[type_page][item.name].type +'.'+item.name)}
                                    maxVal={data_scales_array[index]
                                        .scale.find((scale_, i)=>scale_.name+'.'+ item.name ===create_offer_form[type_page][item.name].type +'.'+item.name)?.size.max}
                                    stepVal={data_scales_array[index]
                                        .scale.find((scale_, i)=>scale_.name+'.'+ item.name ===create_offer_form[type_page][item.name].type +'.'+item.name)?.step}
                                    value_={create_offer_form[type_page][item.name].value}
                                    setNewRangeValue={(newVal) => {
                                        change_form_state(type_page, item.name+'.value', newVal)
                                    }}
                                />
                            </div>
                        </div>
                        }
                        {(item.type==='muisliderX2'&&item.scale)&&
                        <div>
                            <RadioNumList
                                value_={create_offer_form[type_page][item.name].type +'.'+item.name}
                                list={
                                    form_num_list(item.scale,item)
                                }
                                item_={item}
                                wr_style={{marginBottom: 15, marginTop: 0}}
                                path={`mainStepper.second_step.second_tab.radio_btns_blocks.iron_num_block.text_values.data.`}
                                btns_style={{}}
                                selectVariant={(n) => {
                                    change_form_state(type_page, item.name+'.type', n.split('.')[0])
                                }
                                }
                            />

                            <div className="rangeWr">

                                <RangeDoubleSliderComponent

                                    minVal={data_scales_array[index].scale.find((scale_, i)=> {

                                        return scale_.name+'.'+ item.name ===create_offer_form[type_page][item.name].type +'.'+item.name
                                    })?.size.min}

                                    scale_={data_scales_array[index]
                                        .scale.find((scale_, i)=>scale_.name+'.'+ item.name ===create_offer_form[type_page][item.name].type +'.'+item.name)}
                                    maxVal={data_scales_array[index]
                                        .scale.find((scale_, i)=>scale_.name+'.'+ item.name ===create_offer_form[type_page][item.name].type +'.'+item.name)?.size.max}
                                    stepVal={data_scales_array[index]
                                        .scale.find((scale_, i)=>scale_.name+'.'+ item.name ===create_offer_form[type_page][item.name].type +'.'+item.name)?.step}
                                    value_={create_offer_form[type_page][item.name].value}

                                    setNewRangeValue={(newVal) => {
                                        change_form_state(type_page, item.name+'.value', newVal)
                                    }}
                                />
                            </div>
                        </div>
                        }
                        {

                            item.type==='switch'&&
                            <RadioTabComponent
                                // in_state={true}
                                tab_value={create_offer_form[type_page][item.name]}
                                set_tab_value={(tab_) => {
                                    change_form_state(type_page, item.name, tab_)
                                }}
                                btn_w={'50%'}
                                wr_style={{marginBottom: 0}}
                                sub_lab={"*"}
                                path_={
                                        `mainStepper.third_step.switches.${item.name}.data.`
                                }
                            />

                        }

                        {

                            item.type==='select'&&
                            <CustomSelect
                                options={item.options}
                                def_value={create_offer_form[type_page][item.name]}
                                change_val={(val)=>{
                                    change_form_state(type_page, item.name, val)
                                }}
                                only_={item.only_}
                                id_sel={item.name}
                                // tooltips={data_types.purpose.text_arr}
                            />
                        }

                    </div>
                    </Grow>
                    ) :null



            } )
        }

    </div>
        {
            has_bottom_line ?
                <div className="step-row text">
                    <div className="textR_line">
                        <span></span>
                    </div>
                    {/*<div className="text_under_textR_line">*/}
                    {/*    <span>*</span> {glv(`mainStepper.second_step.second_tab.ps_title`)}*/}
                    {/*</div>*/}
                </div> : null

        }

    </>
}


// const [select_value, set_select_value] = React.useState(null);
// let d1_scale = data_scales_array[0]?.scale;
// let d2_scale = data_scales_array[1]?.scale;
// let d1_ch_t = data_scales_array[0]?.scale?.constructor===Array;
// let d2_ch_t = data_scales_array[1]?.scale?.constructor===Array;
// // console.log( d1_ch_t, d2_ch_t, d1_ch_t ? d1_scale[0].name+ d1_scale[0].text_name+0:'')
// const [range_values, set_range_values] = React.useState({
//     'range_0': {
//         num: d1_ch_t ? d1_scale[0].name+ d1_scale[0].text_name+0:'',
//         value: d1_ch_t ? d1_scale[0].limits.min : 0
//     },
//     'range_1':  {
//         num: d2_ch_t ? d2_scale[0].name+ d2_scale[0].text_name+0: '',
//         value: d2_ch_t ? d2_scale[0].limits.min : 0
//     }
// })
//
// useEffect(()=>{
//     set_range_values({
//         'range_0': {
//             num: d1_ch_t ? d1_scale[0].name+ d1_scale[0].text_name+0:'',
//             value: 0
//         },
//         'range_1':  {
//             num: d2_ch_t ? d2_scale[0].name+ d2_scale[0].text_name+0: '',
//             value: 0
//         }
//     })
//     // console.log(range_values)
// }, [d1_ch_t, d2_ch_t,d1_scale, d2_scale])
//
// const [boolean_values, set_boolean_values] = React.useState({
//     'bool_0':   d1_scale ,
//     'bool_1':   d2_scale
// })