import React from 'react';

import SelectComponent from './custom_select';
import imgs from './jsonImgs';
import {glv} from "./App";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {lang_arr} from './App'
let path = './images/'

function HeaderComponent(props) {
    const matches = useMediaQuery('(max-width:1050px)');
    const [currLang, setCurrLang] = React.useState(props.def_lang);
    const [path, setPath] = React.useState('./images/ru.png');

    React.useEffect(()=>{
        setPath(`./images/${currLang}.png`);
    },[currLang])
    return (
        <header className="header">
      <div className="header-wr">
          <div className="header-wr-left">
              <div className="header-wr-left-img" onClick={()=>props.op_res()} style={{cursor: 'pointer'}}>
                  <img src={imgs.logo} alt="Logo" style={{width: 50, height: 50}}></img>
              </div>
              <div className="header-wr-left-text">

                  {
                      glv(`header.logoTitle`)
                  }®
                  
        
              </div>
          </div>
          <div className="header-wr-right">

              <div className="header-wr-right-num" style={{marginRight: 25}}>
              {glv(`header.phoneTooltip`)} <a href={`tel:${glv(`header.phone_number`)}`}> {glv(`header.phone_number`)} </a>
              </div>
              <a href={`tel:${glv(`header.phone_number`)}`} className="mobilePhone"></a>
              {/*<div classNameName="header-wr-right-flag" style={{marginLeft: 25}}>*/}
              {/*    <img src={imgs[currLang]} alt="flag" width={18} height={18}></img>*/}
              {/*</div>*/}
              <SelectComponent
                options={lang_arr}
                def_value={lang_arr.findIndex((item)=>props.def_lang===item)}
                style_wsb={{width:'80px', marginLeft:matches? '25px':'0px', marginBottom:'5px'}}
                style_ws={{paddingRight:'0px', paddingTop:0, paddingBottom:5, paddingLeft:10}}
                style_svg={{width:'11px', height: '11px', top:'8px', right:10}}
                changeVal={(val)=>{
                    setCurrLang(lang_arr[val])
                    props.changeLang(lang_arr[val])}}
                />


          </div>
      </div>
  </header>
    )
}

export default HeaderComponent;