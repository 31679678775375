import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {glv} from "./App";
import {path, assocPath} from 'ramda';
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./arrows";
import imgs from "./jsonImgs";
const vals={
    0: 'osob',
    1: 'firm'
}

function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}

export default React.memo(
    function RadioBtnsComponent(props) {

        const [value, setValue] = React.useState(0);
        const matches_1010 = useMediaQuery('(max-width:1025px)');

        let form_val =props.path_f ? path(props.path_f.split('.'),props.form_) : 'not val_form';

        const change_form_val= (value)=>{
            let  new_form = assocPath(
                [...props.path.split('.')],
                value,
                props.form_
            )
            // props.set_form(new_form)
        }

        React.useEffect(()=> {
            if (form_val==='not val_form') {
                return
            }
            setValue(form_val? 1 : 0)
        }, [form_val])

        return      (
            <div className="step-row radios" style={{...props.wr_style, marginTop: matches_1010&&props.rad_label ? 24 : 0}}>
                {
                    props.rad_label ?
                        <div className="step-row-label" style={{fontWeight: 500}}>{props.rad_label}{props.sub_lab?<sub>{props.sub_lab}</sub>:null}</div>
                        : null
                }

                <div className="step-row-checks" style={{...props.btns_style, width: '100%', marginTop: matches_1010&&props.rad_label ? 0 : 0}}>
                    <ScrollMenu
                        LeftArrow={LeftArrow}
                        RightArrow={RightArrow}
                        onWheel={onWheel}
                    >
                    {
                        props.arrVals.map((el, index)=>{
                            // console.log('e')
                            let idd= "sfggoyhy"+index+el+Math.random();
                            return    (

                                <div
                                    role="button"
                                    tabIndex={0}
                                    key={"sfggoyhy"+index+el}
                                    onClick={()=>{
                                        setValue(index);
                                        let vals ={
                                            0: true,
                                            1: false
                                        }
                                        // change_form_val(vals[index])
                                        props.selectVariant(index);

                                    }}
                                    style={{
                                        opacity: 1,
                                        color:  value===index ? '#fff' : props.btns_style?.color ?props.btns_style.color :'#000',

                                        border:'1px solid #EEEEEE',

                                        padding:'15px 26px',
                                        display: 'flex',
                                        justifyContent:'center',
                                        cursor: 'pointer',
                                        alignItems: 'center',
                                        width: 'max-content',
                                        background: value===index ? '#3db9e5' : '#fff',
                                        // transform: value===index  ? 'rotate(45deg)' : 'rotate(0deg)',
                                        transition: 'color, background 0.2s',

                                        borderRadius: index===0 ? '5px 0px 0px 5px' : index === props.arrVals.length-1 ? '0px 5px 5px 0px' : '0px'
                                    }}
                                >
                                    {value===index ?
                                        <img src={imgs.check_img} style={{width: 12, height: 12}}/>
                                        // <CheckIcon style={{width:19, height: 16}}/>
                                        : null}
                                    <span style={
                                        {
                                            // opacity: value===index? 0.5 : 1,
                                            marginLeft: value===index? 10 : 0,
                                            fontSize: matches_1010? '12px' : '16px',
                                            transition: value===index? 'margin-left 0.2s' : 'none',
                                        }
                                    }>{glv(props.path+index, )}</span>
                                </div>
                            )
                        })
                    }
                    </ScrollMenu>
                </div>
            </div>

        )

    }
)