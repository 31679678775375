import * as actionTypes from '../types/env.types';
import Cookies from 'js-cookie';

export const setUrls = (urls) => {
    return {
      type: actionTypes.SET_URLS,
      urls,
    };
  };

  export const setHash = (hash) => {
    return {
      type: actionTypes.HASH,
      hash,
    };
  };

  export const setTreeValid = (treeVal) => {
    return {
      type: actionTypes.TREE_VALID,
      treeVal,
    };
  };

export const set_address = (curr_address) => {
  return {
    type: actionTypes.SET_ADDRESS,
    curr_address,
  };
};


  


export const setLangValues = (lang_values) => {
    return {
      type: actionTypes.LANG_VALUES,
      lang_values,
    };
  };
  export const setCurrentPages = (currentPage) => {
    
    return {
      type: actionTypes.CURRENT_PAGE,
      currentPage,
    };
  };
  

  export const setLanguageConfig = (lang_conf) => {
    return {
      type: actionTypes.LANG_CONF,
      lang_conf,
    };
  };
  
  export const setLanguage = (language) => {
  
    Cookies.set('language', language, { domain: '.bregus.eu', sameSite: 'Strict'});
 
    return {
      type: actionTypes.SET_LANGUAGE,
      language,
    };
  };

  export const setConfUser = (confUser) => {
    return {
      type: actionTypes.CONF_USER,
      confUser,
    };
  };