import * as actionTypes from "../types/env.types";

import Cookies from "js-cookie";

const initialState = {
  urls: {
    api_key: "",
    api_url: "",
  },
  language: null,
  lang_values: null,
  lang_conf: null,
  currentPage: window.location.pathname,
  confUser: null,
  hash: '',
  treeVal: null,
  curr_address: null
};

export default function envReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_URLS:
      return {
        ...state,
        urls: action.urls,
      };

    case actionTypes.SET_ADDRESS:
      return {
        ...state,
        curr_address: action.curr_address,
      };
      case actionTypes.HASH:
        return {
          ...state,
          hash: action.hash,
        };
        case actionTypes.TREE_VALID:
          return {
            ...state,
            treeVal: action.treeVal,
          };
      case actionTypes.CONF_USER:
        return {
          ...state,
          confUser: action.confUser,
        };
      case actionTypes.CURRENT_PAGE:
        return {
          ...state,
          currentPage: action.currentPage,
        };

    case actionTypes.LANG_CONF:
      return {
        ...state,
        lang_conf: action.lang_conf,
      };

    case actionTypes.LANG_VALUES:
      return {
        ...state,
        lang_values: action.lang_values,
      };
    case actionTypes.SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };

    default:
      return state;
  }
}
