import React, {useCallback, useEffect} from 'react';
import {RowScales} from "./row_scales_component";


const WFmanuf = ({all_v, conds}) => {
    // console.log(conds)
    return (
        <>
            <RowScales
                data_scales_array={
                    ["inlet_pipe_diameter", "contin_cons_water"]
                        .map((item)=>({...all_v[item], name: item}))
                }
                type_page={'watt_par'}
            />
            <RowScales
                data_scales_array={
                    ["sewerage_select", '']
                        .map((item)=> {
                            let obj = {...all_v[item], name: item};
                            // if (item === 'sewerage_select'){
                            //     obj.only_ = 0;
                            // }
                            return ({...obj})
                        })
                }
                type_page={'watt_par'}
            />


            <RowScales
                data_scales_array={
                    ["has_add_cons",
                        conds.has_add_cons? "volume_oth" : ""
                    ]
                        .map((item)=> {
                            // console.log(item, conds.has_add_cons, all_v[item])
                            return   ({...all_v[item], name: item})

                        })
                }
                type_page={'watt_par'}
            />

            {/*{*/}
            {/*    conds.add_cons ?*/}
            {/*        <RowScales*/}
            {/*            data_scales_array={*/}
            {/*                [ "volume_oth",""]*/}
            {/*                    .map((item)=>({...all_v[item], name: item}))*/}
            {/*            }*/}
            {/*            type_page={'watt_par'}*/}
            {/*        /> : null*/}
            {/*}*/}





            <RowScales
                data_scales_array={
                    ["watering", conds.waterin?  "volume_ck" : '']
                        .map((item)=>({...all_v[item], name: item}))
                }
                type_page={'watt_par'}
            />

            {
                conds.waterin?
                    <RowScales
                        data_scales_array={
                            ["volume_dr", "day_ck" ]
                                .map((item)=>({...all_v[item], name: item}))
                        }
                        type_page={'watt_par'}
                    /> : null
            }

            <RowScales
                data_scales_array={
                    ["has_acc", conds.has_acc?  "acc_type" : '']
                        .map((item)=>({...all_v[item], name: item}))
                }
                type_page={'watt_par'}
            />
            {
                conds.has_acc?
                    <RowScales
                        data_scales_array={
                            ["volume_tan", "hor_ver" ]
                                .map((item)=>({...all_v[item], name: item}))
                        }
                        type_page={'watt_par'}
                    /> : null
            }

            <RowScales
                data_scales_array={
                    ["interruption_water", '']
                        .map((item)=>({...all_v[item], name: item}))
                }
                type_page={'watt_par'}
            />

            <RowScales
                data_scales_array={
                    ["know_pres_watt", conds.known_wat_pr?  "water_pres" : '']
                        .map((item)=>({...all_v[item], name: item}))
                }
                type_page={'watt_par'}
            />
        </>
    )
}

export default WFmanuf;