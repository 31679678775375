
import {glv} from "./App";

export function format_items_to_global_form(item) {
    switch (item.type) {
        case 'select': {
            return item.only_ ?  item.only_ :null
        }
        case 'switch': {
            return item.scale
        }
        case 'muisliderX1': {
            return {
                type: item.scale ?item.scale[0].name : '',
                value: item.scale ? item.scale[0].size.min:''
            }
        }
        case 'muisliderX2': {
            return {
                type: item.scale[0].name,
                value: [item.scale[0].size.min, item.scale[0].size.max]
            }
        }
    }
}

let format_number_phone = (tel) => {
    let formatted_number = `${tel.tel ? '+' +trim_value(tel.operator_code)
    +' '+
    trim_value(tel.tel)
        .match(new RegExp('.{1,3}', 'g'))
        .join("-") : ''
    }`
    return formatted_number
}


export function format_items_to_view_form(item) {

    switch (item.name) {
        case 'tel': {
            return format_number_phone(item.data)
        }
        case 'anal': {
            if (item?.data?.constructor === Object) {
                console.log(item?.data)
                if (item.data.value.constructor===Array) {
                    return item?.data?.value[0] +' - ' + item.data.value[1] + ' ' +item.data.type
                } else {
                    if (!item.data.value&&!item.data.type) {
                        return null
                    }
                    return item.data.value +  '/' +item.data.type
                }
            }
            if (item?.data?.constructor === Boolean) {
                if (glv){
                    return glv(`mainStepper.third_step.switches.${item.name___}.data.${Number(item?.data)}`)
                }
                return   String(item?.data)
            }
        }
        case 'watt_par': {
            if (item?.data?.constructor === Object) {
                console.log(item?.data)
                if (item.data.value.constructor===Array) {
                    return item?.data?.value[0] +' - ' + item.data.value[1] + ' ' +item.data.type
                } else {
                    if (!item.data.value&&!item.data.type) {
                        return null
                    }
                    return item.data.value +  '/' +item.data.type
                }
            }
            if (item?.data?.constructor === Boolean) {
                if (glv){
                    return glv(`mainStepper.third_step.switches.${item.name___}.data.${Number(!item?.data)}`)
                }
                return String(item?.data)
            }
        }
        case 'nip': {
            return item.data
        }
        case 'instalation_adr': {
            return item.data.string_adr
        }

        default: {
            return item.data
        }
    }
}

export function trim_value(str){
    return str.replace(/\s+/g, '').replace(/[^\d]/g, '').trim()
}


export function create_dn(element) {
    if (!element) {
        return null
    }
    let new_ = {...element, dn: ''};
    ['road', 'house_number', 'country', 'county', 'state', 'city', 'town', 'neighbourhood', 'postcode'].forEach(item => {
            if (element.address[item]) {
                let dop_symb = ', ';
                if (item === 'road' && element.address['house_number']) {
                    dop_symb = ' ';
                }
                if (item === 'postcode') {
                    dop_symb = '';
                }
                new_.dn += element.address[item] + dop_symb
            }
        }
    );

    return new_
}


export function formatDate(date, format) {
    const map = {
        mm: date.getMonth() + 1 < 10 ? '0'+ (date.getMonth() + 1) : date.getMonth() + 1,
        dd: date.getDate() < 10 ? '0'+ date.getDate() :date.getDate() ,
        yy: date.getFullYear(),
        yyyy: date.getFullYear()
    }

    return format.replace(/mm|dd|yy/gi, matched => map[matched])
}

export function form_num_list (arr, curr_scale) {

    let returned_arr = arr.map((item,index)=> {
        return {
            id: item.name+'.'+curr_scale.name,
            name: item?.name,
            text: item?.text_name,
        }
    })
    if (returned_arr.length===1) {
        returned_arr.push({
            id: 'disabled',
            name: '',
            text: '',
        })
        return returned_arr;
    }
    return returned_arr
}


export function nip_validate(r) {
    if ("string" != typeof r)
        return !1;
    var t = r.replace(/-/gu, "");
    if (!/^[0-9]{10}$/u.test(t))
        return !1;
    var n = String(t).split("")
        , e = [6, 5, 7, 2, 3, 4, 5, 6, 7].map((function(r, t) {
            return r * parseInt(n[t], 10)
        }
    ))
        , u = 0;
    e.forEach((function(r) {
            u += r
        }
    ));
    var i = u % 11;
    return parseInt(n[9], 10) === i
}


export function validateEmail(email) {
    var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(String(email).toLowerCase());
}

export function pesel_validate(r) {
    var t = Number(r.substring(2, 4));
    if (!t || t % 20 > 12)
        return !1;
    var n = Number(r.substring(4, 6));
    if (!n || n < 1 || n > 31)
        return !1;
    if (!/^[0-9]{11}$/u.test(r))
        return !1;
    var e = [1, 3, 7, 9]
        , u = ("" + r).split("").map((function(r) {
            return parseInt(r, 10)
        }
    ))
        , i = function(r, t) {
        var n = "function" == typeof Symbol && r[Symbol.iterator];
        if (!n)
            return r;
        var e, u, i = n.call(r), a = [];
        try {
            for (; (void 0 === t || t-- > 0) && !(e = i.next()).done; )
                a.push(e.value)
        } catch (r) {
            u = {
                error: r
            }
        } finally {
            try {
                e && !e.done && (n = i.return) && n.call(i)
            } finally {
                if (u)
                    throw u.error
            }
        }
        return a
    }(u.splice(-1), 1)[0]
        , a = u.reduce((function(r, t, n) {
            return r + t * e[n % 4]
        }
    )) % 10;
    return 10 - (0 === a ? 10 : a) === i
}

function getTop(el) {
    return el.offsetTop + (el.offsetParent && getTop(el.offsetParent));
}

function getLeft(el) {
    return el.offsetLeft + (el.offsetParent && getLeft(el.offsetParent));
}
