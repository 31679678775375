import {glv} from "./App";


export let tree_ = {
    "plumb": {
        "human": {
           type_: "plumb_human",
           keys: ["bdsm", "microlog", "ph", "temp", "dir_ger", "color_iron", "iron"]
},
        "technic": {
            type_: "plumb_tech",
            keys:  ["bdsm", "ph", "temp", "dir_ger", "color_iron","iron", ],
        },
        "mixed": {
            type_: "plumb_mix",
            keys:  ["bdsm", "microlog", "ph", "temp", "dir_ger",  "color_iron","iron"]
        },
    },
    "well": {
        "human": {
            type_: "well_human",
            keys:   ["iron", "marg", "bdsm", "ph", "temp",  "color_df","color_dr ", "elect", "amiac_ion", "dir_ger", "amoniy_nit",  "nitrats", "spoke", "bleach"]
        },

        "technic": {
            type_: "well_tech",
            keys: ["microlog", "iron", "marg", "bdsm", "ph",  "temp","color_df", "color_dr "  ],
        },

        "mixed":{
            type_: "well_mix",
            keys: ["iron", "marg", "bdsm", "ph", "temp",  "color_df","color_dr ", "elect", "amiac_ion", "dir_ger", "amoniy_nit",  "nitrats", "spoke", "bleach"]
        }
    }
}



export let  all_vals = null;

export  const set_all_vals = (vals)=> {
    all_vals = {...vals};
}


export let arr_watering = [
    "volume_ck",
    "volume_dr",
    "day_ck",
]

export let arr_sewerage_select = [
    "sewerage",
]

export let arr_acc = [
    "acc_type",
    "volume_tan",
    "hor_ver",
]

export let arr_wat_pres = [
    "water_pres",
]

export let arr_has_addit_consum = [
    "additional_consumption",
]

export let arr_addit_consum = [
    "volume_oth",
]

export let arr_count_laundresses = [
    "count_laundresses",
]

export let arr_well_rest = [
    "has_acc",
]
export let arr_plumb_rest = [
    "interruption_water",
    'know_pres_watt'
]

export let tree_water_for = {
    home: {
        type_: "home",
        keys: [
            "count_peoples",
            "count_consumption_taps",
            "day_water",
            "sewerage_select",
            "count_bathrooms",
            "has_add_cons",
            "watering",
            "has_acc",
            "interruption_water",
            'know_pres_watt'
        ]
    },
    communal_home: {
        type_: "communal_home",
        keys: [
            "count_peoples",
            "count_consumption_taps",
            "day_water",
            "sewerage_select",
            "count_bathrooms",
            "has_add_cons",
            "has_acc",
            "interruption_water",
            'know_pres_watt'
        ]
    },
    flat: {
        type_: "flat",
        keys: [
            "count_peoples",
            "count_consumption_taps",
            "day_water",
            "sewerage_select",
            "count_bathrooms",
            "has_add_cons",
            "watering",
            "interruption_water",
            "know_pres_watt"
        ]
    },
    manufactur_perf: {
        type_: "manufactur_perf",
        keys: [
            "inlet_pipe_diameter",
            "contin_cons_water",
            "hours_load",
            "sewerage_select",
            "has_add_cons",
            "watering",
            "has_acc",
            "interruption_water",
            "know_pres_watt"
        ]
    },
    ferm: {
        type_: "ferm",
        keys: [
            "inlet_pipe_diameter",
            "contin_cons_water",
            "hours_load",
            "sewerage_select",
            "has_add_cons",
            "watering",
            "has_acc",
            "interruption_water",
            "know_pres_watt"
        ]
    },
    ogorod: {
        type_: "ferm",
        keys: [
            "inlet_pipe_diameter",
            "hours_load",
            "sewerage_select",
            "has_add_cons",
            "volume_ck",
            "volume_dr",
            "day_ck",
            "has_acc",
            "interruption_water",
            "know_pres_watt"
        ]
    },
    hotel: {
        type_: "hotel",
        keys: [
            "count_numbers",
            "count_cons_taps_simultaneously",
            "day_water",
            "sewerage_select",
            "has_launddress",
            "has_add_cons",
            "watering",
            "has_acc",
            "interruption_water",
            "know_pres_watt"
        ]
    },
    restoraunt:
        {
            type_:"restoraunt",
            keys:     [
                "count_consumption_taps",
                "day_water",
                "sewerage_select",
                "has_paramoka",

                'volume_oth'
            ],
        },

    hotel_restoran:         {
        type_:"hotel_restoran",
        keys:     [
            "count_numbers",
            "count_cons_taps_simultaneously",
            "count_consumption_taps",
            "day_water",
            "sewerage_select",
            "has_launddress",
            "has_paramoka",
            'has_filter',
            "has_add_cons",
            "watering",
            "has_acc",
            "interruption_water",
            "know_pres_watt"
        ],
    },

}

// {
//     id: 'home',
//         text: glv('mainStepper.third_step.selects.what_for_water.domik')
//
// },
// {
//     id: 'flat',
//         text: glv('mainStepper.third_step.selects.what_for_water.flat')
// },
// {
//     id: 'communal_home',
//         text: glv('mainStepper.third_step.selects.what_for_water.communalka')
// },
// {
//     id: 'manufactur_perf',
//         text: glv('mainStepper.third_step.selects.what_for_water.manufactura')
// },
// {
//     id: 'ferm',
//         text: glv('mainStepper.third_step.selects.what_for_water.ferma')
// },
// {
//     id: 'ogorod',
//         text:glv('mainStepper.third_step.selects.what_for_water.ogorod')
// },
// {
//     id: 'hotel',
//         text: glv('mainStepper.third_step.selects.what_for_water.hotel')
// },
// {
//     id: 'restoraunt',
//         text: glv('mainStepper.third_step.selects.what_for_water.restoraunt')
// },
// {
//     id: 'hotel_restoran',
//         text: glv('mainStepper.third_step.selects.what_for_water.hotel_restoran')
// },


