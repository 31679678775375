import React, { useEffect, useCallback, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, Divider, TextField } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { NavLink } from 'react-router-dom';
import SelectComponent from "./custom_select";
import * as actions from "./redux/actions/env.actions";
import {glv} from "./App"
import {lang_arr} from "./App";
import { validatePolish } from 'validate-polish';
import MaskedInput from 'react-maskedinput';
import { IMaskInput } from 'react-imask';
import CloseIcon from '@material-ui/icons/Close';
const ibantools = require('ibantools');



function PaperComponent(props) {
    return (
        // <Draggable
        //     handle="#draggable-dialog-title"
        //     cancel={'[class*="MuiDialogContent-root"]'}
        // >
            <Paper {...props} />
        // </Draggable>
    );
}

let copy_bank_obj = {
        bank_id: 'mbank',
        card_number: '21 1140 2004 0000 3902 8051 7530',
        is_valid_card_num: false,
        logo_img: 'https://offer.bregus.eu/images/bank/ico/mbank.png',
        name: 'mBank',
        bank_code: '1140',
        confirmed: true
    };


function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

function ErrorText(props) {
    const matches = useMediaQuery('(max-width:1025px)');
    return  <div style={{
        position: 'absolute',
        top: 20,
        fontSize: 13,
        left: 10,
        padding: 2,
        background: 'white',
        color: '#e00052',
        display: props.err ? 'block' : 'none'
    }}> {
        props.err
    }
    </div>
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: 0,
        paddingRight: 0,
    },

    fromToLab: {
        marginTop: 20,
    },
    highlight: {
        color: "white",
        backgroundColor: "#000",
    },
    title: {
        flex: "1 1 100%",
    },
    dI: {
        backgroundColor: "#ff6",
    },

    formControl: {
        marginTop: 10,
        width: "100%",
    },

    globalHelpLabel: {
        marginTop: 10,
        textAlign: "center",
        width: "100%",
        // fontWeight: 'bold'
        paddingBottom: 5,
        borderBottom: "rgba(0,0,0,0.1) solid",
    },

    simpleHelp: {
        marginTop: 5,
        textAlign: "center",
        color: "#c2c2c2",
        width: "100%",
    },

    titleLabel: {
        marginTop: 10,
        textAlign: "center",
        fontWeight: "bold",
        paddingBottom: 5,
        width: "100%",
        borderBottom: "rgba(0,0,0,0.1) solid",
    },
    okBtn: {
        background: "#5E5AB9",
        // boxShadow: '-9px -9px 16px rgba(255, 255, 255, 0.6), 4px 4px 8px rgba(63, 60, 255, 0.12)',
        borderRadius: 12,
        color: "white",
        fontFamily: "gilroy",
        fontWeight: 600,
        width: "43%",
        marginRight: 10,
        marginBottom: 15,
        "&:hover": {
            background: "#5e5ab9",
            boxShadow: "none",
        },
    },
    scrPap: {
        maxHeight: "none",
    },
    cancBtn: {
        marginBottom: 15,
        fontWeight: 600,
        width: "43%",
        background: "linear-gradient(0deg, #cfddfc, #E3E9F7)",
        // boxShadow: '-9px -9px 24px rgba(255, 255, 255, 0.570094), 20px 22px 40px rgba(0, 0, 0, 0.0826595)',
        opacity: 0.7,
        borderRadius: 12,
        marginLeft: 10,
        color: "#2F2A61",
        fontFamily: "gilroy",
        marginRight: 10,
        "&:hover": {
            // background: 'linear-gradient(0deg, #cfddfc, #DFE6F6)',
            // boxShadow:' inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)',
        },
        "&:active": {},
    },
}));

function space(str, after) {
    if (!str) {
        return false;
    }
    after = after || 4;
    var v = str.replace(/[^\dA-Z]/g, ''),
        reg = new RegExp(".{" + after + "}", "g");
    return v.replace(reg, function (a) {
        return a + ' ';
    });
}


let curr_bank_id = '';

function trim_value(str){
    return str.replace(/\s+/g, '').replace(/[^\d]/g, '').trim()
}


const BindBankModal = (props) => {
    const dispatch = useDispatch();
    const confUser = useSelector((state) => state.envReducer.confUser);
    const classes = useStyles();
    // const { addToast } = useToasts();
    // const confirm = useConfirm();
    const matches = useMediaQuery("(max-width:1050px)");

    const sizes = useWindowSize();
    const localization = useSelector((state) => state.envReducer.lang_values);


    const language = useSelector((state) => state.envReducer.language);

    const set_language = React.useCallback((language) => dispatch(actions.setLanguage(language)), [
        dispatch,
    ]);



    const [show_input_card_num, set_show_input_card_num] = React.useState(false);
    const [error_card, set_error_card] = React.useState(false);


    React.useEffect(()=> {
        if (curr_bank_id==='another_bank_hz_kakoi') {
            props.change_bank_data('set', 'physics.binded_bank.card_number', '')
        }
        validate_card_number(props.card_num)
    }, [props.curr_bank.bank_id, props.card_num])

    React.useEffect(()=> {
            if (props.open==='sel_bank') {
                set_show_input_card_num(false)
            }
        if (props.open==='card_num') {
            set_show_input_card_num(true)
        }
    }, [props.open])

    let _banks_ = Object.keys(props.banks_).map((key_)=> {
        return {...props.banks_[key_], id: key_}
    });
    _banks_.push({
        id: 'another_bank_hz_kakoi',
        name: 'Other bank',
        site: 'default',
        img: {
            logo: 'https://offer.bregus.eu/images/bank/ico/bank_i.png',
            banner: 'https://offer.bregus.eu/images/bank/full/bank_b.png'
        }
    })

    useEffect(() => {
        document.title =glv('document_titles.main_title');
    }, []);
    let trimed_iban = trim_value(props.curr_bank.card_number);
    let cond_show_save_btn = show_input_card_num&&!props.curr_bank.is_valid_card_num && trimed_iban.length ===26;

    const validate_code_bank = (val_)=> {
        let space_removed_val = val_.replace(/\s+/g, '').trim();
        let b_code = space_removed_val.slice(2,6);

    }

    const validate_card_number = (val_card) => {

         let space_removed_val = trim_value(val_card);

        if (Number.isNaN(space_removed_val)) {
            return
        }
        if (/\D/.test(space_removed_val)) {
            return
        }
        let val_iban = ibantools.extractIBAN('PL'+space_removed_val);
        let formatted_iban = ibantools.friendlyFormatIBAN('PL'+space_removed_val);
        let b_code = space_removed_val.slice(2,6);
        if (space_removed_val.length>26) {
            return
        }
        let valid = true;
        if (space_removed_val.length<26) {
            if (space_removed_val.length>6&&b_code!==props.curr_bank.bank_code&&props.curr_bank.bank_id!=='another_bank_hz_kakoi') {
                valid = false;
            }
            props.change_bank_data('set', 'physics.binded_bank', {
                ...props.curr_bank,
                card_number:val_card,
                is_valid_card_num:valid ? false
                    :
                    glv('account.second_step.bind_bank_modal.errors.number_not_match_bank')
            })

        }else {
            if (!val_iban.valid) {
                valid = false
            }
            if (b_code!==props.curr_bank.bank_code&&props.curr_bank.bank_id!=='another_bank_hz_kakoi') {
                props.change_bank_data('set', 'physics.binded_bank', {
                    ...props.curr_bank,
                    card_number:val_card,
                    is_valid_card_num: glv('account.second_step.bind_bank_modal.errors.number_not_match_bank')
                })
                return
            }
            props.change_bank_data('set', 'physics.binded_bank', {
                ...props.curr_bank,
                card_number:val_card,
                is_valid_card_num:
                    valid ? false : glv('account.second_step.bind_bank_modal.errors.card_number_not_valid')
            })

        }
    }
  // if (props.curr_bank.bank_id!==curr_bank_id&&props.curr_bank.card_number) {
  //     validate_card_number(props.curr_bank.card_number)
  // }


    return  (
            <Dialog
                style={{zIndex: 1308}}
                fullScreen = {matches? true : false}
                fullWidth
                maxWidth={"sm"}
                open={props.open}
                onClose={()=>{
                    // console.log(props.curr_bank.card_number.length)
                    if((props.curr_bank.is_valid_card_num||!props.curr_bank.card_number||trim_value(props.curr_bank.card_number).length< 26 )&&props.curr_bank.confirmed) {
                        // console.log(copy_bank_obj)
                        props.change_bank_data('set', 'physics.binded_bank', {
                            ...copy_bank_obj
                        })
                    }

                        props.close()
                    set_show_input_card_num(false)
                        if (!props.curr_bank.confirmed) {
                            props.change_bank_data('set', 'physics.binded_bank', {
                                ...props.curr_bank,
                                card_number:'val_card',
                                is_valid_card_num: false,
                                bank_id: '',
                                logo_img: '',
                                name: '',
                                bank_code: ''
                            })
                        }
                }}
                classes={{ paperScrollPaper: classes.scrPap }}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                PaperProps={{
                    style: {

                        background: "#ffffff",
                        // boxShadow:'-5px -5px 7px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(23, 23, 23, 0.12)',
                        borderRadius: matches ? 0 : 5,
                        maxWidth: matches ? '100%' : "50%",
                        minWidth: "200px",
                        // height: "80%",
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "space-between",
                        overflow: "hidden",
                    },
                }}
                style={{}}
            >


                <div style={
                    matches ?
                    {
                    textAlign: "center",
                        borderBottom: matches ?  '2px solid #d9d9d9' : '',
                    // marginTop: matches ? 25 : 0,
                    display: 'flex', width: '100%',
                    justifyContent: 'space-between',
                    alignItems:'center'
                } : {}
                }>
                    {
                        matches ?     <div></div> : null
                    }

                    <DialogTitle
                        style={{
                          textAlign: "center", borderBottom: matches ? '' : '2px solid #d9d9d9',
                        }}
                        id="draggable-dialog-title"
                    >
                        {
                            show_input_card_num ?
                                glv("account.second_step.bind_bank_modal.titles.enter_card_num")
                                :
                                glv("account.second_step.bind_bank_modal.titles.select_bank")
                        }
                    </DialogTitle>
                    {
                        matches ?
                            <CloseIcon

                                onClick={()=>{
                                    if((props.curr_bank.is_valid_card_num||!props.card_num)&&props.curr_bank.confirmed) {
                                        props.change_bank_data('set', 'physics.binded_bank', {
                                            ...copy_bank_obj
                                        })
                                    }

                                props.close()
                                    set_show_input_card_num(false)
                                    if (!props.curr_bank.confirmed) {
                                        props.change_bank_data('set', 'physics.binded_bank', {
                                            ...props.curr_bank,
                                            card_number:'val_card',
                                            is_valid_card_num: false,
                                            bank_id: '',
                                            logo_img: '',
                                            name: '',
                                            bank_code: ''
                                        })
                                    }
                        }} style={{marginRight: 10, cursor: 'pointer'}}/> : null

                    }

                </div>
                {/*<div>*/}
                {/*    <DialogTitle*/}
                {/*        style={{*/}
                {/*            // marginTop: matches ? 25 :0,*/}
                {/*        }}*/}
                {/*        id="draggable-dialog-title"*/}
                {/*    >*/}

                {/*        /!*{glv(`devicesPage.dev_list.frames.fireware.header`)}*!/*/}
                {/*    </DialogTitle>*/}

                {/*</div>*/}
                <div>

                    {
                        show_input_card_num ?
                            <div>
                                <div className="step-row w100" style={{
                                    display: "flex",
                                    margin: `32px 15px ${cond_show_save_btn? 32 : 15}px 15px`,
                                    width: 'auto',
                                    // marginBottom: props.curr_bank.is_valid_card_num&&props.curr_bank.card_number?.length<26   ? '' : 0
                                }}>

                                    <div style={{width: '100%', display: 'flex', alignItems: 'flex-end'}} >
                                        <div
                                            onClick={()=>set_show_input_card_num(false)}
                                            style={{
                                            border: '1px solid rgb(238,238,238)',
                                            width: 48,
                                            height: 48,
                                            borderRadius:'5px 0px 0px 5px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer'
                                        }}>
                                            <img src={props.curr_bank.logo_img}
                                                 style={{width: '55%', height: '55%',borderRadius:'2px'}}/>
                                        </div>
                                        <div className="step-row-field" style={{width:  '100%' }}>
                                            <label
                                                style={{marginTop: 0}}
                                                htmlFor="sf6">
                                                {
                                                    props.curr_bank.name|| 'Bank'
                                                    // glv(`account.second_step.bind_bank_modal.inputs.card_num_f.label`)
                                                }
                                                {/*<sub>*</sub>*/}
                                            </label>

                                            <IMaskInput
                                                id="sf6"
                                                inputmode="numeric"
                                                placeholder={glv(`account.second_step.bind_bank_modal.inputs.card_num_f.placeholder`)}
                                                style={{border: props.curr_bank.is_valid_card_num ? '1px solid #e00052' : '', borderRadius:'0px 5px 5px 0px',}}
                                                value={props.card_num}
                                                onAccept={
                                                    (value, mask) => {
                                                        validate_card_number(value)
                                                    }
                                                }
                                                onChange={(e, mask) => {}
                                                  //   console.log(e.target.value)
                                                  //   validate_card_number(e.target.value)
                                                  // }
                                                }
                                                mask={'00 0000 0000 0000 0000 0000 0000'}
                                            />
                                            <ErrorText err={props.curr_bank.is_valid_card_num}/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            :
                            <div
                                className={'hide_scroll_general hide_scroll'}
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'auto',
                                    width: '100%',
                                    maxHeight: matches ? sizes.height - 66 : 600,
                                    overflowY: 'auto',
                                    paddingBottom:matches ? 80 : 0
                                    // gridGap: 10,
                                    // flexWrap: 'wrap',
                                    //     margin: '24px 50px'
                                }}
                            >
                                {
                                    _banks_.map((bank, index)=>{
                                        return (
                                            <div
                                                key={bank.name+index+'bank_'}
                                                onClick={()=>{
                                                    props.change_bank_data('set', 'physics.binded_bank', {
                                                        ...props.curr_bank,
                                                        bank_id: bank.id,
                                                        logo_img: bank.img.logo,
                                                        name: bank.name,
                                                        bank_code: bank.code
                                                    })
                                                    curr_bank_id = bank.id;
                                                     set_show_input_card_num(true);
                                                 }}
                                                 className={'bank_wrapper'}
                                                 style={{display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                     background: props.curr_bank.bank_id===bank.id? '#3DB9E5' : '',
                                                     gridColumn: matches&&index===_banks_.length-1? '1/3' : ''
                                                 }}
                                            >
                                                {
                                                index===_banks_.length-1 ?
                                                    <span>{glv(`account.second_step.bind_bank_modal.buttons.another_bank`)}</span>
                                                    :
                                                    <img src={bank.img.banner} style={{width: '100%', height: '100%'}}/>
                                            }

                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }

                </div>

                {/* <AppBar position="fixed"  style={{   top: 'auto', bottom: 0,}}> */}
                <DialogActions style={{ marginTop: 0, marginBottom: 0,

                    display: cond_show_save_btn ? 'block': 'none', padding: '0px 15px 15px' }}>


                            <button
                                onClick={()=> {
                                    props.change_bank_data('set', 'physics.binded_bank.confirmed', true)
                                    set_show_input_card_num(false)
                                    props.close();
                                    copy_bank_obj = {...props.curr_bank}
                                }
                                }

                                className={'save_card_num_btn'}
                            >
                                { glv("account.second_step.bind_bank_modal.buttons.save_card_num")}
                            </button>

                </DialogActions>
                {/* </AppBar> */}
            </Dialog>

    );
};

export default BindBankModal;
