import React from 'react';
import RadioBtnsComponent from './radio-btns';
import {useInput} from './hooks/useInput';
import Map from './Map';
import {useDispatch, useSelector} from "react-redux";
import * as actions from "./redux/actions/env.actions";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import '@paraboly/react-osm-geocoding/dist/index.css'
import useDebounce from './hooks/useDebounce';
import {glv, steps} from "./App";
import AdresInput from "./AdresInput";
import PhoneInput from "./phone_number_input"
import {global_form, set_field_in_global_form, set_steps} from "./App";
import RadioParaComponent from './radio_btn_para'

import {assocPath} from 'ramda';
const debounce = require('debounce');


function trim_value(str){
    return str.replace(/\s+/g, '').replace(/[^\d]/g, '').trim()
}

function validateEmail(email) {
    var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(String(email).toLowerCase());
}

export function create_dn(element) {
    if (!element) {
        return null
    }
    let new_ = {...element, dn: ''};
    ['road', 'house_number', 'country', 'county', 'state', 'city', 'town', 'neighbourhood', 'postcode'].forEach(item => {
            if (element.address[item]) {
                let dop_symb = ', ';
                if (item === 'road' && element.address['house_number']) {
                    dop_symb = ' ';
                }
                if (item === 'postcode') {
                    dop_symb = '';
                }
                new_.dn += element.address[item] + dop_symb
            }
        }
    );

    return new_
}

function formatDate(date, format) {
    const map = {
        mm: date.getMonth() + 1,
        dd: date.getDate() < 10 ? '0'+ date.getDate() :date.getDate() ,
        yy: date.getFullYear(),
        yyyy: date.getFullYear()
    }

    return format.replace(/mm|dd|yy/gi, matched => map[matched])
}
//6252219184

export default function ThirdAccStep(props) {
    const dispatch = useDispatch();

    const matches = useMediaQuery('(max-width:1025px)');

    const curr_address = useSelector((state) => state.envReducer.curr_address);


    const set_curr_address = React.useCallback((address) => dispatch(actions.set_address(address)), [
        dispatch,
    ]);


    //general

    const [oferty, set_oferty] = React.useState('');

    const [firm_osob, set_firm_osob] = React.useState(false);

    const [show_pub_loading, set_show_pub_loading] = React.useState(false);

    const [city, setCity] = React.useState('');
    const [city_err, set_city_err] = React.useState(false);

    // fiz

    const [male, set_male] = React.useState(true);

    const [phone_number, set_phone_number] = React.useState('');

    const [email_fiz, set_email_fiz] = React.useState('');
    const [email_fiz_error, set_email_fiz_error] = React.useState(false);

    const [address_fe_fiz, set_address_fe_fiz] = React.useState('');
    const [address_fe_fiz_err, set_address_fe_fiz_err] = React.useState(false);

    const [ln_fiz, set_ln_fiz] = React.useState('');
    const [ln_fiz_err, set_ln_fiz_err] = React.useState('');

    const [fn_fiz, set_fn_fiz] = React.useState('');
    const [fn_fiz_err, set_fn_fiz_err] = React.useState('');

    const [comments_fiz, set_comments_fiz] = React.useState('');


    // firm

    const [email_firm, set_email_firm] = React.useState('');
    const [email_firm_error, set_email_firm_error] = React.useState(false);

    const [nipnum, set_nipnum] = React.useState('');
    const [nipnum_err, set_nipnum_err] = React.useState('');

    const [firm_name, set_firm_name] = React.useState('');
    const [firmname_err, set_firmname_err] = React.useState('');


    const [phone_number_firm, set_phone_number_firm] = React.useState('');

    const [phone_number_firm_contact, set_phone_number_firm_contact] = React.useState('');

    const [address_firm, set_address_firm] = React.useState('');
    const [address_firm_err, set_address_firm_err] = React.useState(false);


    const [f_l_name_osob_firm, set_f_l_name_osob_firm] = React.useState('');
    const [f_l_name_osob_firm_err, set_f_l_name_osob_firm_err] = React.useState('');



    // const check_type_client = ()=> {
    //     if (props.form.client.physics.select) {
    //
    //         set_firm_osob(true)
    //         return
    //     }
    //     if (props.form.client.juristic.select) {
    //
    //         set_firm_osob(false)
    //     }
    // }
    // React.useEffect(() => {
    //
    //     check_type_client()
    // }, [props.form]);

    React.useEffect(() => {
        // document.querySelector('')
    }, []);

    function validate_osob_form() {
        let some_error = false;


        if (!city) {
            some_error = true;
            set_city_err(glv(`mainStepper.first_step.inputs.errors.local_req_err`))
        }

        if (phone_number.error) {
            some_error = true;
        }

        if (!email_fiz) {
            some_error = true;
            set_email_fiz_error(glv(`mainStepper.first_step.inputs.errors.local_req_err`))
        }
        if (email_fiz_error) {
            some_error = true;
        }
        if (!address_fe_fiz) {
            some_error = true;
            set_address_fe_fiz_err(glv(`mainStepper.first_step.inputs.errors.local_req_err`))
        }
        if (!ln_fiz) {
            some_error = true;
            set_ln_fiz_err(glv(`mainStepper.first_step.inputs.errors.local_req_err`))
        }
        if (!fn_fiz) {
            some_error = true;
            set_fn_fiz_err(glv(`mainStepper.first_step.inputs.errors.local_req_err`))
        }

        return !some_error
    }




    return (
        <form className={"container step step1" + props.clas}

        >




            <div className="step-row " style={{display: "flex" }}>
                <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 0 : 0 }}>

                        <span style={{fontWeight: '500',  color: '#000000', }}>
                            <span style={{color: '#6D6E71'}}>{glv('account.third_step.type_acc.title' )}</span>
                            {': '}
                            {props.form_.physics_juristics?
                                glv('account.third_step.type_acc.physics' ):
                                glv('account.third_step.type_acc.juristics' )}
                        </span>
                </div>
                <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 24 : 0 }}>


                    <span style={{fontWeight: '500',  color: '#000000' }}>
                        <span style={{color: '#6D6E71'}}>
                           {glv('account.third_step.type_acc.registration_date' )}
                        </span>
                        {': '}
                        {formatDate(new Date(), 'mm.dd.yy')}
                    </span>


                           {/*<span>{formatDate(new Date(), 'mm.dd.yy')}</span>*/}


                </div>
            </div>
            <div className="" style={{width: '100%', height: 1, backgroundColor: '#D9D9D9',
            marginBottom: matches ? 0 : 32,
                marginTop: matches? 24 : 0
            }}>
                {/*<div className="textR_line">*/}
                {/*    /!*<span></span>*!/*/}
                {/*</div>*/}
            </div>
            {/*<div className="step-row text">*/}
            {/*    <div className="textR_line">*/}
            {/*        <span style={{fontWeight: '500',  color: '#6D6E71', }}>*/}
            {/*            /!*{glv(`account.third_step.type_acc.title_content`)}*!/*/}
            {/*        </span>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="step-toptext" style={{marginTop: matches ? 24 : 0, color: '#000000' }}>

                <div className="dect">
                    {glv(`account.third_step.title_content`)}
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*{glv(`account.first_step.description.p2`)}*/}
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*{glv(`account.first_step.description.p3`)}*/}
                </div>
                <div className="mob">
                    {glv(`account.third_step.title_content`)}
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*{glv(`account.first_step.description.p2`)}*/}
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*{glv(`account.first_step.description.p3`)}*/}
                </div>
            </div>
            {/*<hr style={{color: '#D9D9D9', backgroundColor: '#D9D9D9'}}/>*/}
            {
                props.form_.physics_juristics ?
                    <div
                        style={matches? {}: {
                            display: 'grid',
                            gridTemplateColumns: 'auto',
                            // gridAutoRows: '1fr'
                        }}
                    >
                        <div className="step-row " style={{display: "flex" }}>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 24 : 0}}>


                                    <span style={{fontWeight: '500',  color: '#6D6E71', }}>{glv('account.third_step.physics.name' )}{': '}</span>

                                <div className={'fake_input'}>  <span>{props.form_.physics.name}</span></div>


                            </div>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 24 : 0}}>


                                    <span style={{fontWeight: '500',  color: '#6D6E71', }}>{glv('account.third_step.physics.sname' )}{': '}</span>

                                <div className={'fake_input'}>        <span>{props.form_.physics.sname}</span></div>


                            </div>
                        </div>
                        <div className="step-row " style={{display: "flex" }}>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 24 : 0}}>


                                    <span style={{fontWeight: '500',  color: '#6D6E71', }}>{glv('account.third_step.physics.phone' )}{': '}</span>
                                    <div className={'fake_input'}>
                                                                        <span>+{
                                                                            // trim_value(props.form_.physics.tel.operator_code)
                                                                            props.form_.physics.tel.tel?
                                                                            trim_value(props.form_.physics.tel.operator_code)+' '+
                                                                            trim_value(props.form_.physics.tel.tel).match(new RegExp('.{1,3}', 'g')).join("-")
                                                                                : ''

                                                                        }</span>
                                    </div>
                            </div>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 24 : 0}}>


                                    <span style={{fontWeight: '500',  color: '#6D6E71', }}>{glv('account.third_step.physics.email' )}{': '}</span>
                                    <div className={'fake_input'}>   <span>{props.form_.physics.email}</span></div>


                            </div>
                        </div>
                        <div className="step-row " style={{display: "flex" }}>

                            <div className="step-row-field" style={{width:  '100%' , marginTop: matches ? 24 : 0}}>


                                    <span style={{fontWeight: '500',  color: '#6D6E71', }}>{glv('account.third_step.physics.address' )}{': '}</span>
                                    <div className={'fake_input'}>
                                        <span>{props.form_.physics.adress.string_adr}</span>
                                    </div>


                            </div>
                        </div>
                        {/*<div className="step-row text">*/}
                        {/*    <div className="textR_line">*/}
                        {/*        <span style={{fontWeight: '500',  color: '#6D6E71', }}>{glv(`account.third_step.physics.binded_bank`)}</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="step-row " style={{display: "flex",  }}>
                            <div className="step-row-field" style={{width: matches ? '100%' : '16%', marginTop: matches ? 24 : 0, flex:1}}>


                                <span style={{fontWeight: '500',  color: '#6D6E71', }}>{glv('account.third_step.physics.pesel' )}{': '}</span>
                                <div className={'fake_input'}>

                                                                            <span>{
                                                                                props.form_.physics.nal_num
                                                                            }</span>
                                </div>

                            </div>

                            <div className="step-row-field" style={{width: matches ? '100%' : '78%', marginTop: matches ? 24 : 0, flex: 2}}>


                                <span style={{fontWeight: '500',  color: '#6D6E71', }}>
                                    {/*{props.form_.physics.binded_bank.name}*/}
                                    {glv('account.third_step.physics.card_num' )}
                                    {': '}
                                </span>
                                <div className={'fake_input'} style={{display: 'flex', height: 48, padding: 0, alignItems: 'center'}}>
                                    <div
                                        style={{
                                            // borderRight: '1px solid rgb(238,238,238)',
                                            width: 48,
                                            height: 48,
                                            borderRadius:'2px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                        <img src={props.form_.physics.binded_bank.logo_img}
                                             style={{width: '55%', height: '55%',borderRadius:'2px'}}/>
                                    </div>
                                    <span style={{marginLeft: 10}}>{props.form_.physics.binded_bank.card_number}</span>
                                </div>


                            </div>
                        </div>
                </div>
                    :
                    <div          style={matches? {}: {
                        display: 'grid',
                        gridTemplateColumns: 'auto',
                        // gridAutoRows: '1fr'
                    }}>
                        <div className="step-row " style={{display: "flex" }}>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 24 : 0}}>


                                    <span style={{fontWeight: '500',  color: '#6D6E71', }}>{glv('account.third_step.juristics.nip' )}{': '}</span>
                                    <div className={'fake_input'}>
                                        <span>{props.form_.juristics.nip}</span>
                                    </div>


                            </div>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 24 : 0}}>


                                    <span style={{fontWeight: '500',  color: '#6D6E71', }}>{glv('account.third_step.juristics.region' )}{': '}</span>
                                    <div className={'fake_input'}>
                                        <span>{props.form_.juristics.reg}</span>
                                    </div>


                            </div>
                        </div>
                        <div className="step-row " style={{display: "flex" }}>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 24 : 0}}>


                                    <span style={{fontWeight: '500',  color: '#6D6E71', }}>{glv('account.third_step.juristics.name' )}{': '}</span>
                                    <div className={'fake_input'}>
                                        <span>{props.form_.juristics.name}</span>
                                    </div>


                            </div>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 24 : 0}}>


                                    <span style={{fontWeight: '500',  color: '#6D6E71', }}>{glv('account.third_step.juristics.address' )}{': '}</span>
                                    <div className={'fake_input'}>
                                        <span>{props.form_.juristics.adress}</span>
                                    </div>


                            </div>
                        </div>
                        <div className="step-row " style={{display: "flex" }}>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 24 : 0}}>


                                    <span style={{fontWeight: '500',  color: '#6D6E71', }}>{glv('account.third_step.juristics.email' )}{': '}</span>
                                    <div className={'fake_input'}>
                                        <span>{props.form_.juristics.email}</span>
                                    </div>


                            </div>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%', marginTop: matches ? 24 : 0}}>


                                    <span style={{fontWeight: '500',  color: '#6D6E71', }}>{glv('account.third_step.juristics.phone' )}{': '}</span>
                                    <div className={'fake_input'}>
                                                                           <span>+{props.form_.juristics.tel_1.tel?
                                                                               trim_value( props.form_.juristics.tel_1.operator_code)+' '+
                                                                               trim_value(props.form_.juristics.tel_1.tel).match(new RegExp('.{1,3}', 'g')).join("-")
                                                                               : ''
                                                                           }</span>

                                    </div>


                            </div>
                        </div>
                    </div>
            }


            <div className="step-row last" style={{marginTop: matches? 24 : 0}}>
                <div className="prevBtn" onClick={() => props.prevStep()}>
                    {
                        glv(`mainStepper.prevStepTitle`)
                    }
                </div>
                <div className="nextBtnWr ">
                    <div className="nextBtn" onClick={() => {
                        // props.nextStep()
                    }}>{glv(`mainStepper.nextStepTitle`)}</div>
                    {/* <input type="submit" className="nextBtn" value={props.lang.mainStepper.nextStepTitle} onClick = {()=>props.nextStep()}/> */}
                </div>
            </div>
        </form>)
}