import React from 'react';
import RadioBtnsComponent from './radio-btns';
import {useInput} from './hooks/useInput';
import Map from './Map';
import {useDispatch, useSelector} from "react-redux";
import * as actions from "./redux/actions/env.actions";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import '@paraboly/react-osm-geocoding/dist/index.css'
import useDebounce from './hooks/useDebounce';
import {glv, steps} from "./App";
import AdresInput from "./AdresInput";
import PhoneInput from "./phone_number_input"
import {global_form, set_field_in_global_form, set_steps} from "./App";
import RadioParaComponent from './radio_btn_para'

import {assocPath} from 'ramda';
import RadioTabComponent from "./RadioParaTabComponent";

const debounce = require('debounce');


function validateEmail(email) {
    var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(String(email).toLowerCase());
}

export function create_dn(element) {
    if (!element) {
        return null
    }
    let new_ = {...element, dn: ''};
    ['road', 'house_number', 'country', 'county', 'state', 'city', 'town', 'neighbourhood', 'postcode'].forEach(item => {
            if (element.address[item]) {
                let dop_symb = ', ';
                if (item === 'road' && element.address['house_number']) {
                    dop_symb = ' ';
                }
                if (item === 'postcode') {
                    dop_symb = '';
                }
                new_.dn += element.address[item] + dop_symb
            }
        }
    );

    return new_
}


export default function FirstAccStep(props) {
    const dispatch = useDispatch();

    const matches = useMediaQuery('(max-width:1025px)');

    const curr_address = useSelector((state) => state.envReducer.curr_address);


    const set_curr_address = React.useCallback((address) => dispatch(actions.set_address(address)), [
        dispatch,
    ]);


    //general

    const [oferty, set_oferty] = React.useState('');

    const [firm_osob, set_firm_osob] = React.useState(false);

    const [show_pub_loading, set_show_pub_loading] = React.useState(false);

    const [city, setCity] = React.useState('');
    const [city_err, set_city_err] = React.useState(false);

    // fiz

    const [male, set_male] = React.useState(true);

    const [phone_number, set_phone_number] = React.useState('');

    const [email_fiz, set_email_fiz] = React.useState('');
    const [email_fiz_error, set_email_fiz_error] = React.useState(false);

    const [address_fe_fiz, set_address_fe_fiz] = React.useState('');
    const [address_fe_fiz_err, set_address_fe_fiz_err] = React.useState(false);

    const [ln_fiz, set_ln_fiz] = React.useState('');
    const [ln_fiz_err, set_ln_fiz_err] = React.useState('');

    const [fn_fiz, set_fn_fiz] = React.useState('');
    const [fn_fiz_err, set_fn_fiz_err] = React.useState('');

    const [comments_fiz, set_comments_fiz] = React.useState('');


    // firm

    const [email_firm, set_email_firm] = React.useState('');
    const [email_firm_error, set_email_firm_error] = React.useState(false);

    const [nipnum, set_nipnum] = React.useState('');
    const [nipnum_err, set_nipnum_err] = React.useState('');

    const [firm_name, set_firm_name] = React.useState('');
    const [firmname_err, set_firmname_err] = React.useState('');


    const [phone_number_firm, set_phone_number_firm] = React.useState('');

    const [phone_number_firm_contact, set_phone_number_firm_contact] = React.useState('');

    const [address_firm, set_address_firm] = React.useState('');
    const [address_firm_err, set_address_firm_err] = React.useState(false);


    const [f_l_name_osob_firm, set_f_l_name_osob_firm] = React.useState('');
    const [f_l_name_osob_firm_err, set_f_l_name_osob_firm_err] = React.useState('');


    // const check_type_client = ()=> {
    //     if (props.form.client.physics.select) {
    //
    //         set_firm_osob(true)
    //         return
    //     }
    //     if (props.form.client.juristic.select) {
    //
    //         set_firm_osob(false)
    //     }
    // }
    // React.useEffect(() => {
    //
    //     check_type_client()
    // }, [props.form]);

    React.useEffect(() => {
        // document.querySelector('')
    }, []);

    function validate_osob_form() {
        let some_error = false;


        if (!city) {
            some_error = true;
            set_city_err(glv(`mainStepper.first_step.inputs.errors.local_req_err`))
        }

        if (phone_number.error) {
            some_error = true;
        }

        if (!email_fiz) {
            some_error = true;
            set_email_fiz_error(glv(`mainStepper.first_step.inputs.errors.local_req_err`))
        }
        if (email_fiz_error) {
            some_error = true;
        }
        if (!address_fe_fiz) {
            some_error = true;
            set_address_fe_fiz_err(glv(`mainStepper.first_step.inputs.errors.local_req_err`))
        }
        if (!ln_fiz) {
            some_error = true;
            set_ln_fiz_err(glv(`mainStepper.first_step.inputs.errors.local_req_err`))
        }
        if (!fn_fiz) {
            some_error = true;
            set_fn_fiz_err(glv(`mainStepper.first_step.inputs.errors.local_req_err`))
        }

        return !some_error
    }

    function validate_firm_form() {
        let some_error = false;
        debugger

        if (!city) {
            some_error = true;
            set_city_err(glv(`mainStepper.first_step.inputs.errors.local_req_err`))
        }

        if (phone_number_firm.error) {
            some_error = true;
        }
        if (phone_number_firm_contact.error) {
            some_error = true;
        }

        if (!email_firm) {
            some_error = true;
            set_email_firm_error(glv(`mainStepper.first_step.inputs.errors.local_req_err`))
        }
        if (email_firm_error) {
            some_error = true;
        }
        if (!address_firm) {
            some_error = true;
            set_address_firm_err(glv(`mainStepper.first_step.inputs.errors.local_req_err`))
        }
        if (!nipnum) {
            some_error = true;
            set_nipnum_err(glv(`mainStepper.first_step.inputs.errors.local_req_err`))
        }
        if (!firm_name) {
            some_error = true;
            set_firmname_err(glv(`mainStepper.first_step.inputs.errors.local_req_err`))
        }

        if (!f_l_name_osob_firm) {
            some_error = true;
            set_f_l_name_osob_firm_err(glv(`mainStepper.first_step.inputs.errors.local_req_err`))
        }
        return !some_error
    }

    function validate_form_before_next_step() {
        switch (firm_osob) {
            case 0:
                return validate_osob_form()
            case 1:
                return validate_firm_form()
            default:
                return false
        }

    }


    return (
        <form className={"container step step1" + props.clas}>


            <div className="step-toptext" style={{marginTop: matches ? 24 : 0}}>

                <div className={matches? "mob":"dect"}>
                    {
                        glv(`account.first_step.description.p1`)===`account.first_step.description.p1` ||
                        glv(`account.first_step.description.p1`)==='' ? null :
                            <span >
                                            {glv(`account.first_step.description.p1`)}

                    </span>
                    }

                    {
                        glv(`account.first_step.description.p2`)===`account.first_step.description.p2` ||
                        glv(`account.first_step.description.p2`)==='' ? null :
                            <span>
                                                              <br/>
                    <br/>
                      {glv(`account.first_step.description.p2`)}

                    </span>
                    }

                    {
                        glv(`account.first_step.description.p3`)===`account.first_step.description.p3` ||
                        glv(`account.first_step.description.p3`)==='' ? null :
                            <span>
                                                                <br/>
                    <br/>
                      {glv(`account.first_step.description.p3`)}
                    </span>
                    }
                </div>
                {/*<div className="mob">*/}
                {/*    {glv(`account.first_step.description.p1`)}*/}
                {/*    <br/>*/}
                {/*    <br/>*/}
                {/*    {glv(`account.first_step.description.p2`)}*/}
                {/*    <br/>*/}
                {/*    <br/>*/}
                {/*    {glv(`account.first_step.description.p3`)}*/}
                {/*</div>*/}
            </div>

            <div style={{
                marginTop: matches ? 24 : 0,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: matches ? 'column' : 'row'
            }}>
                <RadioTabComponent
                    tab_value={props.form_.physics_juristics}
                    set_tab_value={(tab_) => {
                        props.set_form_('set', 'physics_juristics', tab_)
                    }}
                    btn_w={'192px'}
                    wr_style={{marginBottom: 0}}
                    sub_lab={"*"}
                    path_={`mainStepper.first_step.radio_btns_blocks.representation_block.text_values.data.`}
                />
                {props.form_.physics_juristics === null ? null :
                    <div className="step-row last" style={{marginTop: matches ? 24 : 0}}>
                        <div className="nextBtnWr first">
                            <div className="nextBtn" onClick={() => {
                                props.nextStep()
                            }}>{glv(`mainStepper.nextStepTitle`)}</div>
                            {/* <input type="submit" className="nextBtn" value={props.lang.mainStepper.nextStepTitle} onClick = {()=>props.nextStep()}/> */}
                        </div>
                    </div>
                }
            </div>


        </form>)
}