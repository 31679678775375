import React from "react";
import ReactDOM from "react-dom";
import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu";
import CheckIcon from '@material-ui/icons/Check';
import {LeftArrow, RightArrow} from "./arrows";
import {glv} from "./App";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import  imgs from './jsonImgs';
import {_log} from "./services/logger";
// import { Card } from "./card";
// import { Footer } from "./footer";
// import { Header } from "./header";
// import "./globalStyles.css";

// NOTE: embrace power of CSS flexbox!
// https://css-tricks.com/snippets/css/a-guide-to-flexbox/
// import "./arrowsOnBottomOrTop.css";
// import "./hideScrollbar.css";
// import "./firstItemMargin.css";

// type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

function useDrag() {
    const [clicked, setClicked] = React.useState(false);
    const [dragging, setDragging] = React.useState(false);
    const [position, setPosition] = React.useState(0);
    const [diff, setDiff] = React.useState(0);

    const dragStart = React.useCallback((ev) => {
        setPosition(ev.clientX);
        setDiff(0);
        setClicked(true);
    }, []);

    const dragStop = React.useCallback(
        () =>
            window.requestAnimationFrame(() => {
                setDragging(false);
                setClicked(false);
            }),
        []
    );

    const dragMove = React.useCallback(
        (ev, cb) => {
            const newDiff = position - ev.clientX;

            const movedEnough = Math.abs(newDiff) > 5;

            if (clicked && movedEnough) {
                setDragging(true);
            }

            if (dragging && movedEnough) {
                setPosition(ev.clientX);
                setDiff(newDiff);
                cb(newDiff);
            }
        },
        [clicked, dragging, position]
    );

    return {
        dragStart,
        dragStop,
        dragMove,
        diff,
        dragging,
        position,
        setDragging,
        setDiff,
        setPosition
    };
}

const elemPrefix = "test";
const getId = (index) => `${elemPrefix}${index}`;


export function Card({
                         id_card,
                         onClick,
                         value,
                         checked,
                         index,
                         btns_style,
                         isLast,
                         isFirst,
                         matches_1010,
                         text,

                     }) {



    return (
        <div

            title={text}
            className="card"
            role="button"
            tabIndex={0}
            key={"sfggoyhy" + index + value}
            onClick={() => {
                if (id_card==='disabled'){
                    return
                }
                onClick(index);
            }}
            style={{
                opacity: 1,
                color: checked ? '#fff' : btns_style?.color ? btns_style.color : '#000',
                height: '100%',
                border: '1px solid #EEEEEE',


                padding: '14px 34px',
                display: 'flex',
                justifyContent: 'center',
                cursor:id_card==='disabled' ?'default' : 'pointer',
                alignItems: 'center',
                // width: index===0&&card_width===0 ?  'max-content': card_width,
                background:id_card==='disabled' ? '#f6f7f8' : checked ? '#3db9e5' : '#fff',
                // transform: value===index  ? 'rotate(45deg)' : 'rotate(0deg)',
                transition: 'color, background 0.2s',
                flex:1,
                borderRadius: isFirst ? '5px 0px 0px 5px' : isLast ? '0px 5px 5px 0px' : '0px'
            }}
        >
            {checked ? <img src={imgs.check_img} style={{width: 12, height: 12}}/>

                : null}
            <span style={
                {
                    // opacity: value===index? 0.5 : 1,
                    marginLeft: checked ? 10 : 0,
                    fontSize: matches_1010 ? '12px' : '16px',
                    transition: checked ? 'margin-left 0.2s' : 'none',
                }
            }>{value}</span>
        </div>
    );
}


function RadioNumList(props) {
    const matches_1010 = useMediaQuery('(max-width:1025px)');
    let value_ = props.value_;
    // console.log(value_)
    const [w_btns, set_w_btns] = React.useState(0)

    let list_ = props.list.sort((a, b)=> {
        if (a.name.length > b.name.length) {
            return -1; }
        if (a.name.length < b.name.length) {
            return 1; }
        return 0;
    })

    const { dragStart, dragStop, dragMove, dragging } = useDrag();
    const handleDrag = ({ scrollContainer }) => (
        ev
    ) =>
        dragMove(ev, (newPos) => {
            if (scrollContainer.current) {
                const currentScroll = scrollContainer.current.scrollLeft;
                scrollContainer.current.scrollLeft = currentScroll + newPos;
            }
        });
    // _log.add('cards', list_)
    if (!list_.find(item=>item.id===value_)) {
        // console.log(list_, value_, props.rw, props.item_)
    }

    return (
        <>
            <div className="step-row radios" style={{...props.wr_style, }}>
                <div style={{position: 'relative'}}>
                    <ScrollMenu
                        LeftArrow={LeftArrow}
                        RightArrow={RightArrow}
                        onWheel={onWheel}
                        onMouseDown={() => dragStart}
                        onMouseUp={() => dragStop}
                        onMouseMove={handleDrag}
                    >
                        {/*<div itemId={'first_separator'} style={{display: 'none'}}></div>*/}
                        {list_.map((item, index) => {
                            return (
                                <Card
                                    id_card={item.id}

                                    value={item.name}
                                    text={item.text}
                                    index={index} // NOTE: itemId is required for track items
                                    key={item.id}
                                    onClick={() => {
                                        props.selectVariant(item.id)
                                    }}
                                    itemId={index + 'asd'}
                                    checked={value_ === item.id}
                                    isFirst={index === 0}
                                    isLast={index === props.list.length - 1}
                                    btns_style={props.btns_style}
                                    matches_1010={matches_1010}
                                />
                            )
                        })}
                        {/*<div itemId={'last_separator' } style={{display: 'none'}}></div>*/}
                    </ScrollMenu>
                </div>
            </div>
        </>
    );
}


export default RadioNumList;

function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}