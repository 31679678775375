import './App.css';
import HeaderComponent from './header';
import StepperComponent from './stepper';
import React from 'react';
import appInfo from "../package.json";
import {useEffect} from 'react';
import imgs from './jsonImgs';
import {assocPath, dissocPath, drop, remove, pathOr, path} from "ramda";
import sha1 from "sha1";
import moment from "moment";
import Cookies from "js-cookie";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "./redux/actions/env.actions";
import AppRouter from "./router";
import {Router} from "react-router-dom";
import {createBrowserHistory} from "history";
import ReactPhoneInput from "react-phone-input-2";
import PhoneInput from 'react-phone-number-input';

import 'react-phone-input-2/lib/style.css'
import CustomImageLoader from "react-custom-image-loader.";
import Backdrop from "@material-ui/core/Backdrop";
import MainService from "./services/main_service";
import {_log} from "./services/logger";
import {formatDate} from "./helpers";
import {set_all_vals} from "./temp_tree";


export const ContextConfirmModal = React.createContext();

export const Offer_User_Context = React.createContext();

export const Watter_Data_Context = React.createContext();

export const Create_Offer_Context = React.createContext();
// Type water and perfomance context
// штукм которые выбираются на второй страниице в двух первых инпутах

export const TPW_Context = React.createContext();

export let _log_ = null;
const history = createBrowserHistory();

export let release_build = true;

export let debug_ta = localStorage.getItem('debug_ta');

export let steps = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
}

export let set_steps = (steps_) => {
    steps = {...steps_}
}

window.set_debug_ta = (tip)=> {
    localStorage.setItem('debug_ta', tip);
}

export let _steps_ = [
    {status: 0, name: 'first'},
    {status: 0, name: 'second'},
    {status: 0, name: 'third'},
    {status: 0, name: 'fourth'},
    {status: 0, name: 'fiveth'},
    {status: 0, name: 'sixth'},
    {status: 0, name: 'seventh'},

]

export let _set_steps_ = (steps_) => {
    _steps_ = [...steps_]
}


export let routes = ['/create-offer', '/view-offer', '/user-page'];

export let route_paths = {
    '/create-offer': "main_router_modal.create_page_route",
    '/view-offer': "main_router_modal.view_page_route",
    '/user-page': "main_router_modal.user_page_route"
}




export let lang_arr = null;

export let set_field_in_global_form = (new_form) => {
    // global_form = {...new_form}
}


export let localization_tree = {}


export let env_urls = null;

export let set_env_urls = (urls) => {
    env_urls = {...urls}
}

export let env_url = '';

let langConf = null;

export function glv(path_local, type = 'str', can_send_log = false) {
    let parsed_path = path_local.split('.');
    let path_value = path(parsed_path, localization_tree);
    let error_r = false;

    if (path_value === undefined) {

        switch (type) {
            case 'arr':
                path_value = [];
                break;
            case  'obj':
                path_value = {};
                break;
            case  'str':
                path_value = path_local;
                break;
            default :
                path_value = "NaN";
        }

        error_r = true;

    } else {
        if (type === 'arr' && !Array.isArray(path_value)) {
            error_r = true;
            path_value = [];
        } else if (type === 'obj' && path_value.constructor !== Object) {
            error_r = true;
            path_value = {};
        } else if (type === 'str' && path_value.constructor !== String) {
            error_r = true;
            path_value = path_local;
        }

    }

    if (!can_send_log && error_r) {
        // console.log(path_local,path_value)
        setlogos(
            {description: 'по заданному пути нет значения', path: path_local, type: type},
            'ошибка при попытке получить языковое значение по заданному пути'
        );
    }


    return path_value;
}

export const setlogos = (error, point) => {
    let login = Cookies.get("hash_key", {domain: ".bregus.eu"});


    let errforLog = window.constructor_error(moment().unix(), login ? login : "", point, error)


    let logos = JSON.parse(localStorage.getItem("logos"));

    logos.arr.push(errforLog);
    localStorage.setItem(
        "logos",
        JSON.stringify({key: sha1(logos.arr), arr: logos.arr})
    );
};


window.onerror = function myErrorHandler(errorMsg, url, lineNumber) {
    setlogos({msg: errorMsg, url, lineNumber}, 'global error')
}





export function create_template(temp_name) {
    switch (temp_name) {
        case 'fiz': {
            return {
                "tel": {
                    "country_code": "",
                    "operator_code": "",
                    "tel": ""
                },
                name: '',
                sname: '',
                email: '',
                "address": '',
                comment: '',
                "instalation_adr": {
                    "coordinate": {
                        "lat": 0,
                        "lon": 0
                    },
                    "coordinate_arr": {
                        "country": "",
                        "country_code": "",
                        "region": "",
                        "state_district": "",
                        "city": "",
                        "town": "",
                        "village": "",
                        "house_number": "",
                        "house_name": ""
                    },
                    "string_adr": ""
                }
            }
        }
        case 'jur': {
            return{
                "tel": {
                    "country_code": "",
                    "operator_code": "",
                    "tel": ""
                },
                nip: '',
                company_name: '',
                address: '',
                email: '',
                comment: '',
                contact_name: '',
                "instalation_adr": {
                    "coordinate": {
                        "lat": 0,
                        "lon": 0
                    },
                    "coordinate_arr": {
                        "country": "",
                        "country_code": "",
                        "region": "",
                        "state_district": "",
                        "city": "",
                        "town": "",
                        "village": "",
                        "house_number": "",
                        "house_name": ""
                    },
                    "string_adr": ""
                }
            }
        }
        case 'anal': {
            return {

                where_from_water: null

            }
        }
        case 'watt_par': {
            return {
                water_for: ''
            }
        }
        default: {
            return {

            }
        }
    }
}

let temp_form_for_changing=null

function App() {

    const dispatch = useDispatch();

    const language = useSelector((state) => state.envReducer.language);

    const [all_loaded, set_all_loaded] = React.useState(false);

    const [open_confirm_modal, set_open_confirm_modal] = React.useState(false);

    const set_language = React.useCallback((language) => dispatch(actions.setLanguage(language)), [
        dispatch,
    ]);

    const [first_script_loaded, set_first_script_loaded] = React.useState(false);

    const [offer_acc_data, set_offer_acc_data] = React.useState(null);
    const [offer_acc_data_, set_offer_acc_data_] = React.useState('dsf');

    const [lang, setLang] = React.useState('ru');
    const [langJSON, setLangJSON] = React.useState(null);

    const [watter_data, set_watter_data] = React.useState(null);

    const [tpw, set_tpw] = React.useState({
        type_water: null,
        type_perf_water: null,
        _what_for_water_: null
    });

    const [create_offer_form, set_create_offer_form] = React.useState({
        user_arr: 'asdasd',
        date_crt: '10-12-15',
        doc_id: '',
        type: 'fiz',
        fiz: {
            ...create_template('fiz')
        }
    });
    useEffect(()=>{

       temp_form_for_changing={...create_offer_form}

    }, [create_offer_form])



    window.debug_create_offer_form = (path, value)=> {
        let parsed_path = path.split('.');
        let new_form = assocPath(parsed_path, value,
            {
                ...temp_form_for_changing
            });
        set_create_offer_form(
            {...new_form}
        )
    }
    const change_form_state =React.useCallback((modificator, path_, value, opt_new_form )=> {
        // temp_form_for_changing = {...create_offer_form}
        // console.log(
        //     temp_form_for_changing,
        //     modificator,
        //     path_
        //     // new_form,
        //     // parsed_path
        // )
        switch (modificator){

            case 'g':{

                set_create_offer_form(
                    {...value}
                )
                temp_form_for_changing=   {...value}
                return {...value}
            }
            case 'l1':{
                let parsed_path = path_.split('.');
                let new_form = assocPath(parsed_path, value,
                    {
                        ...temp_form_for_changing
                    });

                set_create_offer_form(
                    {...new_form}
                )
                temp_form_for_changing=   {...new_form};
                return new_form
            }
            case 'fiz_jur':{
                //

                let parsed_path = path_ ?
                    [temp_form_for_changing?.type ??'fiz'].concat(path_.split('.')) : [temp_form_for_changing?.type?? 'fiz'] ;

                let new_form = assocPath(parsed_path, value,
                     {
                        ...temp_form_for_changing
                    });
                // console.log(new_form)
                set_create_offer_form({...new_form})
                temp_form_for_changing=   {...new_form}
                return new_form
            }
            case 'fiz_jur_del':{
                // console.log(create_offer_form)
                let new_form = dissocPath([path_],
               {
                        ...temp_form_for_changing
                    });
                // console.log(new_form)
                set_create_offer_form({...new_form})
                temp_form_for_changing=   {...new_form}
                return new_form
            }
            case 'anal':{
                let parsed_path = path_ ?
                    ['anal'].concat(path_.split('.')) : ['anal']
                let new_form = assocPath(parsed_path, value,
                 {
                        ...temp_form_for_changing
                    });
                set_create_offer_form({...new_form})
                temp_form_for_changing=   {...new_form}
                return new_form
            }
            case 'anal_del':{
                let parsed_path = path_ ?
                    ['anal'].concat(path_.split('.')) : ['anal']

                let new_form = dissocPath(parsed_path,
                    {
                        ...temp_form_for_changing
                    });

                set_create_offer_form({...new_form})
                temp_form_for_changing=   {...new_form}
                return new_form
            }
            case 'watt_par':{
                let parsed_path = path_ ?
                    ['watt_par'].concat(path_.split('.')) : ['watt_par']
                let new_form = assocPath(parsed_path, value,
                  {
                        ...temp_form_for_changing
                    });
                set_create_offer_form({...new_form})
                temp_form_for_changing=   {...new_form}
                return new_form
            }
        }
    }, [create_offer_form, set_create_offer_form, temp_form_for_changing])

    const handle_change_tpw = (name, value)=> {
        set_tpw({...tpw, [name]: value})
    }

    const soa = (data)=> {

        set_offer_acc_data(data)
        set_offer_acc_data_('data')
    }

    function logosTapok() {
        try {
            if (!env_urls) {
                logosTapok()
                return;
            }
            setTimeout(function run() {
                let logos = JSON.parse(localStorage.getItem('logos'));
                if (!logos) {
                    localStorage.setItem('logos', JSON.stringify({key: '', arr: []}));
                    return;
                }
                if (navigator.onLine && logos.key) {
                    MainService.logosErr(logos)
                    localStorage.setItem('logos', JSON.stringify({key: '', arr: []}));
                }
                setTimeout(run, 10000);
            }, 1);
        } catch (e) {
            console.log(e, 'ошибка при отправке логов')
        }
    }

    function getLangConf() {

        if (!env_urls) {
            getLangConf()
            return;
        }
        fetch(`${process.env.PUBLIC_URL}/json/lang/conf.json`)
            .then((data) => data.json())
            .then((res) => {
                if (!lang_arr) {
                    lang_arr = Object.keys(res.language_arr);
                }
                let lang = Cookies.get("language", {domain: "bregus.eu"});
                let isValidLang = Object.keys(res.language_arr).find(
                    (item) => item === lang
                );
                langConf = res;
                let ln = lang && isValidLang ? lang : res.main_language;
                window.ln = ln;
                set_language(ln);
                change_form_state('fiz_jur','tel.country_code', ln,  )
            });
    }


    function getLangTree() {

        if (!env_urls || !langConf || !language || !first_script_loaded



        ) {
            // console.log('eee',
            //     env_urls ,langConf ,language ,first_script_loaded,offer_acc_data
            //     )
            getLangTree()
            return;
        }
        let url = `${release_build ? process.env.PUBLIC_URL : 'https://offer.bregus.eu'}${langConf.language_arr[language].language_url}`;
        MainService.getAppRequest(url)
            .then((res) => {
                let tree = window.parser_lang_tree(res, dissocPath, assocPath);
                localization_tree = {...tree};
                set_all_loaded(true)
                setLangJSON(localization_tree)
                // let switches_names = [
                //     "color_iron",
                //     "microlog",
                //     "route_tank",
                //     "spoke",
                //     "has_acc",
                //     "has_add_cons",
                //     "know_pres_watt",
                //     "has_paramoka",
                //     "has_filter",
                //     "has_launddress",
                //     "hor_ver",
                //     "watering",
                //     "contin_cons_water"
                // ]
                //
                // let obj  =  {
                //
                // }
                // let asd = {
                //     hor_ver:  ["horizont", "vertikal"],
                //     route_tank:  ["глубинный", "повернхностный"]
                // }
                // switches_names.map((item)=> {
                //     obj[item] = {
                //         "id_info": {
                //             "name": "any array",
                //                 "type": "str_arr",
                //                 "template": null
                //         },
                //         "data":asd[item] ?asd[item] : [
                //             "Да",
                //             "Нет"
                //         ],
                //         "help": "Arrayu"
                //     }
                // })
                // console.log(JSON.stringify(obj))

                // 'mainStepper.third_step.switches.route_tank.data.'
                set_all_vals(
                    {
                        has_acc: {
                            name: 'has_acc',
                            "type": "switch",
                            "scale": false
                        },

                        has_add_cons:{
                            name: 'has_add_cons',
                            "type": "switch",
                            "scale": false
                        },
                        has_paramoka:{
                            name: 'has_paramoka',
                            "type": "switch",
                            "scale": false
                        },
                        know_pres_watt: {
                            name: 'know_pres_watt',
                            "type": "switch",
                            "scale": false
                        },
                        has_filter: {
                            name: 'has_filter',
                            "type": "switch",
                            "scale": false
                        },
                        has_launddress: {
                            name: 'has_launddress',
                            "type": "switch",
                            "scale": false
                        },
                        hor_ver: {
                            name: 'hor_ver',
                            "type": "switch",
                            "scale": false
                        },

                        watering: {
                            name: 'watering',
                            "type": "switch",
                            "scale": false
                        },
                        contin_cons_water: {
                            name: 'contin_cons_water',
                            "type": "switch",
                            "scale": false
                        },
                        type_manip_pump: {
                            type: 'select',
                            label: glv('mainStepper.second_step.legends.type_manip_pump.title'),
                            options: [
                                {id: 'switchmat', text: glv('mainStepper.third_step.selects.type_manip_pump.switchmat')},
                                {id: 'chastot', text: glv('mainStepper.third_step.selects.type_manip_pump.chastot')},
                                {id: 'without_manipulating', text: glv('mainStepper.third_step.selects.type_manip_pump.without_manipulating')},
                            ]
                        },
                        count_consumption_taps: {
                            type: 'select',
                            options: glv('mainStepper.third_step.selects.count_consumption_taps.data', 'arr').map((item, index)=>({id:index, text: item}))
                        },
                        count_peoples: {
                            type: 'select',
                            options: glv('mainStepper.third_step.selects.count_peoples.data', 'arr').map((item, index)=>({id:index, text: item}))
                        },
                        sewerage_select: {
                            type: 'select',
                            options: glv('mainStepper.third_step.selects.sewerage_select.data', 'arr').map((item, index)=>({id:index, text: item}))
                        },
                        count_bathrooms: {
                            type: 'select',
                            options: glv('mainStepper.third_step.selects.count_bathrooms.data', 'arr').map((item, index)=>({id:index, text: item}))
                        },
                        additional_consumption: {
                            type: 'select',
                            options: glv('mainStepper.third_step.selects.additional_consumption.data', 'arr').map((item, index)=>({id:index, text: item}))
                        },
                        acc_type: {
                            type: 'select',
                            options: glv('mainStepper.third_step.selects.acc_type.data', 'arr').map((item, index)=>({id:index, text: item}))
                        },
                        interruption_water: {
                            type: 'select',
                            options: glv('mainStepper.third_step.selects.interruption_water.data', 'arr').map((item, index)=>({id:index, text: item}))
                        },
                        inlet_pipe_diameter: {
                            type: 'select',
                            options: glv('mainStepper.third_step.selects.inlet_pipe_diameter.data', 'arr').map((item, index)=>({id:index, text: item}))
                        },

                        count_numbers: {
                            type: 'select',
                            options: glv('mainStepper.third_step.selects.count_numbers.data', 'arr').map((item, index)=>({id:index, text: item}))
                        },
                        count_cons_taps_simultaneously: {
                            type: 'select',
                            options: glv('mainStepper.third_step.selects.count_cons_taps_simultaneously.data', 'arr').map((item, index)=>({id:index, text: item}))
                        },
                        count_laundresses: {
                            type: 'select',
                            options: glv('mainStepper.third_step.selects.count_laundresses.data', 'arr').map((item, index)=>({id:index, text: item}))
                        },
                    }
                )
            })
    }

    useEffect(() => {
        env_url = process.env.PUBLIC_URL;

        const LoadExternalScript = () => {
            const externalScript = document.createElement("script");
            externalScript.onerror = () => {
            };
            externalScript.async = true;
            document.body.appendChild(externalScript);
            externalScript.src = `https://bregus.eu/js_pugins/requestor.js`;
            externalScript.onload = () => {
                // console.log('true')
                set_first_script_loaded(true)

            }
        };
        LoadExternalScript();


    }, [])

    const check_acc_first_load = ()=> {
        MainService.get_first_load().then((res)=> {
            if(res.code==200) {
                let acc_data = res.info.tree.offer_user;
                if (!acc_data.id||acc_data.type==='first_load') {
                    if (window.location.pathname.length> 1 || window.location.hash.length > 0) {
                        window.location.href='/';
                        return
                    }
                }


                if (window.location.pathname!== '/user-page'&&
                    window.location.pathname!== '/create-offer'&&
                    window.location.pathname!== '/view-offer'&&
                    window.location.pathname!== '/'
        ) {
                        window.location.href='/';
                        // history.push('/') ;
                        return
                }
                set_create_offer_form({
                    ...create_offer_form,
                    date_crt: formatDate(new Date(), 'mm-dd-yy'),
                    user_arr:  res.info.tree.offer_user.id+' - ' + res.info.tree.offer_user.akk_data.name +' '+res.info.tree.offer_user.akk_data.sname
                })

                soa({...res.info.tree.offer_user,
                    sessions: res.info.tree.sessions,
                    date: res.info.tree.global_user.date.user_crt })
                // console.log(res.info.tree.offer_user)
            }


        })
    }

    useEffect(() => {
        if (!first_script_loaded) {
            return
        }
        let request = new XMLHttpRequest();
        request.open('GET', `${process.env.PUBLIC_URL}/json/conf.json`);
        request.responseType = 'json';
        request.send();
        request.onload = function () {
            let res = request.response;
            let new_urls = {
                api_key:release_build ?  res.keys.apiKey : 'bbse8tftxg4rn2w1',
                api_url: res.url.api,
                login: res.url.login,
                api_home: res.url.home,
                timepoints: res.timepoints,
            };
            set_env_urls({...new_urls})
            check_acc_first_load();
            logosTapok();
            getLangConf();
        }
        request.onerror = function () {
            alert('Недостаточно файлов на сервере, запрос на директорию главного конфига не получил данные')
        }

    }, [first_script_loaded]);

    useEffect(() => {
        if (language) {
            getLangTree()
        }
    }, [language]);

    return all_loaded&& offer_acc_data ? (
            <Create_Offer_Context.Provider value={[create_offer_form, change_form_state]} >
            <TPW_Context.Provider value={[tpw, handle_change_tpw]} >
        <Watter_Data_Context.Provider value={[watter_data, set_watter_data]} >
            <Offer_User_Context.Provider value={[offer_acc_data, set_offer_acc_data]}>
        <ContextConfirmModal.Provider value={[open_confirm_modal, set_open_confirm_modal]}>
        <Router history={history}>
            <div>


                <AppRouter lang_={langJSON}/>


                <div style={{
                    position: 'absolute',
                    bottom: 5,
                    left: 3,
                    color: '#3db9e5',
                    zIndex: 1301
                }}>   {appInfo.version}</div>

                <img src={imgs.img_a} alt="" className="leftImg"/>
                <img src={imgs.img_b} alt="" className="left_bottom_Img_bg"/>
                <img src={imgs.img_c} alt="" className="bottom_Img_bg"/>
                <img src={imgs.img_d} alt="" className="right_bottom_Img_bg"/>
                <img src={imgs.img_e} alt="" className="rightImg"/>
            </div>
        </Router>
        </ContextConfirmModal.Provider>
            </Offer_User_Context.Provider>
        </Watter_Data_Context.Provider>
            </TPW_Context.Provider>
            </Create_Offer_Context.Provider>

    ) : <Backdrop
        open={true} style={{
        zIndex: 100000,
        color: "#fff",
    }}>
        <CustomImageLoader
            image={`${process.env.PUBLIC_URL}/images/icon.svg`}
            isLoaded={true}
            circle={false}
            speed={2}
            animationType={"pulse"}
        />
    </Backdrop>;
}

export default App;
