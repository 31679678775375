import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export function ErrorText(props) {
    const matches = useMediaQuery('(max-width:1025px)');
    return  <div style={{
        position: 'absolute',
        top: matches ? 45 : 20,
        fontSize: 13,
        left: 10,
        padding: 2,
        background: 'white',
        color: '#e00052',
        display: props.err ? 'block' : 'none'
    }}> {
        props.err
    }
    </div>
}