import React, { useEffect, useCallback, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, Divider, TextField } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { NavLink } from 'react-router-dom';
import SelectComponent from "./custom_select";
import * as actions from "./redux/actions/env.actions";
import {glv, Offer_User_Context, routes, route_paths} from "./App"
import {lang_arr} from "./App";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: 0,
        paddingRight: 0,
    },

    fromToLab: {
        marginTop: 20,
    },
    highlight: {
        color: "white",
        backgroundColor: "#000",
    },
    title: {
        flex: "1 1 100%",
    },
    dI: {
        backgroundColor: "#ff6",
    },

    formControl: {
        marginTop: 10,
        width: "100%",
    },

    globalHelpLabel: {
        marginTop: 10,
        textAlign: "center",
        width: "100%",
        // fontWeight: 'bold'
        paddingBottom: 5,
        borderBottom: "rgba(0,0,0,0.1) solid",
    },

    simpleHelp: {
        marginTop: 5,
        textAlign: "center",
        color: "#c2c2c2",
        width: "100%",
    },

    titleLabel: {
        marginTop: 10,
        textAlign: "center",
        fontWeight: "bold",
        paddingBottom: 5,
        width: "100%",
        borderBottom: "rgba(0,0,0,0.1) solid",
    },
    okBtn: {
        background: "#5E5AB9",
        // boxShadow: '-9px -9px 16px rgba(255, 255, 255, 0.6), 4px 4px 8px rgba(63, 60, 255, 0.12)',
        borderRadius: 12,
        color: "white",
        fontFamily: "gilroy",
        fontWeight: 600,
        width: "43%",
        marginRight: 10,
        marginBottom: 15,
        "&:hover": {
            background: "#5e5ab9",
            boxShadow: "none",
        },
    },
    scrPap: {
        maxHeight: "none",
    },
    cancBtn: {
        marginBottom: 15,
        fontWeight: 600,
        width: "43%",
        background: "linear-gradient(0deg, #cfddfc, #E3E9F7)",
        // boxShadow: '-9px -9px 24px rgba(255, 255, 255, 0.570094), 20px 22px 40px rgba(0, 0, 0, 0.0826595)',
        opacity: 0.7,
        borderRadius: 12,
        marginLeft: 10,
        color: "#2F2A61",
        fontFamily: "gilroy",
        marginRight: 10,
        "&:hover": {
            // background: 'linear-gradient(0deg, #cfddfc, #DFE6F6)',
            // boxShadow:' inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)',
        },
        "&:active": {},
    },
}));


let ts_acc = {
    individual: 'fiz',
    entity: 'jur'
}



const RouterModal = ({ open, cancel, devId, firm }) => {
    const dispatch = useDispatch();
    const confUser = useSelector((state) => state.envReducer.confUser);
    const classes = useStyles();
    // const { addToast } = useToasts();
    // const confirm = useConfirm();
    const matches = useMediaQuery("(max-width:1050px)");

    const [offer_acc_data, set_offer_acc_data] = React.useContext(Offer_User_Context)

    const localization = useSelector((state) => state.envReducer.lang_values);
    // const [idPosition, setIdPosition] = React.useState(null);
    // const [devData, setDevData] = React.useState(null);
    // const [infoDevData, setInfoDevData] = React.useState(null);
    // const [devDataCopy, setDevDataCopy] = React.useState(null);
    // const hash = useSelector((state) => state.envReducer.hash);
    // const [groups, setGroups] = React.useState(null);
    // const urls = useSelector((state) => state.envReducer.urls);
    // const setUrls = useCallback((status) => dispatch(actions.setUrls(status)), [
    //     dispatch,
    // ]);

    const language = useSelector((state) => state.envReducer.language);

    const set_language = React.useCallback((language) => dispatch(actions.setLanguage(language)), [
        dispatch,
    ]);

    const [value, setValue] = React.useState(null);

    // const handleChange = (event) => {
    //     setValue(event.target.value);
    // };


    // const setConfUser = useCallback(
    //     (conf) => dispatch(actions.setConfUser(conf)),
    //     [dispatch]
    // );
    // const { addToast } = useToasts();
    const [accUser, setAccUser] = React.useState([]);
    const [active_route, set_active_route] = React.useState(false);


    const getData = async () => {
        // let url = "";
        // if (moment.unix(urls.timepoints.end).isBefore()) {
        //     await ErrorService.tapConf(urls, setConfUser, setUrls).then((res) => {
        //         url = `${res.url.api}_${res.keys.apiKey}`;
        //     });
        // } else {
        //     url = `${urls.api_url}_${urls.api_key}`;
        // }
        //
        // await DevicesService.getFirmWareData((data)=>setUrls(data), hash, urls.api_url, urls.api_key, hash)
        //     .then((res) => {
        //         if (res.code === 200) {
        //             debugger
        //             setDevData(res.info.list)
        //             // setInfoDevData({title:accordion, value: tree[Object.keys(tree)[0]].values.alarms, units: '', colorText: '#000', show: row.values.alarms > 0})
        //         }
        //     })
        //     .catch((err) => {
        //
        //     });
    };


    useEffect(() => {
        document.title =glv('document_titles.main_title');
    }, []);


    return  (
        <div style={{position: 'relative'}}>
            <div style={{position: 'fixed', right: matches ? 5 : '15px', top:matches ? 8 :  '15px', zIndex: 1301 , borderRadius: '2px'}}>
                <SelectComponent
                    options={lang_arr}
                    def_value={lang_arr.findIndex((item)=>language===item)}
                    style_wsb={{width:'80px',  background: 'white', borderRadius: '2px'}}
                    style_ws={{paddingRight:'0px', paddingTop:0, paddingBottom:5, paddingLeft:10, border: matches ? '1px solid #d9d9d9' : 'none'}}
                    style_svg={{width:'11px', height: '11px', top:'8px', right:10}}
                    changeVal={(val)=>{
                        // setCurrLang(['ru', 'en', 'pl', 'uk'][val])
                        set_language(lang_arr[val])
                    }}
                />
            </div>

        <Dialog
            fullScreen = {matches? true : false}
            fullWidth
            maxWidth={"sm"}
            open={true}
            // onClose={handleClose}
            classes={{ paperScrollPaper: classes.scrPap }}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={{
                style: {

                    background: "#ffffff",
                    // boxShadow:'-5px -5px 7px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(23, 23, 23, 0.12)',
                    borderRadius: matches ? 0 : 5,
                    maxWidth: matches ? '100%' : "50%",
                    minWidth: "200px",
                    // height: "80%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflow: "hidden",
                },
            }}
            style={{}}
        >


            <div>
                <DialogTitle
                    style={{ cursor: "move", textAlign: "center", borderBottom: '2px solid #d9d9d9',        marginTop: matches ? 25 :0, }}
                    id="draggable-dialog-title"
                >
                    { glv("main_router_modal.title")}
                    {/*{glv(`devicesPage.dev_list.frames.fireware.header`)}*/}
                </DialogTitle>

            </div>

            <div style={{display: 'flex', flexDirection: 'column', margin: '0px 20px', }}>
                {
                    routes.map((route)=> (
                        <NavLink to={route} style={{textDecoration: 'none', }}>
                            <button
                                onMouseDown={()=>set_active_route(route)}
                                onMouseUp={()=>set_active_route(false)}
                                style={{
                                    width: '100%',
                                    padding: '13px 20px',
                                    outline: 'none',
                                    border: '1px solid #3db9e5',
                                    color: active_route===route ? '#ffffff' : '#3db9e5',
                                    background: active_route===route ? '#3db9e5':'#ffffff',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    borderRadius: 5,
                                    marginTop: 24,
                                    fontSize: 16,
                                    transition: 'all 0.2s',
                                }}>
                                { glv(route_paths[route])}
                            </button>
                        </NavLink>
                    ))
                }

            </div>
            {/* <AppBar position="fixed"  style={{   top: 'auto', bottom: 0,}}> */}
            <DialogActions style={{ marginTop: 20 }}>

            </DialogActions>
            {/* </AppBar> */}
        </Dialog>
        </div>
    );
};

export default RouterModal;
