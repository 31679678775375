import React, {useEffect, useCallback, useState} from "react";
import Dialog from "@material-ui/core/Dialog";

import {InlineShareButtons} from 'sharethis-reactjs';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useDispatch, useSelector} from "react-redux";
import {fade, makeStyles, withStyles} from "@material-ui/core/styles";
import {Button, Divider, TextField} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Fade from '@mui/material/Fade';
import CloseIcon from '@material-ui/icons/Close';
import {NavLink} from 'react-router-dom';
import SelectComponent from "./custom_select";
import * as actions from "./redux/actions/env.actions";
import {glv} from "./App"
import {lang_arr} from "./App";

function PaperComponent(props) {
    return (
        // <Draggable
        //     handle="#draggable-dialog-title"
        //     cancel={'[class*="MuiDialogContent-root"]'}
        // >
            <Paper {...props} />
        // </Draggable>
    );
}

const ExpireCopiedModal = props => {

    useEffect(() => {
        setTimeout(() => {
            props.close()
        }, props.delay);
    }, [props.delay]);

    return (
        <Fade in={true}>
            <div
                className={'que_modal'}
                style={{

                    position: 'absolute',
                    top: props.pos.top,
                    left: props.pos.left,
                    zIndex: 9646846,
                    marginLeft: 16,
                    maxWidth: '100%',
                    color: '#6D6E71'
                }}
            >{glv('user_page.share_modal.copied_text')}
            </div>


        </Fade>
    )


};


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: 0,
        paddingRight: 0,
    },

    fromToLab: {
        marginTop: 20,
    },
    highlight: {
        color: "white",
        backgroundColor: "#000",
    },
    title: {
        flex: "1 1 100%",
    },
    dI: {
        backgroundColor: "#ff6",
    },

    formControl: {
        marginTop: 10,
        width: "100%",
    },

    globalHelpLabel: {
        marginTop: 10,
        textAlign: "center",
        width: "100%",
        // fontWeight: 'bold'
        paddingBottom: 5,
        borderBottom: "rgba(0,0,0,0.1) solid",
    },

    simpleHelp: {
        marginTop: 5,
        textAlign: "center",
        color: "#c2c2c2",
        width: "100%",
    },

    titleLabel: {
        marginTop: 10,
        textAlign: "center",
        fontWeight: "bold",
        paddingBottom: 5,
        width: "100%",
        borderBottom: "rgba(0,0,0,0.1) solid",
    },
    okBtn: {
        background: "#5E5AB9",
        // boxShadow: '-9px -9px 16px rgba(255, 255, 255, 0.6), 4px 4px 8px rgba(63, 60, 255, 0.12)',
        borderRadius: 12,
        color: "white",
        fontFamily: "gilroy",
        fontWeight: 600,
        width: "43%",
        marginRight: 10,
        marginBottom: 15,
        "&:hover": {
            background: "#5e5ab9",
            boxShadow: "none",
        },
    },
    scrPap: {
        maxHeight: "none",
    },
    cancBtn: {
        marginBottom: 15,
        fontWeight: 600,
        width: "43%",
        background: "linear-gradient(0deg, #cfddfc, #E3E9F7)",
        // boxShadow: '-9px -9px 24px rgba(255, 255, 255, 0.570094), 20px 22px 40px rgba(0, 0, 0, 0.0826595)',
        opacity: 0.7,
        borderRadius: 12,
        marginLeft: 10,
        color: "#2F2A61",
        fontFamily: "gilroy",
        marginRight: 10,
        "&:hover": {
            // background: 'linear-gradient(0deg, #cfddfc, #DFE6F6)',
            // boxShadow:' inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)',
        },
        "&:active": {},
    },
}));


const ShareModal = (props) => {
    const dispatch = useDispatch();
    const confUser = useSelector((state) => state.envReducer.confUser);
    const classes = useStyles();

    const matches = useMediaQuery("(max-width:1050px)");
    const matches_500 = useMediaQuery("(max-width:550px)");

    const [copied_mouse_pos, set_copied_mouse_pos] = React.useState(null);


    const [accUser, setAccUser] = React.useState([]);
    const [active_route, set_active_route] = React.useState(false);

    useEffect(() => {
        document.title = glv('document_titles.main_title');
    }, []);

    const handle_copy_to_buffer = (e)=> {
        console.log(e)
        navigator.clipboard.writeText(props.qr_link).then(function() {
            set_copied_mouse_pos(true)
        }, function() {
            /* clipboard write failed */
        });

    }

    const handle_close_exp_modal = (e)=> {
        set_copied_mouse_pos(null)
    }


    return (


        <Dialog
            fullScreen={matches ? true : false}
            fullWidth
            maxWidth={"sm"}
            open={props.open}
            onClose={props.close}
            classes={{paperScrollPaper: classes.scrPap}}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={{
                style: {

                    background: "#ffffff",
                    // boxShadow:'-5px -5px 7px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(23, 23, 23, 0.12)',
                    borderRadius: matches ? 0 : 5,
                    maxWidth: matches ? '100%' : "532px",
                    minWidth: "200px",
                    // height: "80%",
                    display: "flex",
                    // flexDirection: "column",
                    // justifyContent: "space-between",
                    overflow: "hidden",
                },
            }}
            style={{}}
        >




            <div style={
                matches ?
                    {
                        textAlign: "center",
                        borderBottom: matches ?  '2px solid #d9d9d9' : '',
                        // marginTop: matches ? 25 : 0,
                        display: 'flex', width: '100%',
                        justifyContent: 'space-between',
                        alignItems:'center'
                    } : {}
            }>
                {
                    matches ?     <div></div> : null
                }

                <DialogTitle
                    style={{
                        textAlign: "center", borderBottom: matches ? '' : '2px solid #d9d9d9',
                    }}
                    id="draggable-dialog-title"
                >
                    {glv("user_page.share_modal.title")}
                </DialogTitle>
                {
                    matches ?
                        <CloseIcon

                            onClick={()=>{
                                props.close()
                            }} style={{marginRight: 10, cursor: 'pointer'}}/> : null

                }

            </div>
            {/*<div>*/}
            {/*    <DialogTitle*/}
            {/*        style={{*/}
            {/*            cursor: "move",*/}
            {/*            textAlign: "center",*/}
            {/*            borderBottom: '2px solid #d9d9d9',*/}
            {/*            marginTop: matches ? 25 : 0,*/}
            {/*        }}*/}
            {/*        id="draggable-dialog-title"*/}
            {/*    >*/}
            {/*        */}
            {/*        /!*{glv(`devicesPage.dev_list.frames.fireware.header`)}*!/*/}
            {/*    </DialogTitle>*/}

            {/*</div>*/}

      <div style={{margin: '0px 16px'}}>
            <div className={'wrap_qr_img'} style={{marginTop: 16}}>
                <div className={"wrapper_qr_block"}>
                    <div className={"qr_block"}>
                        <img src={props.qr_link}
                             style={{
                                 width: '100%',
                                 height: '100%',
                                 border: '1px solid #d9d9d9',
                                 borderRadius: 5
                             }}/>
                    </div>
                </div>
            </div>



            <div style={{display: 'flex', justifyContent: 'center',   }}>

                <div style={{    position: 'relative',                   maxWidth: matches_500?  '100%' : '500px',
                   }}>

                    {
                        copied_mouse_pos ?
                            <ExpireCopiedModal delay={'1000'} close={handle_close_exp_modal} pos={{top: -10, left:0}} />
                            :null
                    }
                <div
                    onClick={handle_copy_to_buffer}
                    className={'modal_qr_link'}
                    style={{
                    maxWidth: matches_500?  '100%' : '500px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign:'center',
                    whiteSpace: 'nowrap',
                    color:'#3DB9E5',
                    marginTop: 32,
                        cursor: 'pointer',

                }}>

                <span style={{
                    // maxWidth: '500px',
                }}>{props.qr_link}</span>
                </div>
                </div>
            </div>




            <div style={{margin: '16px 0px', display:'flex', justifyContent: 'center'}}>
                <InlineShareButtons
                    config={{
                        alignment: 'center',  // alignment of buttons (left, center, right)
                        color: 'social',      // set the color of buttons (social, white)
                        enabled: true,        // show/hide buttons (true, false)
                        font_size: 16,        // font size for the buttons
                        labels: 'cta',        // button labels (cta, counts, null)
                        language: 'en',       // which language to use (see LANGUAGES)
                        networks: [           // which networks to include (see SHARING NETWORKS)
                            'whatsapp',
                            'linkedin',
                            'messenger',
                            'facebook',
                            'twitter',
                            "telegram",
                            'blogger',
                            'delicious',
                            'digg',
                            'email',
                            'flipboard',
                            'livejournal',
                            'mailru',
                            'meneame',
                            'oknoklassniki',
                            'pinterest',
                            'print',
                            'reddit',
                            'sharethis',
                            'sms',
                            'stumbleupon',
                            'tumblr',
                            'vk',
                            'wechat',
                            'weibo',
                            'xing'
                        ],
                        padding: 12,          // padding within buttons (INTEGER)
                        radius: 5,            // the corner radius on each button (INTEGER)
                        show_total: true,
                        size: 40,             // the size of each button (INTEGER)

                        // OPTIONAL PARAMETERS
                        url: props.qr_link, // (defaults to current url)
                        image: props.qr_link,  // (defaults to og:image or twitter:image)
                        description: glv('user_page.share_modal.description_post'),       // (defaults to og:description or twitter:description)
                        title: glv('user_page.share_modal.description_post'),            // (defaults to og:title or twitter:title)
                        message: glv('user_page.share_modal.description_post'),     // (only for email sharing)
                        subject: glv('user_page.share_modal.description_post'),  // (only for email sharing)
                        username: '' // (only for twitter sharing)
                    }}
                />
            </div>
      </div>
        </Dialog>

    );
};

export default ShareModal;



