import React, {useCallback, useEffect} from 'react';
import {RowScales} from "./row_scales_component";


const WFrestoran = ({all_v, conds, rest_cond}) => {
    // console.log(rest_cond,conds.known_wat_pr)
    return (
        <>
            <RowScales
                data_scales_array={
                    ["count_consumption_taps", "day_water"]
                        .map((item)=>({...all_v[item], name: item}))
                }
                type_page={'watt_par'}
            />
            <RowScales
                data_scales_array={
                    ["sewerage_select", ""]
                        .map((item)=>({...all_v[item], name: item}))
                }
                type_page={'watt_par'}
            />




            {
                rest_cond==='well' ?
                    <RowScales
                        data_scales_array={
                            ["has_acc", conds.has_acc?  "acc_type" : '']
                                .map((item)=>({...all_v[item], name: item}))
                        }
                        type_page={'watt_par'}
                    /> : null
            }

            {
                conds.has_acc&&rest_cond==='well'?
                    <RowScales
                        data_scales_array={
                            ["volume_tan", "hor_ver" ]
                                .map((item)=>({...all_v[item], name: item}))
                        }
                        type_page={'watt_par'}
                    /> : null
            }


            {
                rest_cond==='plumb' ?
                    <RowScales
                        data_scales_array={
                            ["interruption_water", '']
                                .map((item)=>({...all_v[item], name: item}))
                        }
                        type_page={'watt_par'}
                    /> : null
            }
            {
                rest_cond==='plumb' ?
                    <RowScales
                        data_scales_array={
                            ["know_pres_watt", conds.known_wat_pr?  "water_pres" : '']
                                .map((item)=>({...all_v[item], name: item}))
                        }
                        type_page={'watt_par'}
                    /> : null
            }
            <RowScales
                data_scales_array={
                    ["has_paramoka", "has_filter"]
                        .map((item)=>({...all_v[item], name: item}))
                }
                type_page={'watt_par'}
            />
            <RowScales
                data_scales_array={
                    ["volume_oth", ""]
                        .map((item)=>({...all_v[item], name: item}))
                }
                type_page={'watt_par'}
            />

        </>
    )
}

export default WFrestoran;