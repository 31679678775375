import React, {useState} from 'react';
import RadioBtnsComponent from './radio-btns';
import {useInput} from './hooks/useInput';
import Map from './Map';
import {useDispatch, useSelector} from "react-redux";
import * as actions from "./redux/actions/env.actions";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import '@paraboly/react-osm-geocoding/dist/index.css'
import useDebounce from './hooks/useDebounce';
import {glv, steps} from "./App";
import AdresInput from "./AdresInput";
import PhoneInput from "./phone_number_input"
import {global_form, set_field_in_global_form, set_steps} from "./App";
import RadioParaComponent from './radio_btn_para'
import { useIMask } from 'react-imask';
import {assocPath} from 'ramda';
import BindBankModal from "./bind_bank_modal";
import MaskedInput from 'react-maskedinput'
import { validatePolish } from 'validate-polish';
import { IMaskInput } from 'react-imask';
import MainService from "./services/main_service";
import CustomImageLoader from "react-custom-image-loader.";
import Backdrop from "@material-ui/core/Backdrop";

import  {nip_validate, pesel_validate, validateEmail} from './helpers';
import RadioTabComponent from "./RadioParaTabComponent";



function ErrorText(props) {
    const matches = useMediaQuery('(max-width:1025px)');
    return  <div style={{
        position: 'absolute',
        top: matches ? 45 : 20,
        fontSize: 13,
        left: 10,
        padding: 2,
        background: 'white',
        color: '#e00052',
        display: props.err ? 'block' : 'none'
    }}> {
        props.err
    }
    </div>
}


function prettify(num) {
    var n = num.toString();
    return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ' ');
}



function trim_value(str){
    return str.replace(/\s+/g, '').replace(/[^\d]/g, '').trim()
}

export function create_dn(element) {
    if (!element) {
        return null
    }
    let new_ = {...element, dn: ''};
    ['road', 'house_number', 'country', 'county', 'state', 'city', 'town', 'neighbourhood', 'postcode'].forEach(item => {
            if (element.address[item]) {
                let dop_symb = ', ';
                if (item === 'road' && element.address['house_number']) {
                    dop_symb = ' ';
                }
                if (item === 'postcode') {
                    dop_symb = '';
                }
                new_.dn += element.address[item] + dop_symb
            }
        }
    );

    return new_
}


function IMaskWithHook() {
    const [val, set_val] = useState('');
    const [opts, setOpts] = useState({ mask: '000 000  000' });
    const { ref, maskRef } = useIMask(opts);

    return <input ref={ref} value={val} onChange={()=>set_val(val)}/>;
}

export default function SecondAccStep(props) {
    // console.log(validatePolish)
    const dispatch = useDispatch();

    const matches = useMediaQuery('(max-width:1025px)');

    const curr_address = useSelector((state) => state.envReducer.curr_address);


    const set_curr_address = React.useCallback((address) => dispatch(actions.set_address(address)), [
        dispatch,
    ]);

    const [ opts, setOpts ] = React.useState({ mask: '0000 00 0000 0' });
    const {ref, maskRef } = useIMask(opts);




    //general
    const [is_loading, set_is_loading] = React.useState(false);
    const [banks, set_banks] = React.useState(null);
    const [open_modal_bind_bank, set_open_modal_bind_bank] = React.useState(false);

    const [is_validated, set_is_validated] = React.useState(false);

    React.useEffect(() => {

            fetch('https://offer.bregus.eu/json/banks.json')
                .then((res)=> {
                    return res.json();
                })
                .then((res)=> {
                    if (!res){
                        alert('load banks error')
                        return
                    }

                    // let banks_arr = Object.keys(res).map((key)=>{
                    //     return res[key]
                    // })
                    set_banks(res)

                }).catch((err)=>{
                alert('load banks error')

            })
    }, []);

    const physics_form_validate = () => {
        let err=false;
        let {name, sname, tel, email, nal_num, adress, errors, binded_bank} = props.form_.physics;

        if (!name||errors.name) {
            err=true;
        }
        if (!sname||errors.sname) {

            err=true;
        }
        if (!tel.tel||errors.phone_num) {

            err=true;
        }
        if (!validateEmail(email)) {
            props.set_form_('set', 'physics.errors.email', glv('account.second_step.errors.email_error'))
            err=true;
        }
        if (!email) {
            err=true;
        }
        if (!nal_num||errors.nal_num||!pesel_validate(trim_value(nal_num))) {

            err=true;
        }

        if (!adress.string_adr||adress.string_adr.length<10) {
            err=true;
        }

        if (!binded_bank.card_number|| binded_bank.is_valid_card_num||trim_value(binded_bank.card_number).length<26||!binded_bank.confirmed) {

            err=true;
        }
        if (!err) {

            set_is_validated(true)
        }else {
            set_is_validated(false)
        }
    }

    const juristics_form_validate = () => {
        let err=false;
        let {nip, name, reg, adress, email,  tel_1, tel_2, errors} = props.form_.juristics;

        if (!name) {
            err=true;
        }
        if (!nip||errors.nip||!nip_validate(nip)) {
            err=true;
        }
        if (!tel_1.tel||errors.tel_1) {
            err=true;
        }
        // if (!tel_2.tel||errors.tel_2) {
        //     err=true;
        // }
        if (!validateEmail(email)) {
            props.set_form_('set', 'physics.errors.email', glv('account.second_step.errors.email_error'))
            err=true;
        }

        if (!reg) {
            err=true;
        }
        if (!email) {
            err=true;
        }

        if (!adress||adress.length<10) {
            err=true;
        }

        if (!err) {
            set_is_validated(true)
        }else {
            set_is_validated(false)
        }
    }

    React.useEffect(() => {
        if (props.form_.physics_juristics) {
            physics_form_validate()
        } else {
            juristics_form_validate()
        }

    }, [props.form_]);


    const validate_pesel_input = (pesel) => {

        let space_removed_val = pesel;

        if (space_removed_val.length===11) {

            let validate = pesel_validate(space_removed_val);
            if (!validate) {
                return glv('account.second_step.errors.pesel_error')
            } else {
               return false
            }
        }
        if (space_removed_val.length<11&&props.form_.physics.errors.nal_num) {
            return false
        }
    }

    const validate_nip_input = (nip)=> {
        if(nip.length === 10) {
            let validate = nip_validate(nip);
            if (!validate) {
                return glv('account.second_step.errors.nip_error')
            } else {
                return false
            }
        }
        if (nip.length<11&&props.form_.juristics.errors.nip) {
            return false
        }
    }

    console.log(props.form_.type_pay_nal)

    return  banks ? (
        <form className={"container step step1" + props.clas}>
            <Backdrop
                open={is_loading} style={{
                zIndex: 100000,
                color: "#fff",
            }}>
                <CustomImageLoader
                    image={`${process.env.PUBLIC_URL}/images/icon.svg`}
                    isLoaded={is_loading}
                    circle={false}
                    speed={2}
                    animationType={"pulse"}
                />
            </Backdrop>
            {
                props.form_.physics_juristics ?
                    <div>
                        <BindBankModal
                            card_num={props.form_.physics.binded_bank.card_number}
                            change_bank_data={props.set_form_}
                            banks_={banks}
                            open={open_modal_bind_bank}
                            close={()=>{
                        set_open_modal_bind_bank(false);
                        }
                        }
                        curr_bank={props.form_.physics.binded_bank}
                        />
                        <div className="step-row " style={{display: "flex" }}>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%'}}>
                                <label
                                    htmlFor="sf6">{glv(`account.second_step.inputs.name_f.label`)}<sub>*</sub></label>
                                <input

                                    id="sf6"
                                       placeholder={glv(`account.second_step.inputs.name_f.placeholder`)}
                                       value={props.form_.physics.name}
                                       style={{border: props.form_.physics.errors.name ? '1px solid #e00052' : ''}}
                                       onChange={(e) => {
                                           if ( e.target.value&&(e.target.value.length<3||e.target.value.length>20)) {
                                               props.set_form_('set', 'physics', {
                                                   ...props.form_.physics,
                                                   name: e.target.value,
                                                   errors: {
                                                       ...props.form_.physics.errors,
                                                       name: glv('account.second_step.errors.short_')
                                                   }
                                               })
                                               return
                                           }
                                           props.set_form_('set', 'physics', {
                                               ...props.form_.physics,
                                               name: e.target.value,
                                               errors: {
                                                   ...props.form_.physics.errors,
                                                   name: false
                                               }
                                           })
                                          }
                                       }
                                       required
                                           />
                                <ErrorText err={props.form_.physics.errors.name}/>
                            </div>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%'}}>
                                <label htmlFor="sf7">{glv(`account.second_step.inputs.sname_f.label`)}<sub>*</sub></label>
                                <input type="text" id="sf7"
                                       placeholder={glv(`account.second_step.inputs.sname_f.placeholder`)}
                                       style={{border: props.form_.physics.errors.sname ? '1px solid #e00052' : ''}}
                                       value={props.form_.physics.sname}
                                       onChange={(e) => {
                                           if ( e.target.value&&(e.target.value.length<3||e.target.value.length>20)) {
                                               props.set_form_('set', 'physics', {
                                                   ...props.form_.physics,
                                                   sname: e.target.value,
                                                   errors: {
                                                       ...props.form_.physics.errors,
                                                       sname: glv('account.second_step.errors.short_')
                                                   }
                                               })
                                               return
                                           }
                                           props.set_form_('set', 'physics', {
                                               ...props.form_.physics,
                                               sname: e.target.value,
                                               errors: {
                                                   ...props.form_.physics.errors,
                                                   sname: false
                                               }
                                           })
                                       }}
                                       required/>
                                <ErrorText err={props.form_.physics.errors.sname}/>
                            </div>
                        </div>
                        <div className="step-row " style={{display: "flex" }}>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%'}}>
                                <label
                                    htmlFor="sf6">{glv(`account.second_step.inputs.tel_f.label`)}<sub>*</sub></label>
                                <PhoneInput
                                    id={'tel_f'}
                                    ln={'pl'}
                                    page_={'account'}
                                    error_path={'physics.errors.phone_num'}
                                    disable_change_country={true}
                                    // placeholder={glv(`mainStepper.first_step.inputs.phone_num.placeholder`)}
                                    form_={props.form_}
                                    set_form={props.set_form_}
                                    path={'physics.tel'}
                                />
                                <ErrorText/>
                            </div>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%'}}>
                                <label htmlFor="sf7">{glv(`account.second_step.inputs.email_f.label`)}<sub>*</sub></label>
                                <input type="text" id="sf7"
                                       placeholder={glv(`account.second_step.inputs.email_f.placeholder`)}
                                       style={{border: props.form_.physics.errors.email ? '1px solid #e00052' : '',background: '#eeeeee'}}
                                       value={props.form_.physics.email}
                                       onChange={(e) => {
                                           props.set_form_('set', 'physics.sname', e.target.value)
                                       }}
                                       disabled={true}
                                       required/>
                                <ErrorText/>
                            </div>
                        </div>

                        <div className="step-row " style={{display: "flex" }}>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%'}}>
                                <label
                                    htmlFor="sf6">{glv(`account.second_step.inputs.nal_num.label`)}<sub>*</sub></label>
                                <IMaskInput
                                    id="sf6"
                                    style={{border: props.form_.physics.errors.nal_num ? '1px solid #e00052' : '', borderRadius:'0px 5px 5px 0px',}}
                                    placeholder={glv(`account.second_step.inputs.nal_num.placeholder`)}
                                    mask="0000 00 0000 0"
                                    inputmode="numeric"
                                    name="pesel"
                                    value={props.form_.physics.nal_num}
                                    onAccept={
                                        (value, mask) => {
                                            let space_removed_val = value.replace(/\s+/g, '').replace(/[^\d]/g, '').trim();

                                            if (Number.isNaN(space_removed_val)) {
                                                return
                                            }
                                            if (/\D/.test(space_removed_val)) {
                                                return
                                            }
                                            let error_ = validate_pesel_input(space_removed_val)
                                            props.set_form_('set', 'physics', {
                                                ...props.form_.physics,
                                                nal_num: value,
                                                errors: {
                                                    ...props.form_.physics.errors,
                                                    nal_num: error_
                                                }
                                            })

                                        }
                                    }
                                    required
                                />

                                <ErrorText err={props.form_.physics.errors.nal_num}/>
                            </div>
                            <AdresInput
                                form_={props.form_}
                                set_form={(f_)=> {
                                    props.set_form_('set', 'physics.adress', f_)
                                }}
                                path={'physics.adress'}
                                custom_write={true}
                                handle_custom_write={(adr)=>{
                                    props.set_form_('set', 'physics.adress', adr)
                                }
                                }
                                set_error={(status) => {

                                  }
                                }
                                error={false}
                                wrap_style={{width: matches ? '100%' : '47%'}}
                                bind_map={false}
                                label={glv(`account.second_step.inputs.address.label`)}
                                placeholder={glv(`account.second_step.inputs.address.placeholder`)}
                            />
                        </div>

                                <RadioTabComponent
                                    tab_value={
                                        // null
                                        props.form_.physics.type_pay_nal
                                    }
                                    set_tab_value={(tab_) => {
                                        props.set_form_('set', 'physics.type_pay_nal', tab_)
                                    }}
                                    btn_w={'50%'}
                                    wr_style={{marginBottom: 0}}
                                    sub_lab={"*"}
                                    path_={`mainStepper.first_step.radio_btns_blocks.type_pay.text_values.data.`}
                                />

                        {
                            !props.form_.physics.type_pay_nal ?
                                <div className="step-row " style={{display: "flex", marginTop: matches? 24 : 32 }}>
                                    <div className="step-row-field" style={{width:  '100%' }}>
                                        {
                                            props.form_.physics.binded_bank.bank_id
                                            && props.form_.physics.binded_bank.card_number
                                            && !props.form_.physics.binded_bank.is_valid_card_num
                                            && props.form_.physics.binded_bank.confirmed
                                                ?
                                                <div style={{width: '100%', display: 'flex', cursor: 'pointer', }}

                                                >

                                                    <div style={{border: '1px solid rgb(238,238,238)', width: 48, height: 48,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        borderRadius:'5px 0px 0px 5px',
                                                        position: 'relative'
                                                    }}>
                                                        <div style={{position: 'absolute', width: '100%', height: '100%', zIndex: 65866868846}}
                                                             onClick={()=>{
                                                                 set_open_modal_bind_bank('sel_bank')
                                                             }
                                                             }
                                                        ></div>
                                                        <img src={props.form_.physics.binded_bank.logo_img}
                                                             style={{
                                                                 width: '55%',
                                                                 height: '55%',
                                                                 borderRadius:'2px',
                                                             }}/>
                                                    </div>
                                                    <div style={{width: '100%', position: 'relative'}}>
                                                        <div style={{position: 'absolute', width: '100%', height: '100%', zIndex: 65866868846}}
                                                             onClick={()=>{
                                                                 set_open_modal_bind_bank('card_num')
                                                             }
                                                             }
                                                        ></div>
                                                        <input type="text" id="sf7"
                                                               style={{width: '100%',
                                                                   borderRadius: '0px 5px 5px 0px',
                                                                   cursor: 'pointer',
                                                                   background: '#eeeeee'
                                                               }}
                                                               placeholder={glv(`account.second_step.inputs.email_f.placeholder`)}
                                                            // style={{border: firmname_err ? '1px solid #e00052' : ''}}
                                                               value={props.form_.physics.binded_bank.card_number}
                                                               onChange={(e) => {
                                                                   // props.set_form_('set', 'physics.sname', e.target.value)
                                                               }}

                                                               disabled={true}
                                                               required/>
                                                    </div>

                                                </div> :
                                                <div className="nextBtn" style={{width: '100%', minWidth: 126, height: 48,}}
                                                     onClick={() => {
                                                         set_open_modal_bind_bank(true)
                                                     }}>{glv(`account.second_step.buttons.bind_bank`)}
                                                </div>
                                        }

                                    </div>
                                </div>
                                : null
                        }


                </div>
                    :
                    <div>
                        <div className="step-row " style={{display: "flex" }}>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%'}}>
                                <label
                                    htmlFor="sf6">{glv(`account.second_step.inputs.nip_j.label`)}<sub>*</sub></label>
                                <IMaskInput
                                    // type="number"
                                    inputmode="numeric"
                                    id="sf6"
                                    placeholder={glv(`account.second_step.inputs.nip_j.placeholder`)}
                                    value={props.form_.juristics.nip}
                                    style={{border: props.form_.juristics.errors.nip ? '1px solid #e00052' : ''}}
                                    mask="000-00-00-000"
                                    onAccept={
                                        (value, mask) => {
                                            let space_removed_val = value.replace(/\s+/g, '').replace(/[^\d]/g, '').trim();

                                            if (Number.isNaN(space_removed_val)) {
                                                return
                                            }
                                            if (/\D/.test(space_removed_val)) {
                                                return
                                            }
                                            let error_ = validate_nip_input(space_removed_val)

                                            if (!error_&&space_removed_val.length===10) {
                                                set_is_loading(true)
                                                MainService.get_firm_for_nip(space_removed_val).then((res)=> {
                                                   if (!res?.info?.nip?.reg) {
                                                       set_is_loading(false)
                                                       props.set_form_('set', 'juristics', {
                                                           ...props.form_.juristics,
                                                           nip: value,
                                                           errors: {
                                                               ...props.form_.juristics.errors,
                                                               nip: glv('account.second_step.errors.no_such_firm')
                                                           }
                                                       })
                                                   } else {
                                                       set_is_loading(false)
                                                       props.set_form_('set', 'juristics', {
                                                           ...props.form_.juristics,
                                                           nip: value,
                                                           reg: res?.info?.nip?.reg,
                                                           name: res?.info?.nip?.name,
                                                           adress: res?.info?.nip?.full_adr,
                                                           errors: {
                                                               ...props.form_.juristics.errors,
                                                               nip: false
                                                           }
                                                       })
                                                   }
                                                })

                                                return
                                            }
                                            props.set_form_('set', 'juristics', {
                                                ...props.form_.juristics,
                                                nip: value,
                                                reg: '',
                                                name: '',
                                                adress:'',
                                                errors: {
                                                    ...props.form_.juristics.errors,
                                                    nip: error_
                                                }
                                            })

                                        }
                                    }
                                    onChange={(e) => {
                                        // props.set_form_('set', 'juristics.nip', e.target.value)
                                    }
                                    }
                                    required
                                />
                                <ErrorText err={props.form_.juristics.errors.nip}/>
                            </div>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%'}}>
                                <label htmlFor="sf7">{glv(`account.second_step.inputs.reg_j.label`)}<sub>*</sub></label>
                                <input type="text" id="sf7"
                                       placeholder={glv(`account.second_step.inputs.reg_j.placeholder`)}
                                    style={{background: '#eeeeee'}}
                                       value={props.form_.juristics.reg}
                                       onChange={(e) => {
                                           // props.set_form_('set', 'juristics.reg', e.target.value)
                                       }}
                                       disabled={true}
                                       required/>
                                <ErrorText/>
                            </div>
                        </div>
                        <div className="step-row w100" style={{display: "flex" }}>
                            <div className="step-row-field" style={{width:  '100%' }}>
                                <label
                                    htmlFor="sf6">{glv(`account.second_step.inputs.name_j.label`)}<sub>*</sub></label>
                                <input

                                    id="sf6"
                                    placeholder={glv(`account.second_step.inputs.name_j.placeholder`)}
                                    value={props.form_.juristics.name}
                                    style={{background: '#eeeeee'}}
                                    onChange={(e) => {
                                        // props.set_form_('set', 'juristics.name', e.target.value)
                                    }
                                    }
                                    disabled={true}
                                    required
                                />
                                <ErrorText/>
                            </div>
                        </div>
                        <div className="step-row " style={{display: "flex" }}>
                            <div className="step-row-field" style={{width: matches ? '100%' : '100%'}}>
                                <label
                                    htmlFor="sf6">{glv(`account.second_step.inputs.address_j.label`)}<sub>*</sub></label>
                                <input

                                    id="sf6"
                                    placeholder={glv(`account.second_step.inputs.address_j.placeholder`)}
                                    value={props.form_.juristics.adress}
                                    style={{background: '#eeeeee'}}
                                    onChange={(e) => {
                                        // props.set_form_('set', 'juristics.adress', e.target.value)
                                    }
                                    }
                                    disabled={true}
                                    required
                                />
                                <ErrorText/>
                            </div>

                        </div>
                        <div className="step-row " style={{display: "flex" }}>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%'}}>
                                <label htmlFor="sf7">{glv(`account.second_step.inputs.email_j.label`)}<sub>*</sub></label>
                                <input type="text" id="sf7"
                                       placeholder={glv(`account.second_step.inputs.email_j.placeholder`)}
                                       style={{background: '#eeeeee'}}
                                       value={props.form_.juristics.email}
                                       onChange={(e) => {
                                           // props.set_form_('set', 'juristics.email', e.target.value)
                                       }}
                                       disabled={true}
                                       required/>
                                <ErrorText/>
                            </div>
                            <div className="step-row-field" style={{width: matches ? '100%' : '47%'}}>
                                <label
                                    htmlFor="sf6">{glv(`account.second_step.inputs.tel_j_1.label`)}<sub>*</sub></label>
                                <PhoneInput
                                    id={'tel_j_1'}
                                    ln={'pl'}
                                    error_path={'juristics.errors.tel_1'}
                                    disable_change_country={true}
                                    page_={'account'}
                                    // placeholder={glv(`mainStepper.first_step.inputs.phone_num.placeholder`)}
                                    form_={props.form_}
                                    set_form={props.set_form_}
                                    path={'juristics.tel_1'}
                                />
                                <ErrorText/>
                            </div>

                            {/*<div className="step-row-field" style={{width: matches ? '100%' : '47%'}}>*/}
                            {/*    <label htmlFor="sf7">{glv(`account.second_step.inputs.tel_j_2.label`)}<sub>*</sub></label>*/}
                            {/*    <PhoneInput*/}
                            {/*        id={'tel_j_2'}*/}
                            {/*        ln={'pl'}*/}
                            {/*        page_={'account'}*/}
                            {/*        disable_change_country={true}*/}
                            {/*        error_path={'juristics.errors.tel_2'}*/}
                            {/*        // placeholder={glv(`mainStepper.first_step.inputs.phone_num.placeholder`)}*/}
                            {/*        form_={props.form_}*/}
                            {/*        set_form={props.set_form_}*/}
                            {/*        path={'juristics.tel_2'}*/}
                            {/*    />*/}
                            {/*    <ErrorText/>*/}
                            {/*</div>*/}
                        </div>
                </div>
            }





            <div className="step-row last" style={{marginTop: matches? 24 : 32}}>
                <div className="prevBtn" onClick={() => props.prevStep()}
                     style={{marginRight: is_validated ? '' : 0,
                        width: !is_validated&&matches ? '100%' : ''
                     }}>
                    {
                        glv(`mainStepper.prevStepTitle`)
                    }
                </div>
                {
                    is_validated? null : <div></div>
                }
                {
                    is_validated ?
                        <div className="nextBtnWr ">
                            <div className="nextBtn" onClick={() => {
                                props.nextStep()
                            }}>{glv(`mainStepper.nextStepTitle`)}</div>
                            {/* <input type="submit" className="nextBtn" value={props.lang.mainStepper.nextStepTitle} onClick = {()=>props.nextStep()}/> */}
                        </div> : null
                }

            </div>
        </form>) : <Backdrop
        open={true} style={{
        zIndex: 100000,
        color: "#fff",
    }}>
        <CustomImageLoader
            image={`${process.env.PUBLIC_URL}/images/icon.svg`}
            isLoaded={true}
            circle={false}
            speed={2}
            animationType={"pulse"}
        />
    </Backdrop>
}